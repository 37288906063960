import React from "react";
import { connect, DispatchProp } from "react-redux";
import { submit } from "redux-form";
import { ModalsConstants } from "../../constants/modals";
import ModalContent from "../../components/modals/ModalContent";
import SendReceiptForm, { SendReceiptFormData } from "../../components/invoice/SendReceiptForm";
import { sendInvoiceForCompletion } from "../../actions/invoices";
import { closeModal } from "../../actions/modals";
import { handleReduxFormError } from "../../services/app/forms";
import { InvoiceStatuses } from "../../constants/invoice";
import { normalizePhoneOrEmail } from "../../services/app/normalizers";
import { Invoice } from "../../types/Invoice";

interface Props extends OwnProps, DispatchProp, PropsFromState {}

interface PropsFromState {
    submitting?: boolean;
}

interface OwnProps {
    invoiceId: string;
    invoice: Invoice;
}

class InvoiceSendRemoteCheckout extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
        this.handleModalSubmit = this.handleModalSubmit.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    handleModalSubmit(): void {
        const { dispatch } = this.props;
        dispatch(submit("sendReceipt"));
    }

    handleModalClose(): void {
        const { dispatch } = this.props;
        dispatch(closeModal(ModalsConstants.SEND_REMOTE_CHECKOUT_INVOICE));
    }

    handleFormSubmit(values: SendReceiptFormData): void {
        const { dispatch, invoiceId } = this.props;
        values.phoneOrEmail = normalizePhoneOrEmail(values.phoneOrEmail);
        const action =
            values.phoneOrEmail && String(values.phoneOrEmail).indexOf("@") >= 0
                ? sendInvoiceForCompletion(invoiceId, values.phoneOrEmail)
                : sendInvoiceForCompletion(invoiceId, undefined, values.phoneOrEmail);

        dispatch<any>(action)
            .then(() => {
                dispatch(closeModal(ModalsConstants.SEND_REMOTE_CHECKOUT_INVOICE));
            })
            .catch(handleReduxFormError);
    }

    render(): React.ReactElement {
        const { submitting, invoice } = this.props;
        const initial = invoice.payerPhone ? invoice.payerPhone : invoice.payerEmail;
        const buttonText = invoice.status === InvoiceStatuses.NEW.key ? "Send Invoice" : "Resend Invoice";

        return (
            <ModalContent
                className="modal-stacked-buttons"
                primaryActionText={submitting ? "Sending..." : buttonText}
                primaryActionClick={this.handleModalSubmit}
                primaryActionDisabled={submitting}
                secondaryActionText="Close"
                secondaryActionClick={this.handleModalClose}
            >
                <SendReceiptForm initialValues={{ phoneOrEmail: initial }} onSubmit={this.handleFormSubmit} />
            </ModalContent>
        );
    }
}

const mapStateToProps = ({ form }): PropsFromState => ({ submitting: form?.sendReceipt?.submitting });

export default connect(mapStateToProps)(InvoiceSendRemoteCheckout);

