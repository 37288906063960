import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, RouteProps, Redirect, useLocation } from "react-router-dom";
import { AuthPaths } from "../../services/app/paths";
import { authCheck } from "../../actions/auth";
import Loader from "../../components/app/Loader";
import { Role } from "../../types/Role";
import SurveyDisplayProvider from "../../components/survey/SurveyDisplayProvider";
import { Type as CompanyType } from "../../constants/company";
import { getCompany } from "../../actions/companies";
import {
    getAppSettings,
    getAppSettingsLoaded,
    getAuth,
    getCurrentIsEmployee,
    getCurrentUser,
    getCurrentUserRoles,
    getIsAuthenticated,
    getUserIsAuthorizedByCompanyType,
    userIsAuthorizedByRole,
    userIsVerified,
} from "../../selectors";

interface OwnProps {
    exact?: boolean;
    requireDefaultsForEmployee?: boolean;
    roles: Role[];
    companyTypes?: CompanyType[];
    path: string;
    component?: React.ComponentClass<any> | React.FunctionComponent<any>;
    render?: React.ComponentClass<any> | React.FunctionComponent<any>;
}

const AuthorizedRoute: React.FC<OwnProps> = (props) => {
    const { requireDefaultsForEmployee, path, roles, companyTypes, ...rest } = props;
    const dispatch = useDispatch();

    const currentUser = useSelector(getCurrentUser);

    const companyId = currentUser?.companyId;

    useEffect(() => {
        const fetchData = async () => {
            await dispatch<any>(authCheck());
            if (companyId) {
                await dispatch<any>(getCompany(companyId));
            }
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyId]);

    const userVerified = useSelector(userIsVerified(path));
    const userAuthorizedByRole = useSelector(userIsAuthorizedByRole(roles));
    const userIsAuthorizedByCompanyType = useSelector(getUserIsAuthorizedByCompanyType(companyTypes));
    const appSettings = useSelector(getAppSettings);
    const authenticated = useSelector(getIsAuthenticated);
    const auth = useSelector(getAuth);
    const authSettingLoaded = useSelector(getAppSettingsLoaded);
    const hasSingleRole = useSelector(getCurrentUserRoles)?.length === 1;
    const employeeOnly = useSelector(getCurrentIsEmployee) && hasSingleRole;
    const location = useLocation();

    if (authenticated) {
        if (!userIsAuthorizedByCompanyType) {
            window.location.href = AuthPaths.ssoLink();
            return null;
        }

        if (!userVerified) {
            return (
                <Redirect
                    to={{
                        pathname: AuthPaths.verifyUserUrl(currentUser.id),
                        search: location.search,
                    }}
                />
            );
        }

        if (userAuthorizedByRole) {
            if (currentUser.firstPasswordReset && path !== AuthPaths.changePasswordUrl() && userVerified) {
                return <Redirect to={AuthPaths.changePasswordUrl()} />;
            }

            if (requireDefaultsForEmployee && employeeOnly && appSettings.defaults == null) {
                return <Redirect to={AuthPaths.selectLocationUrl(path)} />;
            }

            return (
                <SurveyDisplayProvider>
                    <Route {...(rest as RouteProps)} />
                </SurveyDisplayProvider>
            );
        }
    }

    if (auth.checking || !authSettingLoaded) {
        return <Loader data-testid="loader" />;
    }

    window.location.href = AuthPaths.ssoLink(true);

    return null;
};

export default AuthorizedRoute;

