import React, { useState } from "react";
import ModalContent from "../../components/modals/ModalContent";
import { useDispatch } from "react-redux";
import { closeModal } from "../../actions/modals";
import { ModalsConstants } from "../../constants/modals";
import { Invoice } from "../../types";
import { createStyles, makeStyles, TextField, Typography } from "@material-ui/core";
import { updateInvoice } from "../../actions/invoices";

const useStyles = makeStyles((theme) =>
    createStyles({
        input: {
            width: "100%",
            "& .MuiInputBase-input.Mui-disabled": {
                color: theme.palette.text.primary,
            },
        },
        label: {
            fontSize: 16,
            fontWeight: 500,
            textAlign: "left",
            marginBottom: 10,
        },
    })
);

interface PaidInvoiceCommentsModalProps {
    invoice: Invoice;
}
const PaidInvoiceCommentsModal: React.FC<PaidInvoiceCommentsModalProps> = ({ invoice }): JSX.Element => {
    const dispatch = useDispatch();
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>();
    const [comment, setComment] = useState<string>("");

    const styles = useStyles();

    const sendComments = async () => {
        setSubmitting(true);
        const newInvoice = { ...invoice, paidComment: comment };
        setError(undefined);
        try {
            await dispatch(updateInvoice(newInvoice));
            dispatch(closeModal(ModalsConstants.ADD_PAID_INVOICE_COMMENT));
        } catch (e) {
            setError("An error occurred while saving comments. Please try again.");
        } finally {
            setSubmitting(false);
        }
    };

    const handleModalClose = () => {
        dispatch(closeModal(ModalsConstants.ADD_PAID_INVOICE_COMMENT));
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setComment(e.target.value);
    };

    const valid = comment.trim().length > 0;

    const hasComments = !!invoice.paidComment && invoice.paidComment.length > 0;

    const value = hasComments ? invoice.paidComment : comment;

    const genContentProps = () => {
        if (hasComments) {
            return {
                primaryActionText: "Close",
                primaryActionClick: handleModalClose,
                primaryActionDisabled: false,
            };
        } else {
            return {
                primaryActionText: "Save Comments",
                primaryActionClick: sendComments,
                primaryActionDisabled: !valid || submitting,
                secondaryActionText: "Close",
                secondaryActionClick: handleModalClose,
            };
        }
    };

    return (
        <ModalContent className="modal-stacked-buttons" {...genContentProps()}>
            <Typography className={styles.label} id="paid-invoice-comments-field-label">
                Comments
            </Typography>
            <TextField
                className={styles.input}
                disabled={submitting || hasComments}
                data-testid="paid-invoice-comments-field"
                id="paid-invoice-comments-field"
                placeholder="Begin typing comments here..."
                multiline
                variant="outlined"
                aria-labelledby="paid-invoice-comments-field-label"
                value={value}
                onChange={handleChange}
                error={!!error}
                helperText={error}
            />
        </ModalContent>
    );
};

export default PaidInvoiceCommentsModal;

