import React from "react";
import { WithInvoiceStatusProps } from "../WithInvoiceStatusProps";

const IfInvoiceStatus: React.FC<WithInvoiceStatusProps> = (props: WithInvoiceStatusProps): React.ReactElement => {
    const { statuses, invoice, children } = props;
    return <React.Fragment>{statuses.map((s) => s.key).includes(invoice.status) && children}</React.Fragment>;
};

export default IfInvoiceStatus;

