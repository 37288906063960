import "react-app-polyfill/stable";
import "react-app-polyfill/ie11";
import "regenerator-runtime/runtime";
import "raf/polyfill";
import "element-scroll-polyfill";
import "custom-event-polyfill";

import React, { StrictMode } from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { configureStore } from "./store/configureStore";
import { Routes } from "./routes";
import history from "./history";
import _ from "lodash";
import "./assets/scss/style.css";
import ReactGA from "react-ga4";
import { isMobileOnly } from "react-device-detect";
import WootricProvider from "./components/wootric/WootricProvider";
import ErrorBoundary from "./components/ErrorBoundary";
import PWAPreparer from "./components/pwaPreparer/PWAPreparer";
import { ThemeProvider } from "@roadsync/roadsync-ui";
import VersionChecker from "./components/VersionChecker";
import HubSpotLoader from "./HubSpot";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Pendo from "./Pendo";
import { Datadog } from "./Datadog";
import { Amplify } from "aws-amplify";

ReactGA.initialize("UA-122623158-10");

try {
    Amplify.configure({
        aws_cognito_region: "us-east-1",
        aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
        aws_user_pools_web_client_id: process.env.REACT_APP_AWS_POOLS_WEB_CLIENT_ID,
        authenticationFlowType: "USER_PASSWORD_AUTH",
        oauth: {
            domain: process.env.REACT_APP_AWS_OAUTH_DOMAIN,
            scope: ["openid", "email", "phone"],
            redirectSignIn: `${window.location.protocol}//${window.location.host}/`,
            redirectSignOut: `${window.location.protocol}//${window.location.host}/`,
            responseType: "code",
        },
    });
} catch (e) {
    console.warn("Failed to initialize Amplify authentication", e);
}

const store = configureStore();
ReactDOM.render(
    <Provider store={store}>
        <ErrorBoundary>
            <StrictMode>
                <ThemeProvider>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <>
                            <Router history={history}>
                                <Routes />
                            </Router>
                            <Pendo />
                            <HubSpotLoader />
                            <WootricProvider />
                            <PWAPreparer />
                            <VersionChecker />
                        </>
                    </MuiPickersUtilsProvider>
                </ThemeProvider>
            </StrictMode>
        </ErrorBoundary>
    </Provider>,
    document.getElementById("root")
);

if (isMobileOnly) {
    document.body.classList.add("mobile");
}

Datadog.init(store as any);

// Service Worker registration
if ("serviceWorker" in navigator) {
    navigator.serviceWorker
        .register("./serviceWorker.js")
        .then(() => {
            console.info("Service Worker installed successfully!");
        })
        .catch((error) => {
            console.warn("Service Worker wasn't installed!");
            console.warn("Error below");
            console.warn(error);
        });
} else {
    console.warn("Service Worker isn't supported");
}

if (process.env.NODE_ENV !== "production") {
    // @ts-ignore
    window.dumpState = (subKey?: string) => {
        if (!subKey) {
            console.log(localStorage.getItem("reduxState"));
        } else {
            const state = JSON.parse(localStorage.getItem("reduxState") || "{}");
            console.log(JSON.stringify(_.get(state, subKey)));
        }
    };
}
