import { showPendingAlert, showErrorAlert } from "../../actions/alerts";
import * as storage from "../app/storage";
import { Dispatch } from "react";
import { FileUrl } from "../../types/FileUrl";
import { GlobalState } from "../../types/GlobalState";
import { get } from "./http";
import semver from "semver";
import { Log } from "../LoggerService";
import { forceValueToString } from "../app/formats";
import history from "../../history";
import { AuthPaths } from "../app/paths";
const ACCESS_TOKEN_BUFFER = 5 * 60 * 1000; // 5 minutes
const ACCESS_TOKEN_EXPIRATION = 60 * 60 * 1000; // 55 minutes

require("isomorphic-fetch");

export enum HTTPMethod {
    GET = "GET",
    POST = "POST",
}

export enum HTTPCodes {
    UNAUTHORIZED = 401,
    NEEDS_RESET = 428,
}

export enum HTTPSettings {
    IncludeCredentials = "include",
}

export interface FieldError {
    FieldName: string;
    Message: string;
}

export function isRefreshTokenValid(): "valid" | "invalid" | "none" {
    const { refreshExpires, refreshToken } = getStoredTokens();

    // This can be used to force a refresh token to be invalid for dev and testing purposes
    if (!!localStorage.getItem("dev-trip-refresh")) {
        // This value is used to prevent infinite loops of refreshing the access token in dev testing
        localStorage.removeItem("dev-trip-refresh");
        return "invalid";
    }
    if (!refreshToken || !refreshExpires) {
        return "none";
    }
    if (new Date().getTime() >= refreshExpires.getTime()) {
        return "invalid";
    }
    return "valid";
}

export function getEnvUrlPrefix(): string {
    const hostname = window.location.hostname;
    let env = "";
    if (hostname.includes("localhost") || hostname.includes("dev")) {
        env = "dev.";
    } else if (hostname.includes("test")) {
        env = "test.";
    }

    return env;
}

export function storeTokens(accessToken: string, refreshToken: string, expiresIn?: number): void {
    if (!accessToken || !refreshToken) {
        console.error("Invalid tokens", { accessToken: !!accessToken, refreshToken: !!refreshToken });
    }

    // Convert secs to ms
    const expiration = expiresIn ? expiresIn * 1000 : ACCESS_TOKEN_EXPIRATION;
    storage.setItem("accessToken", accessToken);
    storage.setItem("refreshToken", refreshToken);
    storage.setItem("refreshExpires", new Date().getTime() + (expiration - ACCESS_TOKEN_BUFFER));
}

export function getStoredTokens(): { accessToken: string | null; refreshToken: string | null; refreshExpires: Date | null } {
    let refreshExpires: Date | null = null;
    const rawTokenDateString = storage.getItem<string | null>("refreshExpires");
    if (rawTokenDateString) {
        refreshExpires = new Date(rawTokenDateString);
    }
    return {
        accessToken: storage.getItem("accessToken"),
        refreshToken: storage.getItem("refreshToken"),
        refreshExpires,
    };
}

export async function parseResponse<T>(response: Response): Promise<T> {
    try {
        const contentType = response.headers.get("Content-Type");
        const contentJson = contentType && contentType.includes("application/json");
        const content = await (contentJson ? response.json() : response.text());
        if (response.status === HTTPCodes.NEEDS_RESET) {
            history.push(AuthPaths.changePasswordUrl());
        }

        if (response.ok) {
            return Promise.resolve(content);
        }

        return Promise.reject(parseErrorResponse(response, content));
    } catch (ex) {
        return Promise.reject(parseErrorResponse(response, ex));
    }
}

function parseErrorResponse(response: Response, content: any) {
    const error = content?.message || content?.errors?.[0] || "Unable to process your request. Please try again later";
    const message = typeof error === "object" && error.message ? error.message : error;

    return {
        message,
        status: response.status,
        statusText: response.statusText,
        formSubmissionError: getFormSubmissionError(message, content),
    };
}

function getFormSubmissionError(message: string, content: Record<string, any>) {
    const formSubmissionError = {
        _error: message,
        fieldErrors: [] as FieldError[],
    };
    try {
        if (content?.errors) {
            content.errors
                .filter((err) => (err?.parameter && err?.message) || (err?.["sub-parameter"] && err?.message))
                .forEach((err) => {
                    const parameter = err.parameter && String(err.parameter)[0].toLowerCase() + err.parameter.substring(1);
                    const subParameter = err["sub-parameter"] && String(err["sub-parameter"])[0].toLowerCase() + err["sub-parameter"].substring(1);
                    formSubmissionError[subParameter || parameter] = err.message;
                    formSubmissionError.fieldErrors.push({
                        FieldName: err["sub-parameter"] || err.parameter,
                        Message: err.message,
                    });
                });
        }
    } catch (e) {
        // Do nothing
    }
    return formSubmissionError;
}

export function buildUrl(url: string, args?: string | string[][] | Record<string, string> | URLSearchParams | undefined | {}): string {
    if (args) {
        url += "?" + new URLSearchParams(args).toString();
    }
    return url;
}

function _setAuthorizationHeaders(headers?: Headers): Headers {
    headers = headers || new Headers();
    const authToken = storage.getItem<string>("accessToken");
    if (authToken) {
        headers.set("Authorization", `Bearer ${authToken}`);
    }

    return headers;
}

export async function fetchWithHeaders(
    route: RequestInfo,
    body: RequestInit,
    headers?: Headers,
    credentials?: RequestCredentials
): Promise<Response> {
    return normalFetch(route, body, _setAuthorizationHeaders(headers), credentials);
}

export function normalFetch(route: RequestInfo, body: RequestInit, headers?: Headers, credentials?: RequestCredentials): Promise<Response> {
    body.credentials = credentials ?? body.credentials;
    body.headers = headers;
    return fetch(route, body);
}

export function isEmail(input?: string | null): boolean {
    return (input || "").toString().indexOf("@") > -1;
}

// https://stackoverflow.com/a/38935990/350085
// eslint-disable-next-line max-lines-per-function,max-statements
export function dataURLtoFile(imageData: string, filename: string): Blob | File | null {
    let mime: string | undefined;
    let base64String: string | undefined;

    [mime, base64String] = imageData.split(",");
    mime = mime?.match(/:(.*?);/)?.[1];
    if (!mime || !base64String) {
        return null;
    }

    base64String = atob(base64String);
    let n = base64String.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = base64String.charCodeAt(n);
    }

    try {
        if (isCordova()) {
            throw new Error("File constructor seems to fail on mobile, so fall back to Blob");
        }
        return new File([u8arr], filename, { type: mime });
    } catch (err) {
        return new Blob([u8arr], { type: mime });
    }
}

export function isCordova(): boolean {
    return !!window["cordova"];
}

export function getWindow(text = "Generating pdf..."): Window | null {
    if (isCordova()) {
        // TODO: Fix this. Cordova can use inappbrowser plugin instead.
        // That plugin overwrites the window.open prototype and means that both
        // mobile and web can use it, making this code unnecessary.
        return null;
    }
    const win = window.open("", "_blank");
    if (win) {
        win.document.body.innerHTML = text;
    }
    return win;
}

export function openWindow(url: string, target = "_blank"): Window | null {
    return isCordova() ? null : window.open(url, target);
}

export function getPdfUrl(url: string): string {
    if (isCordova()) {
        return `https://docs.google.com/viewer?embedded=true&url=${encodeURIComponent(url)}`;
    }
    return url;
}

export function reloadApp(message: string): CallableFunction {
    return (dispatch: Function) => dispatch(showPendingAlert(message, { onClose: () => window.location.reload() }));
}

export function openFile(p: Promise<FileUrl>): (d: Dispatch<any>) => void {
    return (dispatch: Dispatch<any>): void => {
        p.then((data) => {
            if (!data?.url) {
                throw new Error("Something went wrong please try again later.");
            }
            const win = getWindow();
            if (win) {
                win.location.href = data.url;
                return;
            }
            window.open(getPdfUrl(data.url), "_blank");
        }).catch((err) => dispatch(showErrorAlert(err.message)));
    };
}

export async function wait(timeInMs = 1): Promise<void> {
    await new Promise((r) => setTimeout(r, timeInMs));
}

export async function waitMultiple(times = 10, timeInMsBetweenEach?: number): Promise<void> {
    for (let i = 0; i < times; i++) {
        await wait(timeInMsBetweenEach);
    }
}

export const isIE = (): boolean => navigator.userAgent.indexOf("MSIE") > -1 || navigator.appVersion.indexOf("Trident/") > -1;

export function mapState<T>(state: GlobalState, ...keys: (keyof GlobalState)[]): T {
    const r: any = {};
    keys.forEach((k) => (r[k] = state[k]));
    return r;
}

export function getCurrentVersion(): string {
    return require("../../../package.json").version;
}

export async function getLatestVersion(): Promise<string> {
    const version = await get<string>("/VERSION");
    return forceValueToString(version).trim();
}

export async function isLatestVersion(): Promise<boolean> {
    try {
        const current = getCurrentVersion();
        const latest = await getLatestVersion();
        Log.debug(`Version check > current: ${current} latest: ${latest} > Up to date? ${semver.gte(current, latest)}`);
        return semver.gte(current, latest);
    } catch (e) {
        Log.debug("Could not check for latest version", e);
        return true;
    }
}

export function createURL(url: string, baseURL: string | undefined = process?.env?.REACT_APP_API_BASE_URL): string {
    const appendBaseUrl = url.startsWith("/") && !url.startsWith("//") && !!baseURL;
    return appendBaseUrl ? `${baseURL}${url}` : url;
}
