import { Theme, createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        depositAmount: {
            color: theme.palette.grey[500],
        }
    })
);

export default useStyles;
