import { FormErrors } from "redux-form";
import { phone } from "phone";
import { CheckDetailsFormData } from "../../components/invoice/CheckDetailsForm";
import { CheckTypes } from "../../constants/deposit";
import { isState } from "../../constants/states";
import { PreEditResponseParsed, User } from "../../types";
import { comdataChargeFormValidationErrors, preEditFormValidationErrors } from "../../types/ComdataFuelCardErrors";
import { requiredByIndicatorValue } from "./comdata/preEditParser";
import { dateToYearMonth } from "./datetime";
import { forceValueToString, formatDollar } from "./formats";
import { isFuelCardNumber } from "./invoice";
import { PaymentMethods } from "../../constants/invoice";
import { ExpressPaymentFormData } from "../../containers/invoice/expressPayment/ExpressPaymentForm";
import { isEmpty } from "lodash";
import { TowbookCheckbox, TowbookFormData } from "../../containers/invoice/importFromTowBook/step1/TowbookForm";
import { allowedCombosForValidation, Roles } from "../../constants/roles";
import DateFnsUtils from "@date-io/date-fns";

export const required = (value?: string | null): string | undefined => {
    return forceValueToString(value) ? undefined : "Required";
};

export const firstAndLastName = (value: string): string | undefined => {
    if (!value) {
        return required(value);
    }
    return /^[a-zA-Z][a-zA-Z\-'\s]*$/.test(value) ? undefined : "Invalid characters";
};

export const zipCode = (value?: string | null): string | undefined => {
    if (!value) {
        return undefined;
    }
    return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(forceValueToString(value)) ? undefined : "Invalid zip code";
};

export const state = (value: string): string | undefined => {
    if (!value) {
        return undefined;
    }
    let formattedValue = value?.toUpperCase();
    return !isState(formattedValue) ? "Invalid State" : undefined;
};

export const city = (value: string): string | undefined => {
    if (!value) {
        return undefined;
    }
    let formattedCity = value.trim();
    return !/^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/.test(formattedCity) ? "Invalid City" : undefined;
};

export const streetAddress = (value: string): string | undefined => {
    if (!value) {
        return undefined;
    }
    return !/^[A-Za-z0-9 ]+$/.test(value) ? "Invalid Street Address" : undefined;
};

export const WEXrequired = (value?: string | null): string | undefined => (value ? undefined : "Field required for WEX Integration");

export const email = (value?: string | null): string | undefined =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(value) ? "Invalid email address" : undefined;

export const username = (value?: string | null): string | undefined =>
    value && /^\s+|\s\s+|\s+$/g.test(value) ? "Username cannot start or end with a space" : undefined;

export const customFieldName = (value?: string | null): string | undefined =>
    value && !/^[a-zA-Z]+[a-zA-Z0-9_\s]*$/i.test(value) ? "Only alphanumeric characters" : undefined;

const phoneNumberRegex = /^(\+?[0-9]{9,11}[0-9])|(\([0-9]{3}\)(\s?)[0-9]{3}-?[0-9]{4})|([0-9]{3}-?[0-9]{3}-?[0-9]{4})$/g;
const usPhoneNumberRegex = /^(\+?[0-9]{9}[0-9])|(\([0-9]{3}\)(\s?)[0-9]{3}-?[0-9]{4})|([0-9]{3}-?[0-9]{3}-?[0-9]{4})$/g;

// eslint-disable-next-line max-len
export const formPhoneNumber = (value?: string | null): string | undefined =>
    value && !(phoneNumberRegex.test(value) && value === value.match(phoneNumberRegex)?.[0]) ? "Invalid phone Number" : undefined;

// eslint-disable-next-line max-len
export const usPhoneNumber = (value?: string | null): string | undefined =>
    value && !(usPhoneNumberRegex.test(value) && value === value.match(usPhoneNumberRegex)?.[0])
        ? "Please enter a 10 digit phone number."
        : undefined;

export const emailOrPhoneNumber = (value?: string | null): string | undefined => {
    if (value && String(value).indexOf("@") >= 0) {
        return email(value);
    }
    return value ? formPhoneNumber(value) : undefined;
};

export const numeric = (value?: string | null): string | undefined => (value && !/^[-+]?\d*\.?\d*$/i.test(value) ? "Invalid number" : undefined);

export const floatBiggerThanZero = (value?: string | null): string | undefined =>
    value && !/^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/i.test(value) ? "Invalid amount" : undefined;

export const moneyValueInRange = (notLessThan: number, notMoreThan: number, value?: string): string | undefined => {
    if (!value || !isDecimalTwoPlaces(value)) {
        return "Please enter a valid amount";
    }

    const floatValue = parseFloat(value);
    if (floatValue < notLessThan || floatValue > notMoreThan) {
        return `Amount must be between ${formatDollar(notLessThan)} and ${formatDollar(notMoreThan)}`;
    }
    return undefined;
};

export const version = (value?: string | null): string | undefined =>
    value && !/^\d{1,2}\.\d{1,2}\.\d{1,2}$/.test(value) ? "Invalid version. Use three one-digit or two-digit numbers separated by dots." : undefined;

export const integer = (value?: string | null): string | undefined => (value && !/^[0-9]+$/i.test(value) ? "Invalid number" : undefined);

export const isDecimalTwoPlaces = (value: string): boolean => /^\d+(\.\d{0,2})?$/.test(value);

export const microdeposit = (value?: string | null): string | undefined => (value && !/^0.0[1-9]$/.test(value) ? "Invalid amount" : undefined);

export const dollarMicrodeposit = (value?: string | null): string | undefined =>
    value && !/^0.[0-9][0-9]$/.test(value) ? "Invalid amount" : undefined;

export const identificationNumber = (value?: string | null): string | undefined =>
    value && !/^[0-9]{9}$/g.test(value) ? "Invalid ID. Please enter 9 digits with no spaces or dashes." : undefined;

export const ssnLastFour = (value?: string | null): string | undefined =>
    value && !/^[0-9]{4}$/g.test(value) ? "Please enter last 4 digits of Social Security number with no spaces or dashes." : undefined;

export const workOrderText = (value?: string | null): string | undefined =>
    value && value.length > 64 ? "Please enter a value that is less than 64 characters" : undefined;

// eslint-disable-next-line no-useless-escape
export const password = (value?: string | null): string | undefined =>
    value && !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(value)
        ? "Passwords need a minimum of 8 characters with at least 1 upper," +
          // eslint-disable-next-line
          " 1 lower case, 1 number, and 1 special character(!@#$%^&*)"
        : undefined;

export const fuelCardNumber = (value?: string | null): string | undefined =>
    isFuelCardNumber(value || "") ? undefined : "Please enter a valid fuel card number";

export const lockOutConfirmationValidation = (value?: string | null): string | undefined =>
    value && !/^lock out all users[.]?$/gim.test(value) ? 'Please enter a phrase "lock out all users"' : undefined;

export const futureOrCurrentMonthDate = (value?: string | Date): string | undefined => {
    const dateFns = new DateFnsUtils();
    const now = dateFns.startOfDay(new Date());

    if (value) {
        const inputDate = dateFns.startOfDay(new Date(value));
        return dateFns.isBefore(inputDate, now) ? "Expiration date must be in the future" : undefined;
    }

    return undefined;
};

// eslint-disable-next-line
export const validateCheckDetailsFormData = (values: CheckDetailsFormData): FormErrors<CheckDetailsFormData> => {
    const errors: FormErrors<CheckDetailsFormData> = {};
    const length = forceValueToString(values?.expressCode).length;
    const expirationDateRequired = values?.checkType === CheckTypes.TCHECK.key && [10, 12].indexOf(length) > -1 && !values.expirationDate;

    const nowFormatted = dateToYearMonth(new Date());
    const inputExpirationDate = dateToYearMonth(values?.expirationDate);
    const isExpirationDateValid = nowFormatted <= inputExpirationDate;

    if (values.checkType === CheckTypes.USBANK.key) {
        if (values.accountNumber) {
            const accountNumber = String(values.accountNumber);
            if (accountNumber.length !== 8 || !accountNumber.startsWith("1")) {
                errors.accountNumber = "Admin Code should be 8 digits total and should starts from 1";
            }
        }
    }

    if (!values.expressCode && values.checkType !== CheckTypes.FLEET_ONE.key) {
        errors.expressCode = "Required";
    } else if (isNaN(Number(values.expressCode)) && values.checkType && values.checkType !== CheckTypes.EFS.key) {
        errors.expressCode = `${CheckTypes.getByKey(values.checkType)?.display} may only be numeric.`;
    } else {
        if (values?.checkType === CheckTypes.COMCHEK.key) {
            if (length < 10) {
                errors.expressCode = "Express Code must be at least 10 digits long";
            } else if (length > 23) {
                errors.expressCode = "Express Code must be less than 24 digits long";
            }
        } else if (values?.checkType === CheckTypes.EFS.key) {
            if ([10, 12, 16, 19, 21].indexOf(length) === -1) {
                errors.expressCode = "Moneycode must be 10, 12, 16, 19, or 21 characters";
            }
        } else if (values?.checkType === CheckTypes.TCHECK.key) {
            if ([10, 12, 16, 24].indexOf(length) === -1) {
                errors.expressCode = "Express Code/Card Number must be 10, 12, 16 or 24 digits";
            }
        } else if (values?.checkType === CheckTypes.INSTAMONEY.key) {
            if ([6, 12].indexOf(length) === -1) {
                errors.expressCode = "Moneycode must be 6 or 12 digits long";
            }
        } else if (values?.checkType === CheckTypes.USBANK.key) {
            if (length < 15 || length > 20) {
                errors.expressCode = "Please enter a valid express code between 15 to 20 digits";
            }
        }
    }
    if (expirationDateRequired) {
        errors.expirationDate = "Required";
    }

    if (values?.checkType === CheckTypes.TCHECK.key && [10, 12].indexOf(length) > -1 && values?.expirationDate && !isExpirationDateValid) {
        errors.expirationDate = "Expiration date must be in the future";
    }

    return errors;
};

export const comdataExpressCodeValidator = (value?: string | null): string | undefined => {
    const length = forceValueToString(value).length;

    return isNaN(Number(value)) || length < 14 || length > 24 ? "Express Code should be 14 to 24 digits long." : undefined;
};

export const comdataCheckNumberValidator = (value?: string | null): string | undefined => {
    const length = forceValueToString(value).length;

    return isNaN(Number(value)) || length < 14 ? "Express Code must be at least 14 digits long" : undefined;
};

// Comdata Fuel Card validation
export const matchRegexFormat = (regexRules: string, value?: string): string | undefined => {
    const regex = new RegExp(`^${regexRules}$`);
    const valueToTest = value ? value : "";
    return !regex.test(valueToTest) ? `The value is invalid` : undefined;
};

export const moreThan = (min: string, value?: string, errorMessage?: string): string | undefined =>
    value && parseFloat(value) >= parseFloat(min) ? undefined : errorMessage || "Invalid Hub Reading";

export const lessThan = (max: string, value?: string, errorMessage?: string): string | undefined =>
    value && parseFloat(value) <= parseFloat(max) ? undefined : errorMessage || "Invalid Hub Reading";

// MaxLength validation rule based on tripNumberMaxLength
// @maxLength string is because the API will provide a string value
export const tripNumberMaxLength = (value?: string, maxLength?: string): string | undefined => {
    const length = forceValueToString(value).length;
    if (!maxLength) {
        maxLength = "99";
    }
    if (length > parseInt(maxLength)) {
        return `Trip Number must be shorter than ${maxLength}`;
    }
    return undefined;
};

// Invoice Builder / Remote Checkout
// src/components/invoice/ComdataFuelCardForm.tsx
export const fuelCardComdataValidateForm = (
    values: Record<string, any>,
    setValidationErrors?: (values: Record<string, any>) => void,
    preEditResponseParsed?: PreEditResponseParsed | null,
    isCardNumberRequired: boolean = true
): {} => {
    let errors = {} as any;

    if (isCardNumberRequired) {
        if (required(values.token)) {
            errors.token = required(values.token);
        }

        if (fuelCardNumber(values.token)) {
            errors.token = fuelCardNumber(values.token);
        }
    }

    if (required(values.unitNumber)) {
        errors.unitNumber = required(values.unitNumber);
    }

    // Dynamic fields
    if (requiredByIndicatorValue(preEditResponseParsed?.driverNumberIndicator) && required(values?.driverNumber)) {
        errors.driverNumber = required(values.driverNumber);
    }

    if (
        requiredByIndicatorValue(preEditResponseParsed?.driverNumberIndicator) &&
        preEditResponseParsed?.driverNumberValidation?.length &&
        preEditResponseParsed.driverNumberValidation.length > 0 &&
        values.driverNumber &&
        !!matchRegexFormat(preEditResponseParsed.driverNumberValidation, values.driverNumber)
    ) {
        errors.driverNumber = matchRegexFormat(preEditResponseParsed.driverNumberValidation, values.driverNumber);
    }

    if (requiredByIndicatorValue(preEditResponseParsed?.hubIndicator)) {
        if (required(values?.hubReading)) {
            errors.hubReading = required(values.hubReading);
        }

        if (values?.hubReading?.length && values?.hubReading.length > 0 && preEditResponseParsed?.hubLowReading && integer(values?.hubReading)) {
            errors.hubReading = integer(values?.hubReading);
        }

        if (
            values?.hubReading?.length &&
            values?.hubReading.length > 0 &&
            preEditResponseParsed?.hubLowReading &&
            moreThan(preEditResponseParsed.hubLowReading, values?.hubReading)
        ) {
            errors.hubReading = moreThan(preEditResponseParsed.hubLowReading, values?.hubReading);
        }

        if (values?.hubReading?.length && values?.hubReading.length > 0 && preEditResponseParsed?.hubHighReading && integer(values?.hubReading)) {
            errors.hubReading = integer(values?.hubReading);
        }

        if (
            values?.hubReading?.length &&
            values?.hubReading.length > 0 &&
            preEditResponseParsed?.hubHighReading &&
            lessThan(preEditResponseParsed.hubHighReading, values?.hubReading)
        ) {
            errors.hubReading = lessThan(preEditResponseParsed.hubHighReading, values?.hubReading);
        }
    }

    if (requiredByIndicatorValue(preEditResponseParsed?.trailerHubIndicator)) {
        if (required(values?.trailerHubReading)) {
            errors.trailerHubReading = required(values.trailerHubReading);
        }

        if (values?.trailerHubReading?.length && values?.trailerHubReading.length > 0 && integer(values?.trailerHubReading)) {
            errors.trailerHubReading = integer(values?.trailerHubReading);
        }
    }

    if (requiredByIndicatorValue(preEditResponseParsed?.trailerHoursIndicator)) {
        if (required(values?.trailerHours)) {
            errors.trailerHours = required(values.trailerHours);
        }

        if (values?.trailerHours?.length && values?.trailerHours.length > 0 && integer(values?.trailerHours)) {
            errors.trailerHours = integer(values?.trailerHours);
        }
    }

    if (requiredByIndicatorValue(preEditResponseParsed?.trailerNumberIndicator)) {
        if (required(values?.trailerNumber)) {
            errors.trailerNumber = required(values.trailerNumber);
        }

        if (
            preEditResponseParsed?.trailerNumberValidation?.length &&
            preEditResponseParsed.trailerNumberValidation.length > 0 &&
            values.trailerNumber &&
            !!matchRegexFormat(preEditResponseParsed.trailerNumberValidation, values.trailerNumber)
        ) {
            errors.trailerNumber = matchRegexFormat(preEditResponseParsed.trailerNumberValidation, values.trailerNumber);
        }
    }

    if (requiredByIndicatorValue(preEditResponseParsed?.tripNumberIndicator)) {
        if (required(values?.tripNumber)) {
            errors.tripNumber = required(values.tripNumber);
        }

        if (
            preEditResponseParsed?.tripNumberValidation?.length &&
            preEditResponseParsed.tripNumberValidation.length > 0 &&
            !!matchRegexFormat(preEditResponseParsed.tripNumberValidation, values?.tripNumber)
        ) {
            errors.tripNumber = matchRegexFormat(preEditResponseParsed.tripNumberValidation, values.tripNumber);
        }

        if (values?.tripNumber?.length && values?.tripNumber.length > 0 && integer(values?.tripNumber)) {
            errors.tripNumber = integer(values?.tripNumber);
        }

        if (preEditResponseParsed?.tripNumberMaxLength && tripNumberMaxLength(preEditResponseParsed.tripNumberMaxLength)) {
            errors.tripNumber = tripNumberMaxLength(preEditResponseParsed.tripNumberMaxLength);
        }
    }

    if (requiredByIndicatorValue(preEditResponseParsed?.driverLicenseNumberIndicator)) {
        if (required(values?.driversLicenseNumber)) {
            // ! pay attention to "s" in values.driversLicenseNumber, errors.driversLicenseNumber
            errors.driversLicenseNumber = required(values.driversLicenseNumber);
        }

        if (
            preEditResponseParsed?.driverLicenseNumberValidation?.length &&
            preEditResponseParsed.driverLicenseNumberValidation.length > 0 &&
            values.driversLicenseNumber &&
            !!matchRegexFormat(preEditResponseParsed.driverLicenseNumberValidation, values?.driversLicenseNumber)
        ) {
            // ! pay attention to "s" in values.driversLicenseNumber, errors.driversLicenseNumber
            // and no "s" in driverLicenseNumberValidation, driverLicenseNumberFormat
            errors.driversLicenseNumber = matchRegexFormat(preEditResponseParsed.driverLicenseNumberValidation, values.driversLicenseNumber);
        }

        // driverLicenseStateFormat depends on driverLicenseNumberIndicator
        if (preEditResponseParsed?.driverLicenseStateFormat && preEditResponseParsed?.driverLicenseStateFormat?.length > 0) {
            if (required(values?.driversLicenseState)) {
                // ! pay attention to "s" in values.driversLicenseState, errors.driversLicenseState
                errors.driversLicenseState = required(values.driversLicenseState);
            }

            if (
                preEditResponseParsed?.driverLicenseStateValidation?.length &&
                preEditResponseParsed.driverLicenseStateValidation.length > 0 &&
                values.driversLicenseState &&
                !!matchRegexFormat(preEditResponseParsed.driverLicenseStateValidation, values?.driversLicenseState)
            ) {
                // ! pay attention to "s" in values.driversLicenseState, errors.driversLicenseState
                errors.driversLicenseState = matchRegexFormat(preEditResponseParsed.driverLicenseStateValidation, values.driversLicenseState);
            }
        }
    }

    if (requiredByIndicatorValue(preEditResponseParsed?.poNumberIndicator)) {
        if (required(values?.poNumber)) {
            errors.poNumber = required(values.poNumber);
        }

        if (
            preEditResponseParsed?.poNumberValidation?.length &&
            preEditResponseParsed.poNumberValidation.length > 0 &&
            values.poNumber &&
            !!matchRegexFormat(preEditResponseParsed.poNumberValidation, values?.poNumber)
        ) {
            errors.poNumber = matchRegexFormat(preEditResponseParsed.poNumberValidation, values.poNumber);
        }
    }

    // IB needs it to block the external submit button
    setValidationErrors ? setValidationErrors(errors) : void 0;
    return errors;
};

// Invoice Builder / Remote Checkout
// src/containers/invoice/preparation/steps/NewFuelCardDetails.tsx
export const fuelCardComdataValidateChargeBtn = (
    values: Record<string, any>,
    preEditResponseParsed,
    isVgsDisabled: boolean = true,
    signatureRequired: boolean = false,
    signatureUrl?: string
): boolean => {
    if (signatureRequired && !signatureUrl) {
        return false;
    }

    if ((isVgsDisabled && (!values.token || !!fuelCardNumber(values.token) || !values.unitNumber)) || (!isVgsDisabled && !values.unitNumber)) {
        return false;
    }

    // Dynamic fields rules
    if (requiredByIndicatorValue(preEditResponseParsed?.driverNumberIndicator)) {
        if (
            !values.driverNumber ||
            (values.driverNumber &&
                preEditResponseParsed?.driverNumberValidation?.length &&
                preEditResponseParsed.driverNumberValidation.length > 0 &&
                !!matchRegexFormat(preEditResponseParsed.driverNumberValidation, values.driverNumber))
        ) {
            return false;
        }
    }

    if (requiredByIndicatorValue(preEditResponseParsed?.hubIndicator)) {
        if (
            !values.hubReading ||
            (values.hubReading && integer(values?.hubReading)) ||
            (values.hubReading && preEditResponseParsed.hubLowReading && !!moreThan(preEditResponseParsed.hubLowReading, values?.hubReading)) ||
            (values.hubReading && preEditResponseParsed.hubHighReading && !!lessThan(preEditResponseParsed.hubHighReading, values?.hubReading))
        ) {
            return false;
        }
    }

    if (requiredByIndicatorValue(preEditResponseParsed?.trailerHubIndicator)) {
        if (!values.trailerHubReading) {
            return false;
        }
    }

    if (requiredByIndicatorValue(preEditResponseParsed?.trailerHoursIndicator)) {
        if (!values.trailerHours || (values.trailerHours && integer(values?.trailerHours))) {
            return false;
        }
    }

    if (requiredByIndicatorValue(preEditResponseParsed?.trailerNumberIndicator)) {
        if (
            !values.trailerNumber ||
            (values.trailerNumber &&
                preEditResponseParsed?.trailerNumberValidation?.length &&
                preEditResponseParsed.trailerNumberValidation.length > 0 &&
                !!matchRegexFormat(preEditResponseParsed.trailerNumberValidation, values.trailerNumber))
        ) {
            return false;
        }
    }

    if (requiredByIndicatorValue(preEditResponseParsed?.tripNumberIndicator)) {
        if (
            !values.tripNumber ||
            (values.tripNumber && integer(values?.tripNumber)) ||
            (values.tripNumber &&
                preEditResponseParsed?.tripNumberValidation?.length &&
                preEditResponseParsed.tripNumberValidation.length > 0 &&
                !!matchRegexFormat(preEditResponseParsed?.tripNumberValidation, values.tripNumber))
        ) {
            return false;
        }
    }

    if (requiredByIndicatorValue(preEditResponseParsed?.driverLicenseNumberIndicator)) {
        if (
            !values.driversLicenseNumber ||
            (values.driversLicenseNumber &&
                preEditResponseParsed?.driverLicenseNumberValidation?.length &&
                preEditResponseParsed.driverLicenseNumberValidation.length > 0 &&
                !!matchRegexFormat(preEditResponseParsed?.driverLicenseNumberValidation, values.driversLicenseNumber))
        ) {
            return false;
        }

        // driverLicenseStateFormat depends on driverLicenseNumberIndicator
        if (preEditResponseParsed?.driverLicenseStateFormat && preEditResponseParsed?.driverLicenseStateFormat?.length > 0) {
            if (
                !values.driversLicenseState ||
                (values.driversLicenseState &&
                    preEditResponseParsed?.driverLicenseStateValidation?.length &&
                    preEditResponseParsed.driverLicenseStateValidation.length > 0 &&
                    !!matchRegexFormat(preEditResponseParsed?.driverLicenseStateValidation, values.driversLicenseState))
            ) {
                return false;
            }
        }
    }

    if (requiredByIndicatorValue(preEditResponseParsed?.poNumberIndicator)) {
        if (
            !values.poNumber ||
            (values.poNumber &&
                preEditResponseParsed?.poNumberValidation?.length &&
                preEditResponseParsed.poNumberValidation.length > 0 &&
                !!matchRegexFormat(preEditResponseParsed?.poNumberValidation, values.poNumber))
        ) {
            return false;
        }
    }

    return true;
};

// Invoice Builder / Remote Checkout PreEditForm
// src/components/invoice/PreEditForm.tsx
export const validatePreEdit = (
    values: Record<string, any>,
    isCardNumberRequired: boolean = true,
    setValidationErrors?: (values: Record<string, any>) => void
): {} => {
    let errors = {} as any;

    if (isCardNumberRequired) {
        if (required(values.token)) {
            errors.token = required(values.token);
        }

        if (fuelCardNumber(values.token)) {
            errors.token = fuelCardNumber(values.token);
        }
    }

    if (required(values.unitNumber)) {
        errors.unitNumber = required(values.unitNumber);
    }

    // IB needs it to block the external submit button
    setValidationErrors ? setValidationErrors(errors) : void 0;
    return errors;
};

// Invoice Builder / Remote Checkout
// src/containers/invoice/preparation/steps/NewFuelCardDetails.tsx
export const isPreEditOrComdataFuelCardFormValid = (data: preEditFormValidationErrors | comdataChargeFormValidationErrors): boolean => {
    let result = true;
    if (!!data && Object.keys(data).length > 0) {
        const keys = Object.keys(data);
        keys.forEach((key, index) => {
            if (!!data[key]) {
                result = false;
            }
        });
    }
    return result;
};

export const validatePayout = (
    values: Record<string, any>,
    setPayoutValidationErrors: (values: Record<string, any>) => void,
    maxAmount: string,
    minAmount: string
): {} => {
    let errors = {} as any;

    if (required(values.fundingSourceId)) {
        errors.fundingSourceId = required(values.fundingSourceId);
    }

    if (required(values.amount)) {
        errors.amount = required(values.amount);
    }

    if (lessThan(maxAmount, values.amount)) {
        errors.amount = lessThan(maxAmount, values.amount, "Amount must be smaller");
    }

    if (moreThan(minAmount, values.amount)) {
        errors.amount = moreThan(minAmount, values.amount, "Amount must be larger");
    }

    // is needed to block the external submit button
    setPayoutValidationErrors ? setPayoutValidationErrors(errors) : void 0;
    return errors;
};

export const minDollarValue = (min?: string | number) => (value) =>
    !min || isNaN(value) || parseFloat(value.toString()) >= parseFloat(min.toString())
        ? undefined
        : `Must be greater or equal to ${formatDollar(min)}`;

export const maxDollarValue = (max?: string | number) => (value) =>
    !max || isNaN(value) || parseFloat(value.toString()) <= parseFloat(max.toString()) ? undefined : `Must be less than ${formatDollar(max)}`;

export const driversLicenseNumber = (value: string): string | undefined => {
    if (!value) return undefined;
    return /^[a-zA-Z0-9]*$/.test(value) ? undefined : "Invalid characters";
};

export const urlValidator = (value?: string | null): string | undefined => {
    if (!value) return undefined;
    return /^http(s)?:\/\/[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/.test(value) ? undefined : "Please enter a valid URL";
};

export const baseURLValidator = (value?: string | null): string | undefined => {
    if (!value) return undefined;
    return /^[a-zA-Z0-9\s\-_]+$/.test(value) ? undefined : "Please enter a valid URL (Letters, numbers, underscores and hyphens are valid)";
};

export const internationalPhoneNumber = (value?: string, strict = false): string | undefined => {
    if (!value) return undefined;

    // The try/catch block is here since something seems to be wrong with the
    // library providing [validatePhone]. It doesn't compile properly in jest
    // and throws an error. So when running via jest we just return [undefined].
    try {
        const r = phone(value);

        return r.isValid ? undefined : "Invalid phone number";
    } catch (e) {
        return undefined;
    }
};

export const expressCodeComchek = (value?: string | null): string | undefined => {
    const length = forceValueToString(value).length;
    if (length < 14 || length > 24) {
        return "Invalid Express Code length";
    }

    return undefined;
};

export const expressCodeEfs = (value?: string | null): string | undefined => {
    const length = forceValueToString(value).length;
    if ([10, 12, 16, 19, 21].indexOf(length) === -1) {
        return "Moneycode must be 10, 12, 16, 19, or 21 characters";
    }

    return undefined;
};

export const expressCodeTchek = (value?: string | null): string | undefined => {
    const length = forceValueToString(value).length;
    if ([10, 12, 16, 24].indexOf(length) === -1) {
        return "Express Code/Card Number must be 10, 12, 16 or 24 digits";
    }

    return undefined;
};

export const expressCodeUSBank = (value?: string | null): string | undefined => {
    const length = forceValueToString(value).length;
    if (length < 15 || length > 20) {
        return "Please enter a valid express code between 15 to 20 digits";
    }

    return undefined;
};

// fixes bug in regular RC check flow validation it was always USBank. TODO think of a better way after COMDATA new flow release
export const expressCodeNumericComchek = (value?: string | null): string | undefined => {
    if (isNaN(Number(value))) {
        return `${CheckTypes.COMCHEK.display} may only be numeric.`;
    }

    return undefined;
};

// fixes bug in regular RC check flow validation it was always USBank. TODO think of a better way after COMDATA new flow release
export const expressCodeNumericEfs = (value?: string | null): string | undefined => {
    if (isNaN(Number(value))) {
        return `${CheckTypes.EFS.display} may only be numeric.`;
    }

    return undefined;
};

// fixes bug in regular RC check flow validation it was always USBank. TODO think of a better way after COMDATA new flow release
export const expressCodeNumericTchek = (value?: string | null): string | undefined => {
    if (isNaN(Number(value))) {
        return `${CheckTypes.TCHECK.display} may only be numeric.`;
    }

    return undefined;
};

// fixes bug in regular RC check flow validation it was always USBank. TODO think of a better way after COMDATA new flow release
export const expressCodeNumericUSBank = (value?: string | null): string | undefined => {
    if (isNaN(Number(value))) {
        return `${CheckTypes.USBANK.display} may only be numeric.`;
    }

    return undefined;
};

export const adminCode = (adminCode?: string): string | undefined => {
    if (adminCode) {
        if (adminCode.length !== 8 || !adminCode.startsWith("1")) {
            return "Admin Code should be 8 digits total and should starts from 1";
        }
    }
    return undefined;
};

export const expressCodeComdataLength = (value?: string | null): string | undefined => {
    const length = forceValueToString(value).length;
    const minLength = 14;
    const maxLength = 24;
    if (length < minLength) {
        return `Invalid Express Code length`;
    }
    if (length > maxLength) {
        return `Invalid Express Code length`;
    }

    return undefined;
};

export const expressCodeComdataNumeric = (value?: string | null): string | undefined => {
    if (isNaN(Number(value))) {
        return `Express Code must be numeric only`;
    }

    return undefined;
};

export const isValidPassword = (password: string): boolean => {
    const isAtLeast8CharsLong = password.length >= 8;
    const containsUppercase = /^(?=.*[A-Z])/.test(password);
    const containsNumber = /^(?=.*[0-9])/.test(password);
    const containsSymbol = /^(?=.*[!@#$%^&*])/.test(password);
    const containsLowercase = /^(?=.*[a-z])/.test(password);

    return isAtLeast8CharsLong && containsUppercase && containsNumber && containsSymbol && containsLowercase;
};

export const validateExpressPaymentForm = (
    values?: ExpressPaymentFormData,
    invoiceLineItemAmountMaximum?: number | string,
    isExternalInvoiceNumberRequired?: boolean
): {} => {
    let errors = {} as any;
    const isInvoiceLineItemAmountMaximumNonZero = invoiceLineItemAmountMaximum !== "0" && invoiceLineItemAmountMaximum !== 0;
    const validateMinValue = minDollarValue(0.01);
    const validateMaxValue = isInvoiceLineItemAmountMaximumNonZero ? maxDollarValue(invoiceLineItemAmountMaximum) : () => undefined;
    const validateMaxValueLess100k = maxDollarValue(100000.0);

    if (required(values?.location)) {
        errors.location = required(values?.location);
    }

    if (isExternalInvoiceNumberRequired) {
        if (required(values?.externalInvoiceNumber)) {
            errors.externalInvoiceNumber = required(values?.externalInvoiceNumber);
        }
    }

    if (required(values?.amount)) {
        errors.amount = required(values?.amount);
    }

    if (validateMinValue(values?.amount)) {
        errors.amount = validateMinValue(values?.amount);
    }

    if (validateMaxValue(values?.amount)) {
        errors.amount = validateMaxValue(values?.amount);
    }

    if (validateMaxValueLess100k(values?.amount)) {
        errors.amount = validateMaxValueLess100k(values?.amount);
    }

    if (values?.type !== PaymentMethods.CARD.key && required(values?.phoneOrEmail)) {
        errors.phoneOrEmail = required(values?.phoneOrEmail);
    }

    if (emailOrPhoneNumber(values?.phoneOrEmail)) {
        errors.phoneOrEmail = emailOrPhoneNumber(values?.phoneOrEmail);
    }

    return errors;
};

export const validateTowbookForm = (values: TowbookFormData, payerCheckbox: string, recipentCheckbox: string, maxAmount: string): {} => {
    let errors = {} as any;
    const validateMinValue = minDollarValue(0.01);
    const validateMaxValue = maxDollarValue(maxAmount);

    if (required(values?.location)) {
        errors.location = required(values?.location);
    }

    if (required(values?.amount)) {
        errors.amount = required(values?.amount);
    }

    if (validateMinValue(values?.amount)) {
        errors.amount = validateMinValue(values?.amount);
    }

    if (validateMaxValue(values?.amount)) {
        errors.amount = validateMaxValue(values?.amount);
    }

    if (payerCheckbox === TowbookCheckbox.account) {
        errors.accountName = required(values?.accountName);
    }

    if (payerCheckbox === TowbookCheckbox.contact) {
        errors.contactName = required(values?.contactName);
    }

    if (recipentCheckbox === TowbookCheckbox.phone) {
        if (isEmpty(values?.phone)) {
            errors.phone = required(values?.phone);
        } else {
            errors.phone = emailOrPhoneNumber(values?.phone);
        }
    }

    if (recipentCheckbox === TowbookCheckbox.email) {
        if (isEmpty(values?.email)) {
            errors.email = required(values?.email);
        } else {
            errors.email = email(values?.email);
        }
    }

    return errors;
};

export const preventCreditCardNumbers = (value?: string | null): string | undefined => {
    if (!value) {
        return undefined;
    }

    const str = value.replace(/[^0-9]+/g, "");
    return str.match(/[0-9]{15,16}/) ? "This field is for text input only." : undefined;
};

export const preventCreditCardNumbersAddress = (value?: string | null): string | undefined => {
    if (!value) {
        return undefined;
    }

    const str = value.replace(/[^0-9]+/g, "");
    return str.match(/[0-9]{15,16}/) ? "Please input a valid address for billing." : undefined;
};

export const driversLicenseNumberValidator = (value?: string | null): string | undefined => {
    return required(value) ? "Required, must match the format of the issuer's State" : undefined;
};

export function userRolesetHasError(user: Partial<User>): string | undefined {
    const roles = user.roles;
    if (!roles?.length) {
        return "At least one role is required";
    }

    if (roles?.length === 1) {
        if (roles[0] === Roles.API_USER.key) {
            return "Api user requires one more role";
        }

        return undefined;
    }

    for (const item of roles) {
        for (const role of roles) {
            if (!allowedCombosForValidation[item] || !allowedCombosForValidation[item].includes(role)) {
                return "Select allowed roles combination";
            }
        }
    }

    return undefined;
}
