export class ModalsConstants {
    static readonly DECLINE_REASON: string = "DECLINE_REASON";
    static readonly SEND_RECEIPT: string = "SEND_RECEIPT";
    static readonly LINE_ITEMS_CUSTOM_SERVICE: string = "LINE_ITEMS_CUSTOM_SERVICE";
    static readonly ADD_MULTIPLE_LINE_ITEMS: string = "ADD_MULTIPLE_LINE_ITEMS";
    static readonly ADD_PAID_INVOICE_COMMENT: string = "ADD_PAID_INVOICE_COMMENT";
    static readonly DELETE_ITEM: string = "DELETE_ITEM";
    static readonly SIGNATURE: string = "SIGNATURE";
    static readonly WORK_ORDER_SIGNATURE: string = "WORK_ORDER_SIGNATURE";
    static readonly SET_PASSWORD: string = "SET_PASSWORD";
    static readonly COPY_LOCATION: string = "COPY_LOCATION";
    static readonly CREATE_WORK_ORDER: string = "CREATE_WORK_ORDER";
    static readonly HANDLE_WORK_ORDER_DISMISS: string = "HANDLE_WORK_ORDER_DISMISS";
    static readonly WORK_ORDER_PDF: string = "WORK_ORDER_PDF";
    static readonly WORK_ORDER_PDF_FOR_EMPLOYEES: string = "WORK_ORDER_PDF_FOR_EMPLOYEES";
    static readonly SEND_WORK_ORDER: string = "SEND_WORK_ORDER";
    static readonly SEND_INVOICE_FOR_COMPLETION: string = "SEND_INVOICE_FOR_COMPLETION";
    static readonly CHECK_AUTHORIZER_CALLER: string = "CHECK_AUTHORIZER_CALLER";
    static readonly TERMS_AND_CONDITIONS: string = "TERMS_AND_CONDITIONS";
    static readonly TWO_FACTOR_AUTHENTICATION: string = "TWO_FACTOR_AUTHENTICATION";
    static readonly DELETE_INVOICE_REASON: string = "DELETE_INVOICE_REASON";
    static readonly CONFIRM_INVOICE_TOTAL: string = "CONFIRM_INVOICE_TOTAL";
    static readonly CONFIRM_INVOICE_CREATION: string = "CONFIRM_INVOICE_CREATION";
    static readonly FLEET_CARD_CHECK: string = "FLEET_CARD_CHECK";
    static readonly CHANGE_BANK_ACCOUNT: string = "CHANGE_BANK_ACCOUNT";
    static readonly SEND_REMOTE_CHECKOUT_INVOICE: string = "SEND_REMOTE_CHECKOUT_INVOICE";
    static readonly LOCK_OUT_LOCATION_USERS: string = "LOCK_OUT_LOCATION_USERS";
    static readonly LOCK_OUT_COMPANY_USERS: string = "LOCK_OUT_COMPANY_USERS";
    static readonly SSO_LOCK_OUT: string = "SSO_LOCK_OUT";
    static readonly DOWNLOAD_APP: string = "DOWNLOAD_APP";
    static readonly REPORT_REDIRECT: string = "REPORT_REDIRECT";
    static readonly PAYER_LOGIN_REDIRECT: string = "PAYER_LOGIN_REDIRECT";
    static readonly SSO_ERROR: string = "SSO_ERROR";
    static readonly TOWBOOK_INVOICE_ERROR: string = "TOWBOOK_INVOICE_ERROR";
    static readonly ADDRESS_BOOK_ADD_NEW_PAYER: string = "ADDRESS_BOOK_ADD_NEW_PAYER";
}

