import { GlobalState } from "../types";
import { createSelector } from "reselect";

export const getCompanyLocations = (state: GlobalState) => state.locations.data;

export const getCompanyLocationsList = createSelector([getCompanyLocations], (locations) => {
    return Object.values(locations || {});
});

export const getActiveCompanyLocationsList = createSelector([getCompanyLocationsList], (locations) => {
    return locations.filter(({ isDeactivated }) => !isDeactivated);
});

