export class AuthEndpoints {
    static readonly LOGIN: string = "/api/v1/auth/login";
    static readonly REFRESH_ACCESS_TOKEN: string = "/api/v1/auth/refresh-token";
    static readonly LOGOUT: string = "/api/v1/auth/logout";
    static readonly ME: string = "/api/v1/auth/me";
    static readonly FORGOT: string = "/api/v1/auth/forgot";
    static readonly RESET: string = "/api/v1/auth/reset";
    static readonly CHANGE: string = "/api/v1/auth/change";
    static readonly SET_PASSWORD: string = "/api/v1/auth/set-password";
    static readonly IMPERSONATE_LOGIN: string = "/api/v1/auth/impersonate/login";
    static readonly IMPERSONATE_RESET: string = "/api/v1/auth/impersonate/reset";
    static readonly SIGNUP: string = "/api/v1/signup";
    static readonly PUBLICSIGNUP: string = "/api/v1/public/users";
    static readonly PLAID_LINK_TOKEN: string = "wallet/v2/plaid-link-token";
    static LOGIN_TOKEN = (token: string) => `api/v1/auth/login/${token}`;
    static LOGIN_SSO_CODE = (code: string) => `api/v1/sso?code=${code}`;
}

