import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { auth } from "./auth";
import { users } from "./users";
import { companies } from "./companies";
import { locations } from "./locations";
import { products } from "./products";
import { invoices } from "./invoices";
import { deposits } from "./deposits";
import { sockets } from "./sockets";
import { publicData } from "./public";
import { alerts } from "./alerts";
import { breadcrumbs } from "./breadcrumbs";
import { logs } from "./logs";
import { bank } from "./bank";
import { appSettings } from "./appSettings";
import { publicAppSettings } from "./publicAppSettings";
import { checks } from "./checks";
import { mobileMenu } from "./mobileMenu";
import { fees } from "./fees";
import { features } from "./features";
import { departments } from "./departments";
import { shifts } from "./shifts";
import { modals } from "./modals";
import { report } from "./report";
import { reportRecipients } from "./reportRecipients";
import { emailsLogs } from "./emailsLogs";
import { workOrders } from "./workOrders";
import { workOrderTemplates } from "./workOrderTemplates";
import { labels } from "./labels";
import { checkAuthorizer } from "./checkAuthorizer";
import { files } from "./files";
import { companySettings } from "./companySettings";
import { reportSettings } from "./reportSettings";
import { carriers } from "./carriers";
import { twoFactor } from "./twoFactor";
import { cardAccount } from "./cardAccount";
import { selectedMenuItem } from "./selectedMenuItem";
import { accountingSettings } from "./accountingSettings";
import { beneficialOwner } from "./beneficialOwner";
import { accountingProductsList } from "./accountingProductsList";
import { universalCarriers } from "./universalCarriers";
import { transfers } from "./transfers";
import { InvoicePreparationReducer } from "./invoice.preparation.reducer";
import { PwaPreparerReducer } from "./pwa.preparer.reducer";
import { ads } from "./ads";
import { instantPayoutReducer } from "./instantPayout";
import { invoicePriceUpdatedModal } from "./invoicePriceUpdatedModal";
import { quickbooks } from "./quickbooks";

export default combineReducers<any>({
    form: formReducer,
    auth,
    users,
    companies,
    locations,
    products,
    invoices,
    deposits,
    sockets,
    publicData,
    alerts,
    breadcrumbs,
    logs,
    bank,
    appSettings,
    publicAppSettings,
    checks,
    mobileMenu,
    fees,
    features,
    departments,
    shifts,
    modals,
    report,
    reportRecipients,
    emailsLogs,
    workOrders,
    workOrderTemplates,
    labels,
    checkAuthorizer,
    files,
    companySettings,
    reportSettings,
    carriers,
    twoFactor,
    cardAccount,
    selectedMenuItem,
    accountingSettings,
    beneficialOwner,
    accountingProductsList,
    universalCarriers,
    transfers,
    invoicePreparation: InvoicePreparationReducer,
    pwaPreparer: PwaPreparerReducer,
    ads,
    instantPayout: instantPayoutReducer,
    invoicePriceUpdatedModal,
    quickbooks,
});
