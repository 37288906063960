import { createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "column",
        },
        textContainer: {
            marginBottom: "15px",
        },

        icon: {
            fontSize: "200px",
            color: theme.palette.warning.light,
        },
        paragraph: {
            marginBottom: "15px",
            marginTop: "10px",
        },
    })
);

export default useStyles;

