import { PreparationSteps } from "../constants/invoice";
import { InvoicePreparationStep } from "../types/Invoice";
import { InvoiceStepCategories } from "../constants/invoiceStepCategory";
import Loader from "../components/app/Loader";
import NoLocationsScreen from "../containers/invoice/preparation/steps/NoLocationsScreen";
import NoProductsScreen from "../containers/invoice/preparation/steps/NoProductsScreen";
import PayerInfo from "../containers/invoice/preparation/steps/PayerInfo";
import LocationCustomFields from "../containers/invoice/preparation/steps/LocationCustomFields";
import LineItems from "../containers/invoice/preparation/steps/LineItems";
import PaymentMethod from "../containers/invoice/preparation/steps/PaymentMethod";
import ConfirmCheckNumber from "../containers/invoice/preparation/steps/ConfirmCheckNumber";
import PaymentMethodExists from "../containers/invoice/preparation/steps/PaymentMethodExists";
import SendDriverCheckNumber from "../containers/invoice/preparation/steps/SendDriverCheckNumber";
import CashDetails from "../containers/invoice/preparation/steps/CashDetails";
import CheckDetails from "../containers/invoice/preparation/steps/CheckDetails";
import CardDetails from "../containers/invoice/preparation/steps/CardDetails";
import SelectFleetCardType from "../containers/invoice/preparation/steps/SelectFleetCardType";
import FleetCardCheckNumber from "../containers/invoice/preparation/steps/FleetCardCheckNumber";
import SendInvoiceDetailsToPayer from "../containers/invoice/preparation/steps/SendInvoiceDetailsToPayer";
import AttachFile from "../containers/invoice/preparation/steps/AttachFile";
import SendForRemoteCheckout from "../containers/invoice/preparation/steps/SendForRemoteCheckout";
import CheckTypes from "../containers/invoice/preparation/steps/CheckTypes";
import FuelCardDetails from "../containers/invoice/preparation/steps/FuelCardDetails";
import NewFuelCardDetails from "../containers/invoice/preparation/steps/NewFuelCardDetails";
import NotAllowedToEdit from "../containers/invoice/preparation/steps/NotAllowedToEdit";
import FuelCardPaymentSelectionScreen from "../containers/invoice/preparation/steps/FuelCardPaymentSelectionScreen";
import NewFuelCardPaymentSelectionScreen from "../containers/invoice/preparation/steps/NewFuelCardPaymentSelectionScreen";
import ComdataConfirmRegisterCheckToCard from "../containers/invoice/preparation/steps/ComdataConfirmRegisterCheckToCard";
import DirectPaymentCheckout from "../containers/invoice/preparation/steps/DirectPaymentCheckout";
import RainforestCardDetails from "../containers/invoice/preparation/steps/RainforestCardDetails";
import ImportTowbookContainer from "../containers/invoice/importFromTowBook/step1/ImportTowbookContainer";

export const InvoicePreparationSteps: InvoicePreparationStep[] = [
    {
        stepName: PreparationSteps.LOADING,
        component: Loader,
    },
    {
        stepName: PreparationSteps.NO_LOCATIONS,
        component: NoLocationsScreen,
    },
    {
        stepName: PreparationSteps.NO_PRODUCTS,
        component: NoProductsScreen,
        previousStep: PreparationSteps.PAYER_INFO,
    },
    {
        stepName: PreparationSteps.PAYER_INFO,
        component: PayerInfo,
        category: InvoiceStepCategories.INFO.key,
        pageHeader: "Add driver & customer info",
    },
    {
        stepName: PreparationSteps.LOCATION_CUSTOM_FIELDS,
        component: LocationCustomFields,
        previousStep: PreparationSteps.PAYER_INFO,
        category: InvoiceStepCategories.INFO.key,
        pageHeader: "Add additional info",
    },
    {
        stepName: PreparationSteps.IMPORT_TOWBOOK,
        component: ImportTowbookContainer,
        category: InvoiceStepCategories.INFO.key,
    },
    {
        stepName: PreparationSteps.EXTERNAL_PAYMENT_METHOD,
        component: PaymentMethod,
        previousStep: PreparationSteps.IMPORT_TOWBOOK,
        category: InvoiceStepCategories.PAYMENT.key,
    },
    {
        stepName: PreparationSteps.LINE_ITEMS,
        component: LineItems,
        previewInvoice: false,
        previousStep: PreparationSteps.PAYMENT_METHOD,
        category: InvoiceStepCategories.INVOICE.key,
        pageHeader: "Select products and services",
    },
    {
        stepName: PreparationSteps.PAYMENT_METHOD,
        component: PaymentMethod,
        previousStep: PreparationSteps.LOCATION_CUSTOM_FIELDS,
        category: InvoiceStepCategories.PAYMENT.key,
        pageHeader: "How is the driver paying?",
    },
    {
        stepName: PreparationSteps.CHECK_TYPES,
        component: CheckTypes,
        previousStep: PreparationSteps.PAYMENT_METHOD,
        category: InvoiceStepCategories.PAYMENT.key,
        pageHeader: "Check Types",
    },
    {
        stepName: PreparationSteps.COMDATA_CONFIRM_REGISTER_CHECK_TO_CARD,
        component: ComdataConfirmRegisterCheckToCard,
        previousStep: PreparationSteps.CHECK_TYPES,
        category: InvoiceStepCategories.PAYMENT.key,
        pageHeader: "Do you need to register this check to a card?",
    },
    {
        stepName: PreparationSteps.CHECK_PAYMENT_CODE,
        component: ConfirmCheckNumber,
        previousStep: PreparationSteps.PAYMENT_METHOD,
        category: InvoiceStepCategories.PAYMENT.key,
    },
    {
        stepName: PreparationSteps.PAYMENT_METHOD_EXISTS,
        component: PaymentMethodExists,
        previousStep: PreparationSteps.ATTACH_FILE,
        category: InvoiceStepCategories.PAYMENT.key,
    },
    {
        stepName: PreparationSteps.SEND_CHECK_INFO_TO_DRIVER,
        component: SendDriverCheckNumber,
        previousStep: PreparationSteps.CHECK_PAYMENT_CODE,
        category: InvoiceStepCategories.PAYMENT.key,
        pageHeader: "Send info to a driver",
    },
    {
        stepName: PreparationSteps.CHECK_DETAILS,
        component: CheckDetails,
        previousStep: PreparationSteps.LINE_ITEMS,
        category: InvoiceStepCategories.CHECKOUT.key,
        pageHeader: "Confirm and finalize the invoice",
    },
    {
        stepName: PreparationSteps.CARD_DETAILS,
        component: CardDetails,
        previousStep: PreparationSteps.LINE_ITEMS,
        category: InvoiceStepCategories.CHECKOUT.key,
        pageHeader: "Confirm and finalize the invoice",
    },
    {
        stepName: PreparationSteps.RAINFOREST_CARD_DETAILS,
        component: RainforestCardDetails,
        previousStep: PreparationSteps.LINE_ITEMS,
        category: InvoiceStepCategories.CHECKOUT.key,
        pageHeader: "Confirm and finalize the invoice",
    },
    {
        stepName: PreparationSteps.CASH_DETAILS,
        component: CashDetails,
        previousStep: PreparationSteps.LINE_ITEMS,
        category: InvoiceStepCategories.CHECKOUT.key,
        pageHeader: "Confirm and finalize the invoice",
    },
    {
        stepName: PreparationSteps.SELECT_FLEET_CARD_TYPE,
        component: SelectFleetCardType,
        previousStep: PreparationSteps.PAYMENT_METHOD,
        category: InvoiceStepCategories.PAYMENT.key,
        pageHeader: "Select Fleet Check Type",
    },
    {
        stepName: PreparationSteps.FLEET_CARD_CHECK_NUMBER,
        component: FleetCardCheckNumber,
        previousStep: PreparationSteps.SELECT_FLEET_CARD_TYPE,
        category: InvoiceStepCategories.PAYMENT.key,
    },
    {
        stepName: PreparationSteps.SEND_FOR_REMOTE_CHECKOUT,
        component: SendForRemoteCheckout,
        previousStep: PreparationSteps.LINE_ITEMS,
        category: InvoiceStepCategories.CHECKOUT.key,
        pageHeader: "Enter recipient email or phone number.",
    },
    {
        stepName: PreparationSteps.DIRECT_PAYMENT,
        component: DirectPaymentCheckout,
        previousStep: PreparationSteps.LINE_ITEMS,
        category: InvoiceStepCategories.CHECKOUT.key,
        pageHeader: "Confirm Invoice and Checkout",
    },
    {
        stepName: PreparationSteps.ATTACH_FILE,
        component: AttachFile,
        previousStep: PreparationSteps.LOCATION_CUSTOM_FIELDS,
        category: InvoiceStepCategories.ATTACH.key,
        pageHeader: "Attach Files (Optional)",
    },
    {
        stepName: PreparationSteps.INVOICE_DETAILS,
        component: LineItems,
        previewInvoice: true,
        previousStep: PreparationSteps.LINE_ITEMS,
        category: InvoiceStepCategories.INVOICE.key,
        pageHeader: "Review invoice details",
    },
    {
        stepName: PreparationSteps.SEND_INVOICE_DETAILS_TO_PAYER,
        component: SendInvoiceDetailsToPayer,
        previousStep: PreparationSteps.LINE_ITEMS,
        pageHeader: "Enter payer email or phone number.",
    },
    {
        stepName: PreparationSteps.FUEL_CARD_DETAILS,
        component: FuelCardDetails,
        previousStep: PreparationSteps.LINE_ITEMS,
        category: InvoiceStepCategories.CHECKOUT.key,
    },
    //new componet to handle comdata card selection
    {
        stepName: PreparationSteps.NEW_FUEL_CARD_DETAILS,
        component: NewFuelCardDetails,
        previousStep: PreparationSteps.LINE_ITEMS,
        category: InvoiceStepCategories.CHECKOUT.key,
    },
    {
        stepName: PreparationSteps.FUEL_CARD_PAYMENT_METHODS,
        component: FuelCardPaymentSelectionScreen,
        previousStep: PreparationSteps.PAYMENT_METHOD,
        category: InvoiceStepCategories.PAYMENT.key,
    },
    {
        stepName: PreparationSteps.NEW_FUEL_CARD_PAYMENT_METHODS,
        component: NewFuelCardPaymentSelectionScreen,
        previousStep: PreparationSteps.PAYMENT_METHOD,
        category: InvoiceStepCategories.PAYMENT.key,
    },
    {
        stepName: PreparationSteps.NOT_ALLOWED_TO_EDIT,
        component: NotAllowedToEdit,
    },
];
