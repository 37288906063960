import React from "react";
import { Switch, Route } from "react-router-dom";
import { Roles } from "./constants/roles";
import AuthorizedRoute from "./containers/app/AuthorizedRoute";
import Bundle from "./containers/app/Bundle";
import NotFound from "./components/notFound/NotFound";
import { Redirect } from "react-router";
import { AuthPaths } from "./services/app/paths";

const bundle = (mod: Promise<{}>, cb: Function): JSX.Element => <Bundle bundle={mod} ready={cb} />;

const index = (cb: Function): JSX.Element => bundle(import("./containers/bundles/index"), cb);
const auth = (cb: Function): JSX.Element => bundle(import("./containers/bundles/auth"), cb);
const admin = (cb: Function): JSX.Element => bundle(import("./containers/bundles/admin"), cb);
const invoices = (cb: Function): JSX.Element => bundle(import("./containers/bundles/invoices"), cb);
const workorders = (cb: Function): JSX.Element => bundle(import("./containers/bundles/workorders"), cb);
const workordersPublic = (cb: Function): JSX.Element => bundle(import("./containers/bundles/workordersPublic"), cb);
const checksAndReports = (cb: Function): JSX.Element => bundle(import("./containers/bundles/checks"), cb);
const dashboard = (cb: Function): JSX.Element => bundle(import("./containers/bundles/dashboard"), cb);
const remoteCheckout = (cb: Function): JSX.Element => bundle(import("./containers/bundles/remoteCheckout"), cb);
const checkAuthorizer = (cb: Function): JSX.Element => bundle(import("./containers/bundles/checkAuthorizer"), cb);
const transfers = (cb: Function): JSX.Element => bundle(import("./containers/bundles/transfers"), cb);
const PWAInstaller = (cb: Function): JSX.Element => bundle(import("./containers/bundles/pwaInstaller"), cb);

// eslint-disable-next-line max-lines-per-function
export const Routes: React.FC = () => (
    <Switch>
        <Route exact path="/" render={(): JSX.Element => <Redirect to="/index" />} />

        <Route path="/pwa" render={(): JSX.Element => PWAInstaller((mod) => mod.PwaInstaller)} />

        <Route path={AuthPaths.payerLoginUrl()} render={(): JSX.Element => remoteCheckout((mod) => mod.LoginPage)} />
        <Route path={AuthPaths.payerSignupUrl()} render={(): JSX.Element => remoteCheckout((mod) => mod.PayerSignupPage)} />
        <Route path="/p/i" render={(): JSX.Element => remoteCheckout((mod) => mod.RemoteCheckoutArea)} />

        <Route path="/forgot" render={(): JSX.Element => auth((mod) => mod.ForgotPasswordForm)} />
        <Route path="/reset/:code" render={(): JSX.Element => auth((mod) => mod.ResetPasswordForm)} />
        <Route path="/login" render={(): JSX.Element => auth((mod) => mod.LoginForm)} />
        <Route path="/sso-callback" render={(): JSX.Element => auth((mod) => mod.SSOCallback)} />
        <Route path="/login/redirect/:previousRoute" render={(): JSX.Element => auth((mod) => mod.LoginForm)} />

        <AuthorizedRoute
            roles={[
                Roles.EMPLOYEE,
                Roles.RSEMPLOYEE,
                Roles.SUPER_ADMIN,
                Roles.COMPANY_ADMIN,
                Roles.ACCOUNTANT,
                Roles.CLIENT_SUPPORT,
                Roles.RS_IMPLEMENTATION,
            ]}
            path="/index"
            render={(): JSX.Element => index((mod) => mod.Index)}
        />
        <AuthorizedRoute
            roles={[
                Roles.EMPLOYEE,
                Roles.RSEMPLOYEE,
                Roles.SUPER_ADMIN,
                Roles.RS_IMPLEMENTATION,
                Roles.COMPANY_ADMIN,
                Roles.ACCOUNTANT,
                Roles.CLIENT_SUPPORT,
            ]}
            path="/change"
            render={(): JSX.Element => auth((mod) => mod.ChangePasswordForm)}
        />
        <AuthorizedRoute roles={[Roles.SUPER_ADMIN]} path="/tfa" render={(): JSX.Element => auth((mod) => mod.TwoFactorArea)} />
        <AuthorizedRoute
            roles={[Roles.EMPLOYEE, Roles.COMPANY_ADMIN]}
            path="/select-location/:redirect"
            render={(): JSX.Element => auth((mod) => mod.LocationList)}
        />
        <AuthorizedRoute
            roles={[Roles.EMPLOYEE]}
            path="/select-department-shift"
            render={(): JSX.Element => auth((mod) => mod.DepartmentShiftSelection)}
        />
        <AuthorizedRoute
            roles={[Roles.SUPER_ADMIN, Roles.ACCOUNTANT, Roles.FINANCIAL_ADMIN]}
            path="/dashboard"
            render={(): JSX.Element => dashboard((mod) => mod.DashboardArea)}
            requireDefaultsForEmployee
        />
        <AuthorizedRoute
            roles={[
                Roles.EMPLOYEE,
                Roles.RSEMPLOYEE,
                Roles.SUPER_ADMIN,
                Roles.COMPANY_ADMIN,
                Roles.ACCOUNTANT,
                Roles.CLIENT_SUPPORT,
                Roles.RS_IMPLEMENTATION,
            ]}
            path="/invoices"
            render={(): JSX.Element => invoices((mod) => mod.InvoiceArea)}
            requireDefaultsForEmployee
        />
        <AuthorizedRoute
            roles={[
                Roles.EMPLOYEE,
                Roles.RSEMPLOYEE,
                Roles.SUPER_ADMIN,
                Roles.RS_IMPLEMENTATION,
                Roles.COMPANY_ADMIN,
                Roles.ACCOUNTANT,
                Roles.CLIENT_SUPPORT,
            ]}
            path="/workorders"
            render={(): JSX.Element => workorders((mod) => mod.WorkOrderArea)}
            requireDefaultsForEmployee
        />
        <Route path="/public/workorder/:workOrderToken/approved" render={(): JSX.Element => workordersPublic((mod) => mod.WorkOrderPublicApproved)} />
        <Route path="/p/wo/:workOrderToken" render={(): JSX.Element => workordersPublic((mod) => mod.WorkOrderPublicAuthorizationForm)} />
        <AuthorizedRoute
            roles={[
                Roles.EMPLOYEE,
                Roles.COMPANY_ADMIN,
                Roles.RSEMPLOYEE,
                Roles.SUPER_ADMIN,
                Roles.RSEMPLOYEE,
                Roles.ACCOUNTANT,
                Roles.CLIENT_SUPPORT,
            ]}
            path="/reports"
            requireDefaultsForEmployee
            render={(): JSX.Element => checksAndReports((mod) => mod.ReportArea)}
        />
        <AuthorizedRoute
            roles={[
                Roles.SUPER_ADMIN,
                Roles.RS_IMPLEMENTATION,
                Roles.COMPANY_ADMIN,
                Roles.ACCOUNTANT,
                Roles.CLIENT_SUPPORT,
                Roles.RSEMPLOYEE,
                Roles.EMPLOYEE,
            ]}
            path="/admin"
            render={(): JSX.Element => admin((mod) => mod.AdminArea)}
        />
        <AuthorizedRoute
            roles={[Roles.SUPER_ADMIN, Roles.RS_IMPLEMENTATION, Roles.RSEMPLOYEE]}
            path="/checks"
            render={(): JSX.Element => checksAndReports((mod) => mod.CheckArea)}
        />
        <AuthorizedRoute
            roles={[Roles.SUPER_ADMIN, Roles.RS_IMPLEMENTATION, Roles.RSEMPLOYEE]}
            path="/check-authorizer"
            render={(): JSX.Element => checkAuthorizer((mod) => mod.CheckAuthorizerArea)}
        />
        <AuthorizedRoute
            roles={[
                Roles.SUPER_ADMIN,
                Roles.RS_IMPLEMENTATION,
                Roles.RSEMPLOYEE,
                Roles.COMPANY_ADMIN,
                Roles.ACCOUNTANT,
                Roles.EMPLOYEE,
                Roles.CLIENT_SUPPORT,
            ]}
            path="/verification/:userId"
            render={(): JSX.Element => auth((mod) => mod.VerifyForm)}
        />
        <AuthorizedRoute roles={[Roles.SUPER_ADMIN]} path="/transfers" render={(): JSX.Element => transfers((mod) => mod.TransferIndex)} />
        <Route path="/mobile/redirect" render={(): JSX.Element => auth((mod) => mod.RSEmployeeMobileRedirect)} />
        <AuthorizedRoute
            roles={[Roles.RS_IMPLEMENTATION, Roles.RSEMPLOYEE, Roles.COMPANY_ADMIN, Roles.CLIENT_SUPPORT, Roles.ACCOUNTANT, Roles.EMPLOYEE]}
            path="/help-me"
            render={(): JSX.Element => admin((mod) => mod.HelpMeArea)}
        />
        <Route path="/**" component={NotFound} />
    </Switch>
);
