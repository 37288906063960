import _ from "lodash";
import { QuickbooksImport } from "../types";
import { QuickbooksActions } from "../constants/actions";
export interface QuickbooksUIState {
    loading?: boolean;
    error?: string;
    searchQuery: string;
    page: number;
}

export interface QuickbooksState {
    data: { [id: string]: QuickbooksImport };
    ui: QuickbooksUIState;
}

export function quickbooks(
    state: QuickbooksState = {
        data: {},
        ui: { loading: false, page: 1, searchQuery: "" },
    },
    action: Record<string, any> = {}
): QuickbooksState {
    switch (action.type) {
        case QuickbooksActions.RESET_QUICKBOOKS_STATE:
            return {
                data: {},
                ui: { loading: false, page: 1, searchQuery: "" },
            };
        case QuickbooksActions.RECEIVE_QUICKBOOKS_INVOICES:
            return {
                data: { ...action.payload },
                ui: {
                    searchQuery: "",
                    page: 1,
                    loading: false,
                },
            };
        case QuickbooksActions.SET_QUICKBOOKS_INVOICE_STATE:
            const currentInvoice = state.data[action.payload.id];
            const newInvoice = { ...currentInvoice, ...action.payload.invoice };
            const invalid = !newInvoice.email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(newInvoice.email);
            return _.set({ ...state }, `data.${action.payload.id}`, { ...newInvoice, valid: !invalid });
        case QuickbooksActions.SET_QUICKBOOKS_INVOICES_ALL_SELECTION:
            const { ids, selectionState } = action.payload;
            const updatedData = { ...state.data };

            ids.forEach((id) => {
                if (updatedData[id]) {
                    _.set(updatedData, `${id}.selected`, selectionState);
                }
            });
            return _.set({ ...state }, `ui.data`, updatedData);
        case QuickbooksActions.SET_QUICKBOOKS_LOADING:
            return _.set({ ...state }, `ui.loading`, action.payload);
        case QuickbooksActions.SET_QUICKBOOKS_INVOICES_FILTER:
            return _.set({ ...state }, `ui`, { ...state.ui, ...action.payload });
        case QuickbooksActions.RESET_QUICKBOOKS_INVOICES_BY_FILTER:
            return _.set({ ...state }, `ui`, {
                searchQuery: "",
                page: 1,
                loading: state.ui.loading,
            });
        default:
            return {
                ...state,
            };
    }
}

