import { request, Method } from "./http";
import { VGSForm, VgsTokenResponse } from "../";

export interface FundingSourceAddress {
    first_name: string;
    last_name: string;
    country?: string;
    nickname: string;
    address_1: string;
    address_2: string;
    city: string;
    state: string;
    zip: string;
    email: string;
    phone: string;
}

export interface FundingSourceCreateRequest {
    type: string;
    nickname: string;
    token: string;
    tokenizer: string;
    push_enabled: boolean;
    address: FundingSourceAddress;
    cvv_token: string;
    expiration: string;
}

export interface AstraFundingSource {
    account_number: string;
    address: FundingSourceAddress;
    address_id: number;
    brand: string;
    created_at: string;
    cvv_token: string;
    deleted_at: string;
    expiration: string;
    external_ref_id: string;
    id: number;
    mask: string;
    next_step?: {
        type: "interactive_url";
        flow: "user_authorization";
        attrs: {
            url?: string;
        };
    };
    nickname: string;
    pull_enabled: boolean;
    push_enabled: boolean;
    routing_number: string;
    service: string;
    service_account_id: number;
    status: string;
    token: string;
    token_ref_id: string;
    tokenizer: string;
    type: string;
    updated_at: string;
    user_id: string;
    validator: string;
    validator_ref_id: string;
    wallet_id: number;
    errors: AstraFundingSourceErrorObject[];
}

export interface AstraFundingSourceError {
    message: AstraFundingSourceErrorObject;
    status: Number;
    statusText: string;
    errors?: AstraFundingSourceErrorObject[];
}
export interface AstraFundingSourceErrorObject {
    code: string;
    duplicate_id?: Number;
    title: string;
}

function _createUrl(domain: string, path: string): string {
    if (!domain) {
        throw Error("Invalid API domain");
    }
    if (!path) {
        throw Error("Invalid API path");
    }
    return domain + (domain.endsWith("/") ? "" : "/") + path;
}

/**
 *
 * @param walletApiBaseUrl The API base URL, typically "https://<env>.api.roadsync.app"
 * @param req
 * @param auth
 * @returns
 */
export async function createFundingSource(walletApiBaseUrl: string = "", req: FundingSourceCreateRequest): Promise<AstraFundingSource> {
    if (!walletApiBaseUrl) {
        throw Error("Invalid auth API domain");
    }

    const r = await request<{ data: AstraFundingSource }>(
        Method?.POST,
        _createUrl(walletApiBaseUrl, "wallet/v3/funding_sources"),
        JSON.stringify(req),
        true,
        _createHeaders(),
        "omit"
    );

    return r.data;
}

/**
 *
 * @param fundingSourceId
 * @param walletApiBaseUrl The API base URL, typically "https://<env>.api.roadsync.app"
 * @param req
 * @param auth
 * @returns
 */
export async function patchFundingSource(
    fundingSourceId: string,
    walletApiBaseUrl: string = "",
    req: FundingSourceCreateRequest
): Promise<AstraFundingSource> {
    if (!fundingSourceId) {
        throw Error("Invalid funding source id");
    }

    if (!walletApiBaseUrl) {
        throw Error("Invalid auth API domain");
    }

    const r = await request<{ data: AstraFundingSource }>(
        Method?.PATCH,
        _createUrl(walletApiBaseUrl, `wallet/v3/funding_sources/${fundingSourceId}`),
        JSON.stringify(req),
        true,
        _createHeaders(),
        "omit"
    );

    return r.data;
}

export async function getFundingSource(walletApiBaseUrl: string = "", id: string): Promise<AstraFundingSource> {
    const r = await request<{ data: AstraFundingSource }>(
        Method?.GET,
        _createUrl(walletApiBaseUrl, "wallet/v3/funding_sources/" + id),
        null,
        true,
        undefined,
        "omit"
    );

    return r.data;
}

/**
 *
 * @param walletApiBaseUrl The API base URL, typically "https://<env>.api.roadsync.app"
 * @param auth
 * @returns
 */
export async function getListOfFundingSources(walletApiBaseUrl: string, companyId: string): Promise<AstraFundingSource[]> {
    if (!walletApiBaseUrl) {
        throw Error("Invalid auth API domain");
    }

    const r = await request<{ data: AstraFundingSource[] }>(
        Method?.GET,
        _createUrl(walletApiBaseUrl, `wallet/v3/funding_sources/company/${companyId}?type=card&status=approved&push_enabled=true`),
        null,
        true,
        undefined,
        "omit"
    );

    return r.data;
}
/**
 *
 * @param walletApiBaseUrl The API base URL, typically "https://<env>.api.roadsync.app"
 * @param fundingSourceId
 * @param auth
 * @returns
 */
export async function deleteFundingSource(walletApiBaseUrl: string, fundingSourceId: string): Promise<void> {
    if (!walletApiBaseUrl) {
        throw Error("Invalid auth API domain");
    }

    await request<{}>(Method?.DELETE, _createUrl(walletApiBaseUrl, `wallet/v3/funding_sources/${fundingSourceId}`), null, true, undefined, "omit");
}

export function getVgsToken(vgsForm: VGSForm, callback?: () => void): Promise<VgsTokenResponse> {
    return new Promise((resolve, reject) => {
        vgsForm?.submit(
            "/post",
            {},
            (status: number, response: any) => {
                if (status === 200) {
                    resolve(response);
                } else {
                    callback ? callback() : void 0;
                    reject(response);
                }
            },
            (error: any) => {
                reject(error);
                callback ? callback() : void 0;
            }
        );
    });
}

function _createHeaders(init?: HeadersInit) {
    return new Headers({ "content-type": "application/json", ...init });
}
