import { TokenRefreshResponse } from "../../types";
import { AuthPaths } from "../app/paths";
import { AuthEndpoints } from "./endpoints";
import { fetchWithHeaders, parseResponse, normalFetch, createURL, isRefreshTokenValid, getStoredTokens, storeTokens } from "./utils";

export enum Method {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    PATCH = "PATCH",
    DELETE = "DELETE",
}

/**
 * Makes an HTTP request.
 *
 * @template T - The type of the response data.
 * @param {Method} method - The HTTP method to use for the request.
 * @param {string} url - The URL to send the request to.
 * @param {string | Blob | ArrayBufferView | ArrayBuffer | FormData | URLSearchParams | ReadableStream<Uint8Array> | null} [body] - The body of the request.
 * @param {boolean} [withAuthToken=true] - Whether to include the authentication token in the request headers.
 * @param {Headers} [headers] - Additional headers to include in the request.
 * @param {RequestCredentials} [credentials] - The credentials mode to use for the request.
 */
export async function request<T>(
    method: Method,
    url: string,
    body?: string | Blob | ArrayBufferView | ArrayBuffer | FormData | URLSearchParams | ReadableStream<Uint8Array> | null,
    withAuthToken = true,
    headers?: Headers,
    credentials?: RequestCredentials
): Promise<T> {
    const _credentials = credentials ?? "include";

    const tokenValid = isRefreshTokenValid();

    if (withAuthToken && tokenValid === "invalid" && url !== AuthEndpoints.REFRESH_ACCESS_TOKEN) {
        const { refreshToken } = getStoredTokens();
        try {
            const tokens = await normalFetch(AuthEndpoints.REFRESH_ACCESS_TOKEN, {
                method: "POST",
                credentials: "include",
                body: JSON.stringify({ refreshToken, username: "dummy" }),
            });

            const typed = (await tokens.json()) as TokenRefreshResponse;
            storeTokens(typed.access_token, typed.refresh_token, typed.expires_in);
        } catch (e) {
            console.error("Error refreshing access token", e);
            window.location.href = AuthPaths.ssoLink(true);
        }
    }

    return parseResponse(
        await (withAuthToken ? fetchWithHeaders : normalFetch)(createURL(url), { method, credentials: _credentials, body }, headers, _credentials)
    );
}

export async function get<T>(url: string, withAuthToken = true): Promise<T> {
    return request(Method.GET, url, undefined, withAuthToken);
}

export async function getFile(url: string): Promise<Blob> {
    return fetchWithHeaders(createURL(url), { method: Method.GET, credentials: "include" }).then((response) => response.blob());
}

// eslint-disable-next-line max-params
export async function post<T>(
    url: string,
    body?: string | Blob | ArrayBufferView | ArrayBuffer | FormData | URLSearchParams | ReadableStream<Uint8Array> | null,
    withAuthToken = true,
    headers?: Headers
): Promise<T> {
    return request(Method.POST, url, body, withAuthToken, headers);
}

// eslint-disable-next-line max-params
export async function postJSON<T>(url: string, body?: {}, withAuthToken = true, headers?: Headers): Promise<T> {
    return post(url, body ? JSON.stringify(body) : undefined, withAuthToken, headers);
}

// eslint-disable-next-line max-params
export async function putJSON<T>(url: string, body?: {}, withAuthToken = true, headers?: Headers): Promise<T> {
    return request(Method.PUT, url, body ? JSON.stringify(body) : undefined, withAuthToken, headers);
}

// eslint-disable-next-line max-params
export async function del<T>(url: string, withAuthToken = true, headers?: Headers): Promise<T> {
    return request(Method.DELETE, url, undefined, withAuthToken, headers);
}

// eslint-disable-next-line max-params
export async function delJSON<T>(url: string, body: {}, withAuthToken = true, headers?: Headers): Promise<T> {
    return request(Method.DELETE, url, JSON.stringify(body), withAuthToken, headers);
}
