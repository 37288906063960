import { InvoiceEndpoints, PublicInvoiceEndpoints, WorkOrdersEndpoints } from "./endpoints";
import { dataURLtoFile, wait } from "./utils";
import {
    ApprovedInvoiceInput,
    InvoiceInput,
    Invoice,
    ExpressPaymentInvoiceInput,
    ExternalInvoiceInput,
    BulkImportInvoiceResponse,
} from "../../types/Invoice";
import * as http from "./http";
import { FileUrl } from "../../types/FileUrl";
import { Company } from "../../types/Company";
import { isInvoicePaid } from "../app/invoice";
import { InvoiceDeclineReasons } from "../../constants/invoiceDeclineReasons";
import { InvoiceStatuses } from "../../constants/invoice";
import { TowbookCall } from "../../types/TowbookCall";

export type InvoiceWithCompany = Exclude<Invoice, "company"> & {
    company: Partial<Company>;
};

export function getValidatedPaymentInfo(invoiceId: string): Promise<{}> {
    return http.get(InvoiceEndpoints.getValidatedPaymentInfo(invoiceId));
}

export function getInvoice(invoiceId: string): Promise<Invoice> {
    return http.get(InvoiceEndpoints.getInvoice(invoiceId));
}

export function getVoidedInvoice(invoiceId: string): Promise<Invoice> {
    return http.get(InvoiceEndpoints.getVoidedInvoice(invoiceId));
}

export function createInvoice(companyId: string, invoice: InvoiceInput | Invoice): Promise<Invoice> {
    return http.postJSON(InvoiceEndpoints.createInvoice(companyId), prepareInvoiceInput(invoice));
}

export function createExpressPaymentInvoice(companyId: string, invoice: ExpressPaymentInvoiceInput): Promise<Invoice> {
    return http.postJSON(InvoiceEndpoints.createInvoice(companyId), invoice);
}

export function createTowbookInvoice(companyId: string, invoice: ExternalInvoiceInput): Promise<Invoice> {
    return http.postJSON(InvoiceEndpoints.createInvoice(companyId), invoice);
}

export function createTowbookInvoiceFromWorkOrder(workOrderId: string, invoice: ExternalInvoiceInput): Promise<Invoice> {
    return http.postJSON(WorkOrdersEndpoints.createWorkOrderInvoice(workOrderId), invoice);
}

export function bulkImportInvoices(companyId: string, invoices: ExternalInvoiceInput[]): Promise<BulkImportInvoiceResponse> {
    return http.postJSON(InvoiceEndpoints.bulkImportInvoices(companyId), { invoices });
}

export function updateInvoice(invoice: InvoiceInput | Invoice): Promise<Invoice> {
    return http.putJSON(InvoiceEndpoints.updateInvoice(invoice.id), prepareInvoiceInput(invoice));
}

export function updateInvoicePaidBy(invoice: InvoiceInput | Invoice, userId: string): Promise<{}> {
    return http.postJSON(InvoiceEndpoints.updateInvoicePaidBy(invoice.id), { userId });
}

export async function verifyInvoiceIsPaidOrThrowException(token: string): Promise<void> {
    const invoice = await getInvoiceByToken(token);
    if (isInvoicePaid(invoice)) {
        return;
    }
    const paymentError = invoice.paymentError ? InvoiceDeclineReasons.getByKey(invoice.paymentError)?.display : undefined;
    throw new Error(paymentError || "Something went wrong. Please try again.");
}

// eslint-disable-next-line max-params
export function getInvoicesByLocation(
    locationId: string,
    offset: number,
    limit: number,
    search?: string,
    departmentid?: string,
    invoicestatuses?: string[]
): Promise<InvoiceWithCompany[]> {
    return http.get(InvoiceEndpoints.getInvoicesByLocation(locationId, { offset, limit, search, departmentid, invoicestatuses }));
}

/**
 * @deprecated
 * limitted for certain roles. filter companyId won't work
 * * supports isCompanyAdmin(me) || isClientSupport(me) || isAccountant(me)
 */
// eslint-disable-next-line max-params
export function getInvoicesByCompany(
    companyId: string,
    offset: number,
    limit: number,
    search?: string,
    invoicestatuses?: string[]
): Promise<InvoiceWithCompany[]> {
    return http.get(
        InvoiceEndpoints.getInvoicesByCompany({
            companyId,
            offset,
            limit,
            search,
            invoicestatuses,
        })
    );
}

export function getInvoicesByCompanyId(
    companyId: string,
    offset: number,
    limit: number,
    search?: string,
    includevoided?: boolean,
    invoicestatuses?: string[]
): Promise<InvoiceWithCompany[]> {
    return http.get(
        InvoiceEndpoints.getInvoicesByCompanyId(companyId, {
            offset,
            limit,
            search,
            includevoided,
            invoicestatuses,
        })
    );
}

export function getInvoiceFromTowbook(
    companyId: string,
    towbookCallNumber: string,
    towbookAccountId: string,
    towbookCompanyId: string
): Promise<TowbookCall[]> {
    return http.get(InvoiceEndpoints.getInvoiceFromTowbook(companyId, towbookCallNumber, towbookAccountId, towbookCompanyId));
}

export function getTowbookPdfUrl(companyId: string, callNumber: string, towbookAccountId: string, towbookCompanyId: string): string {
    return InvoiceEndpoints.getPdfUrlFromTowbook(companyId, callNumber, towbookAccountId, towbookCompanyId);
}


export function getTowbookPdf(companyId: string, callNumber: string, towbookAccountId: string, towbookCompanyId: string): Promise<Blob> {
    return http.getFile(InvoiceEndpoints.getPdfUrlFromTowbook(companyId, callNumber, towbookAccountId, towbookCompanyId));
}


export function duplicateInvoice(invoiceId: string, useStoredPaymentDetails: boolean): Promise<any> {
    return http.post(InvoiceEndpoints.duplicateInvoice(invoiceId, useStoredPaymentDetails));
}

// eslint-disable-next-line max-params
export function getAllInvoices(
    offset: number,
    limit: number,
    search?: string,
    includevoided?: boolean,
    invoicestatuses?: string[]
): Promise<InvoiceWithCompany[]> {
    return http.get(InvoiceEndpoints.getAllInvoices({ offset, limit, search, includevoided, invoicestatuses }));
}

export function deleteInvoice(invoiceId: string, deletionReason: string): Promise<any> {
    return http.delJSON(InvoiceEndpoints.deleteInvoice(invoiceId), { deletionReason });
}

export function fullRefundInvoice(invoiceId: string, deletionReason: string): Promise<void> {
    return http.postJSON(InvoiceEndpoints.refundInvoice(), { invoiceId, deletionReason });
}

export function partialRefundInvoice(invoiceId: string, amount: string): Promise<void> {
    return http.postJSON(InvoiceEndpoints.refundInvoice(), { invoiceId, amount });
}

export function generateInvoiceReceipt(invoiceId: string): Promise<any> {
    return http.get(InvoiceEndpoints.generateInvoiceReceipt(invoiceId));
}

export function getInvoiceReceipt(invoiceId: string, isVoided = false): Promise<FileUrl> {
    return http.get(InvoiceEndpoints.getInvoiceReceipt(invoiceId, isVoided));
}

export function getInvoiceReceiptUrl(invoiceId: string): Promise<FileUrl> {
    return http.get(InvoiceEndpoints.getInvoiceReceipt(invoiceId));
}

// eslint-disable-next-line max-params
export function sendInvoiceReceipt(invoiceId: string, email?: string, phoneNumber?: string, token?: string): Promise<any> {
    return http.postJSON(InvoiceEndpoints.sendInvoiceReceipt(invoiceId), { email, phoneNumber, token });
}

// eslint-disable-next-line max-params,max-lines-per-function
export function sendNotificationToPayer(
    invoiceId: string,
    phoneNumber: string,
    email: string,
    payerNotificationType: string,
    checkNumber?: string,
    checkType?: string,
    invoiceGrandTotal?: string
): Promise<any> {
    return http.postJSON(InvoiceEndpoints.sendNotificationToPayer(invoiceId), {
        phoneNumber,
        email,
        checkNumber,
        checkType,
        invoiceGrandTotal,
        payerNotificationType,
    });
}

export function addSignature(invoiceId: string, image: string): Promise<any> {
    return http.post(InvoiceEndpoints.addSignature(invoiceId), dataURLtoFile(image, `signature-${invoiceId}.png`));
}

export function addEmailReminder(invoiceId: string): Promise<any> {
    return http.post(InvoiceEndpoints.addEmailReminder(invoiceId));
}

export function sendInvoiceForCompletion(invoiceId: string, email?: string, phoneNumber?: string): Promise<any> {
    return http.postJSON(InvoiceEndpoints.sendInvoiceForCompletion(invoiceId), { email, phoneNumber });
}

export function getFilesForInvoice(invoiceId: string): Promise<any> {
    return http.get(InvoiceEndpoints.getFilesForInvoice(invoiceId));
}

export function attachPdfToInvoice(invoiceId: string, file: any, fileName: string): Promise<any> {
    return http.post(InvoiceEndpoints.uploadInvoicePdfFile(invoiceId, fileName), file);
}

export function removePdfFromInvoice(invoiceId: string, invoiceFileId: string): Promise<any> {
    return http.delJSON(InvoiceEndpoints.removeInvoicePdfFile(invoiceId), { invoiceFileId });
}

export function updateApprovedInvoice(invoice: ApprovedInvoiceInput): Promise<any> {
    return http.putJSON(InvoiceEndpoints.editApprovedInvoice(invoice.id), prepareApprovedInvoiceInput(invoice));
}

function prepareApprovedInvoiceInput(invoice: ApprovedInvoiceInput): any {
    return {
        id: invoice.id,
        description: invoice.description,
        payerName: invoice.payerName,
        payerPhone: invoice.payerPhone,
        payerEmail: invoice.payerEmail,
        customFields: invoice.customFields,
        departmentId: invoice.departmentId,
        shiftId: invoice.shiftId,
        comments: invoice.comments,
    };
}

export function getV1Invoice(invoiceId: string): Promise<{}> {
    return http.get(InvoiceEndpoints.getV1InvoiceById(invoiceId));
}

export function deleteV1Invoice(invoiceId: string): Promise<{}> {
    return http.del(InvoiceEndpoints.deleteV1InvoiceById(invoiceId));
}

export function getInvoiceByToken(token: string): Promise<Invoice> {
    return http.get(PublicInvoiceEndpoints.getInvoice(token));
}

export function getInvoiceReceiptByToken(token: string): Promise<FileUrl> {
    return http.get(PublicInvoiceEndpoints.getInvoiceReceipt(token));
}

export function addPublicSignature(token: string, image: any): Promise<FileUrl> {
    return http.post(PublicInvoiceEndpoints.addSignature(token), dataURLtoFile(image, `signature-${token}.png`));
}

export function getInvoiceAttachmentByToken(token: string, fileId: string): Promise<FileUrl> {
    return http.get(PublicInvoiceEndpoints.getFileForInvoice(token, fileId));
}

// eslint-disable-next-line max-params
export function getPaidInvoicesByCompany(companyId: string, offset: number, limit: number, quickfilter: string): Promise<any> {
    return http.get(InvoiceEndpoints.getPaidInvoicesByCompany(companyId, offset, limit, quickfilter));
}

// eslint-disable-next-line max-params
export function getUnpaidInvoicesByCompany(companyId: string, offset: number, limit: number, quickfilter: string): Promise<any> {
    return http.get(InvoiceEndpoints.getOpenInvoicesByCompany(companyId, offset, limit, quickfilter));
}

// eslint-disable-next-line max-params
export function getRefundedInvoicesByCompany(companyId: string, offset: number, limit: number, quickfilter: string): Promise<any> {
    return http.get(InvoiceEndpoints.getRefundedInvoicesByCompany(companyId, offset, limit, quickfilter));
}

export function sendInvoiceReceiptViaSMSToPayer(invoiceToken: string, phoneNumber: string): Promise<unknown> {
    return http.postJSON(`/api/v1/public/invoices/${invoiceToken}/notify/payer`, { phoneNumber, payerNotificationType: "signupreminder" });
}

export function markInvoiceStatusProcessing(token: string): Promise<unknown> {
    return http.post(PublicInvoiceEndpoints.markInvoiceProcessing(token));
}

export function unmarkInvoiceStatusProcessing(token: string): Promise<unknown> {
    return http.putJSON(PublicInvoiceEndpoints.unmarkInvoiceProcessing(token));
}

export function printInvoiceReceiptPublic(token?: string): Promise<any> {
    return http.getFile(InvoiceEndpoints.printInvoiceReceiptPublic(token ?? ""));
}

export function printInvoiceReceipt(id: string): Promise<any> {
    return http.getFile(InvoiceEndpoints.printInvoiceReceipt(id));
}

/**
 * `waitUntilInvoiceDoneProcessing` keeps polling the invoice endpoint until the invoice is no longer processing or waiting approval.
 * By default, it will throw an error if the processing time is greater than 5 minutes.
 * If there is a payment error, it will throw the payment error message.
 */
export async function waitUntilInvoiceDoneProcessing(
    token: string,
    timeBetweenRefreshesInSeconds = 15,
    maxAttempts = 20,
    comdataV2 = false
): Promise<void> {
    let invoice = await getInvoiceByToken(token);
    let isProcessing = isInvoiceProcessing(invoice);
    let attempts = 1;
    while (isProcessing && attempts < maxAttempts) {
        await wait(timeBetweenRefreshesInSeconds * 1000);
        attempts++;
        invoice = await getInvoiceByToken(token);
        isProcessing = isInvoiceProcessing(invoice);
    }
    // This will happen if we reach the max attempts and it's still processing.
    if (isProcessing) {
        throw Error("Invoice is still processing. Please refresh the page.");
    }
    if (invoice.paymentError?.length) {
        throw Error(InvoiceDeclineReasons.getByKey(invoice.paymentError)?.display || `Error: ${invoice.paymentError}`);
    }
}

/**
 * Returns true when the `invoice` is processing or waiting approval.
 * @param invoice An optional, but recommended partial `Invoice`.
 * If the parameter is not provided, the function will always return false.
 * @returns `true` when the invoice is not in a processing state, `false` when the invoice is processing.
 */
export function isInvoiceProcessing(invoice?: Partial<Invoice>) {
    return invoice?.status === InvoiceStatuses.PROCESSING.key || invoice?.status === InvoiceStatuses.WAITINGAPPROVAL.key;
}

function prepareInvoiceInput(invoice: InvoiceInput | Invoice): {} {
    return {
        id: invoice.id,
        description: invoice.description,
        payerName: invoice.payerName,
        payerPhone: invoice.payerPhone,
        payerEmail: invoice.payerEmail,
        locationId: invoice.locationId,
        lineItems: invoice.lineItems,
        customFields: invoice.customFields,
        departmentId: invoice.departmentId,
        shiftId: invoice.shiftId,
        workflowStatus: invoice.workflowStatus,
        comments: invoice.comments,
        convFeeDisable: invoice.convFeeDisable,
        storePaymentDetails: invoice?.storePaymentDetails,
        deleteStoredPaymentDetails: invoice?.deleteStoredPaymentDetails,
        externalInvoiceNumber: invoice?.externalInvoiceNumber,
        towbookAccountId: invoice?.towbookAccountId,
        towbookCompanyId: invoice?.towbookCompanyId,
        paymentSettings: invoice?.paymentSettings,
        paidComment: invoice?.paidComment,
    };
}

export async function payButtonPressed(invoice?: Invoice): Promise<void> {
    if (!invoice?.token) {
        throw Error("Invoice token required");
    }
    await http.postJSON(`/api/v1/public/invoices/${invoice.token}/pay-button-pressed`);
}
