import { createSelector } from "reselect";

import { getCurrentUser } from "./userSelectors";
import { getAppSettingsLoaded } from "./appSettingsSelectors";
import { getCurrentUserCompany } from "./companySelectors";
import { Type as CompanyType } from "../constants/company";
import { GlobalState, Role } from "../types";
import { intersection } from "lodash";
import { Verification } from "../constants/verification";

export const getAuth = (state: GlobalState) => state.auth;

export const getAuthLoaded = createSelector([getCurrentUser], (currentUser) => {
    return Array.isArray(currentUser?.roles);
});

export const getIsAuthenticated = createSelector([getAuthLoaded, getAppSettingsLoaded], (authLoaded, appSettingsLoaded) => {
    return authLoaded && appSettingsLoaded;
});

export const getUserIsAuthorizedByCompanyType = (companyTypes?: CompanyType[]) =>
    createSelector([getCurrentUserCompany], (company) => {
        if (companyTypes && companyTypes.length > 0 && company?.type) {
            const companyTypeAllowed = companyTypes.find((ct) => ct.key === company.type);
            return !!companyTypeAllowed;
        }
        return true;
    });

export const userIsAuthorizedByRole = (roles: Role[]) =>
    createSelector([getCurrentUser], (currentUser) => {
        if (!currentUser || !currentUser.roles) {
            return false;
        }

        return (
            intersection(
                currentUser.roles,
                roles.map((r) => r.key)
            ).length > 0
        );
    });

export const userIsVerified = (path: string) =>
    createSelector([getCurrentUser], (currentUser) => {
        return !!(currentUser && currentUser.isVerified) || path === Verification.VERIFICATION_PATH;
    });

