import React from "react";
import { connect, DispatchProp } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { StyledComponentProps, Box } from "@material-ui/core";
import { FullScreenLoader } from "@roadsync/roadsync-ui";
import { Company, CustomField, GlobalState, Invoice, Location, OnCompletedStepProps, WorkOrder } from "../../../../types";
import Alerts from "../../../app/Alerts";
import Modals from "../../../modals/Modals";
import { mapState } from "../../../../services/api/utils";
import TowbookForm, { TowbookCheckbox, TowbookFormData } from "./TowbookForm";
import { isClientSupport, isEmployee } from "../../../../services/app/auth";
import {
    getDepartmentsByLocationId,
    getLocationsArrayFromSettings,
    getLocationsByIds,
    getShiftsByLocationId,
    PreSelectedLocationValue,
} from "../../../../services/app/location";
import { AppSettings } from "../../../../types/AppSettings";
import TowbookInvoiceEdition from "./TowbookFormContainer";
import { ProductType } from "../../../../constants/product";
import { LineItem } from "../../../../types/LineItems";
import { isEmpty } from "lodash";
import { getCompanySettings } from "../../../../actions/companySettings";
import { showErrorAlert } from "../../../../actions/alerts";
import { normalizePhoneOrEmail } from "../../../../services/app/normalizers";
import { createTowbookInvoice, createTowbookInvoiceFromWorkOrder, getTowbookPdf } from "../../../../services/api/invoices";
import { getInvoice } from "../../../../actions/invoices";
import { Contact, TowbookCall } from "../../../../types/TowbookCall";
import { PreparationSteps } from "../../../../constants/invoice";
import { InvoicePaths } from "../../../../services/app/paths";
import { InvoiceSource } from "../../../../constants/invoice";
import { FormApi } from "final-form";
import { openFileFromBlob } from "../../../../services/app/files";

interface CallState {
    towbookCall: TowbookCall;
    towbookAccountId: string;
    towbookCompanyId: string;
}

type PropsFromState = Pick<
    GlobalState,
    "auth" | "companies" | "locations" | "departments" | "shifts" | "products" | "appSettings" | "companySettings"
>;

interface Props extends StyledComponentProps, RouteComponentProps, DispatchProp, PropsFromState, OnCompletedStepProps {
    setNextStep: (step: number, invoice?: Invoice) => void;
}

interface State {
    loading?: boolean;
    isModalOpen?: boolean;
    selectedLocation?: string;
    location?: Location;
    payerCheckbox: string;
    recipentCheckbox: string;
    customFields: CustomField[];
    towbookCallData: TowbookCall;
    maxValue?: string;
    towbookNumber?: string;
    amount?: string;
    towbookAccountId?: string;
    towbookCompanyId?: string;
    selectedContact?: Contact;
    recipientEmails: string[];
    recipientPhoneNumbers: string[];
}

class ImportFromTowbookContainer extends React.Component<Props, State> {
    private mounted = false;
    defaultErrMsg = "Something went wrong. Please try again";

    constructor(props: Props) {
        super(props);
        this.handleLocationChange = this.handleLocationChange.bind(this);
        this.handleAmountChanged = this.handleAmountChanged.bind(this);
        this.handlePayerChange = this.handlePayerChange.bind(this);
        this.handleRecipentChange = this.handleRecipentChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getTowbookPdf = this.getTowbookPdf.bind(this);
        this.getWorkOrder = this.getWorkOrder.bind(this);
        this.isFromWorkOrder = this.isFromWorkOrder.bind(this);
        this.onContactChange = this.onContactChange.bind(this);
        this.state = {
            loading: true,
            isModalOpen: false,
            payerCheckbox: TowbookCheckbox.account,
            recipentCheckbox: TowbookCheckbox.email,
            customFields: [],
            recipientEmails: [],
            recipientPhoneNumbers: [],
            towbookCallData: {
                externalInvoiceNumber: "",
                account: { name: "" },
                contacts: [],
                amount: "",
            },
        };
    }

    isFromWorkOrder(): boolean {
        return !!this.getWorkOrder()?.id;
    }

    getWorkOrder(): WorkOrder | undefined {
        const {
            history: {
                location: { state },
            },
        } = this.props;
        const locationState = state as { workOrder?: WorkOrder };

        return locationState?.workOrder;
    }

    async componentDidMount(): Promise<void> {
        this.mounted = true;
        const { dispatch, history } = this.props;
        const towbookLocationData = this.getTowbookPaymentLocationData();
        const initiallySelectedLocation = towbookLocationData?.filter((l) => l.id === this.getPreselectedOptions().locationId)?.[0];
        const firstInTheListTowbookLocation = towbookLocationData?.[0];
        const location = initiallySelectedLocation ? initiallySelectedLocation : firstInTheListTowbookLocation;

        const companyId = this.getCompanyId();

        //Populates the towbook call data
        const towbookData = history.location.state as CallState;
        if (towbookData) {
            const towbookCall = towbookData.towbookCall;
            this.setState({
                towbookCallData: towbookCall,
                amount: towbookCall.amount,
                maxValue: towbookCall.amount,
                towbookNumber: towbookCall.externalInvoiceNumber,
                towbookAccountId: towbookData.towbookAccountId,
                towbookCompanyId: towbookData.towbookCompanyId,
                selectedContact: towbookCall.contacts ? towbookCall.contacts[0] : undefined,
            }, () => {
                this.handlePayerChange(TowbookCheckbox.account)
            });
        }

        if (location?.customFields?.length) {
            this.setState({ customFields: location.customFields.filter((field) => field.isRequired === true) });
        } else {
            this.setState({ customFields: [] });
        }

        if (companyId) {
            await dispatch<any>(getCompanySettings(companyId));
        }

        if (this.mounted && location) {
            this.setState({ location, selectedLocation: location?.id, loading: false });
        } else {
            this.setState({ loading: false });
        }

    }

    componentWillUnmount(): void {
        this.mounted = false;
    }

    goToNextStep(): void {
        const { onCompletedStep } = this.props;
        return onCompletedStep(PreparationSteps.PAYMENT_METHOD);
    }

    getCompanyId(): string {
        return this.props.auth.me.companyId;
    }

    getCompany(): Company | undefined {
        const {
            companies,
            auth: { me },
        } = this.props;

        return companies?.data?.[me.companyId];
    }

    getLocationData(): Location[] {
        const {
            appSettings: { settings },
            auth: { me },
            locations,
        } = this.props;
        return isEmployee(me) && isClientSupport(me)
            ? getLocationsByIds(locations, me.companyId, me.locationIds)
            : getLocationsArrayFromSettings(settings as AppSettings, locations, me.companyId);
    }

    getTowbookPaymentLocationData(): Location[] {
        return this.getLocationData();
    }

    async getTowbookPdf() {
        const { towbookNumber, towbookAccountId, towbookCompanyId } = this.state;
        if (!towbookNumber || !towbookAccountId || !towbookCompanyId) {
            return;
        }
        const towbookDataPdfUrl = await getTowbookPdf(this.getCompanyId(), towbookNumber, towbookAccountId, towbookCompanyId);

        openFileFromBlob(towbookDataPdfUrl);
    }

    getPreselectedOptions(): PreSelectedLocationValue {
        const {
            appSettings: { defaults },
            departments,
            shifts,
        } = this.props;
        const initialLocation = this.getWorkOrder()?.locationId || this.getWorkOrder()?.location?.id || defaults?.locationId;
        const towbookLocations = this.getTowbookPaymentLocationData();
        const location = towbookLocations.filter((l) => l.id === initialLocation)[0];

        return {
            locationId: location?.id,
            departmentId: location && getDepartmentsByLocationId(location, departments)?.[0]?.id,
            shiftId: location && getShiftsByLocationId(location, shifts)?.[0]?.id,
        };
    }

    async handleLocationChange(selectedLocation): Promise<void> {
        const towbookLocations = this.getTowbookPaymentLocationData();
        const location: Location = towbookLocations.filter((l) => l.id === selectedLocation)[0];

        if (location?.customFields?.length) {
            this.setState({ customFields: location?.customFields.filter((field) => field.isRequired === true) });
        } else {
            this.setState({ customFields: [] });
        }

        this.setState({ location, selectedLocation });
    }

    handleAmountChanged(amount: string): void {
        this.setState({ amount: amount });
    }

    getLocationId(): string | undefined {
        const { selectedLocation } = this.state;

        if (selectedLocation) {
            return selectedLocation;
        }

        return this.getPreselectedOptions()?.locationId;
    }

    getDepartmentId(): string | undefined {
        const { location } = this.state;

        if (!isEmpty(location?.departments) && typeof location?.departments?.[0] === "string") {
            return location?.departments?.[0];
        }

        return this.getPreselectedOptions()?.departmentId;
    }

    getShiftId(): string | undefined {
        const { location } = this.state;

        if (!isEmpty(location?.shifts) && typeof location?.shifts?.[0] === "string") {
            return location?.shifts?.[0];
        }

        return this.getPreselectedOptions()?.shiftId;
    }

    getTowbookCustomLineItems(values: TowbookFormData): LineItem[] {
        const productId = this.getCustomLineItemsProductId() as string;
        const amount = typeof values.amount === "number" ? values.amount : values.amount ? Number(values.amount) : 0;

        const base = {
            productId,
            description: "Towbook",
            qty: amount,
            cost: amount,
            type: ProductType.ADD_MULTIPLE_LINE_ITEMS.key,
            isTaxable: true,
        };

        const lineItems: LineItem[] = [
            {
                ...base,
                id: "",
                product: { ...base, id: productId },
            },
        ];

        return lineItems;
    }

    getCustomLineItemsProductId(): string | undefined {
        const { products } = this.props;
        return Object.keys(products?.data || [])
            .filter((id) => products.data[id].type === ProductType.ADD_MULTIPLE_LINE_ITEMS.key)
            .shift();
    }

    getInvoiceData(values: TowbookFormData) {
        const { auth: { me } } = this.props;
        const { payerCheckbox, recipentCheckbox, selectedLocation, towbookNumber, towbookAccountId, towbookCompanyId } = this.state;
        const towbookLocations = this.getTowbookPaymentLocationData();
        const location: Location = towbookLocations.filter((l) => l.id === selectedLocation)[0];

        const phone = normalizePhoneOrEmail(values.phone);
        const email = normalizePhoneOrEmail(values.email);
        const payerEmail = recipentCheckbox === TowbookCheckbox.email ? email : undefined;
        const payerPhone = recipentCheckbox === TowbookCheckbox.phone ? phone : undefined;

        let customFieldValues = {};

        if (location?.customFields?.length) {
            Object.keys(location.customFields).forEach((item) => {
                const field = location.customFields?.[item];
                customFieldValues[field.name] = values[field.name];
            });
        }

        return {
            companyId: me.companyId,
            locationId: this.getLocationId(),
            departmentId: this.getDepartmentId(),
            shiftId: this.getShiftId(),
            payerName: payerCheckbox === TowbookCheckbox.account ? values.accountName : values.contactName,
            payerPhone,
            payerEmail,
            lineItems: [...this.getTowbookCustomLineItems(values)],
            source: InvoiceSource.TOWBOOK,
            customFields: location?.customFields?.length ? customFieldValues : null,
            externalInvoiceNumber: towbookNumber,
            autoAddAttachments: values.autoAddAttachments,
            towbookAccountId: towbookAccountId,
            towbookCompanyId: towbookCompanyId,
        };
    }

    async handleSubmit(values: TowbookFormData): Promise<void> {
        const { dispatch, history } = this.props;
        this.setState({ loading: true });

        const invoiceToSave = this.getInvoiceData(values);
        try {
            const invoice = this.isFromWorkOrder()
                ? await createTowbookInvoiceFromWorkOrder(this.getWorkOrder()!.id, invoiceToSave)
                : await createTowbookInvoice(this.getCompanyId(), invoiceToSave);

            if (invoice) {
                await dispatch<any>(getInvoice(invoice.id));
                history.replace(InvoicePaths.editUrl(invoice.id));
                this.goToNextStep();
            }
        } catch (e) {
            dispatch(showErrorAlert((e as any)?.message ?? this.defaultErrMsg));
        } finally {
            this.setState({ loading: false });
        }
    }

    isLoading(): boolean {
        const company = this.getCompany();
        const { loading } = this.state;
        return !!loading || !company;
    }

    handlePayerChange(value: string, form?: FormApi<TowbookFormData, Partial<TowbookFormData>>) {
        let { towbookCallData, selectedContact } = this.state;

        this.setState({ payerCheckbox: value });

        // If account is selected, set available phone numbers
        // and email addresses to be that of the account.
        if (value === TowbookCheckbox.account) {
            return this.setState({
                recipientEmails: towbookCallData?.account.emails ?? [],
                recipientPhoneNumbers: towbookCallData?.account.phoneNumbers ?? [],
            }, () => {
                form?.change('email', towbookCallData?.account.emails?.[0] || '');
                form?.change('phone', towbookCallData?.account.phoneNumbers?.[0] || '');
            });
        }

        //if contact is selected, set the first email and phone number
        // from the selected contact
        this.setState({
            selectedContact,
            recipientEmails: (selectedContact?.emails ?? []).slice(0, 1),
            recipientPhoneNumbers: (selectedContact?.phoneNumbers ?? []).slice(0, 1),
        }, () => {
            form?.change('email', selectedContact?.emails?.[0] || '');
            form?.change('phone', selectedContact?.phoneNumbers?.[0] || '');
        });
    }

    handleRecipentChange(value: string) {
        this.setState({ recipentCheckbox: value });
    }

    onContactChange(contact?: Contact | string | null, form?: FormApi<TowbookFormData, Partial<TowbookFormData>>): void {
        const { towbookCallData } = this.state;
        var selectedContact: Contact | undefined;

        if ("string" === typeof contact) {
            selectedContact = towbookCallData.contacts?.filter((c) => c.name === contact)[0];
        } else if (!!contact) {
            selectedContact = contact;
        }

        this.setState(
            { selectedContact },
            () => this.handlePayerChange(TowbookCheckbox.contact, form)
        );
    }

    getInitialValues(): Partial<TowbookFormData> {
        const { payerCheckbox, towbookCallData, selectedContact } = this.state;
        const isUsingAccount = payerCheckbox === TowbookCheckbox.account;
        return {
            contactName: selectedContact?.name,
            phone: isUsingAccount
                ? towbookCallData.account.phoneNumbers?.[0]
                : selectedContact?.phoneNumbers?.[0],
            email: isUsingAccount
                ? towbookCallData.account.emails?.[0]
                : selectedContact?.emails?.[0],
        };
    }

    render(): React.ReactElement {
        const {
            loading, selectedLocation, payerCheckbox, recipentCheckbox,
            customFields, towbookCallData, maxValue, towbookNumber, amount,
            recipientEmails, recipientPhoneNumbers,
        } = this.state;
        const towbookPaymentLocations = this.getTowbookPaymentLocationData();

        return (
            <Box>
                <FullScreenLoader show={this.isLoading()} />
                <Alerts />
                <TowbookInvoiceEdition>
                    <TowbookForm
                        loading={loading}
                        towbookCallNumber={towbookNumber}
                        towbookPaymentLocations={towbookPaymentLocations}
                        selectedLocation={selectedLocation}
                        towbookCallData={towbookCallData}
                        maxValue={maxValue}
                        amount={amount}
                        handleLocationChange={this.handleLocationChange}
                        handleAmountChanged={this.handleAmountChanged}
                        handleSubmit={this.handleSubmit}
                        handlePdfView={this.getTowbookPdf}
                        handlePayerChange={this.handlePayerChange}
                        handleRecipientChange={this.handleRecipentChange}
                        payerCheckbox={payerCheckbox}
                        recipientCheckbox={recipentCheckbox}
                        onContactChange={this.onContactChange}
                        recipientEmails={recipientEmails}
                        recipientPhoneNumbers={recipientPhoneNumbers}
                        customFields={customFields}
                        isLocationReadOnly={this.isFromWorkOrder()}
                        initialValues={this.getInitialValues()}
                    />
                </TowbookInvoiceEdition>
                <Modals />
            </Box>
        );
    }
}

const mapStateToProps = (state: GlobalState): PropsFromState =>
    mapState<PropsFromState>(state, "auth", "companies", "locations", "departments", "shifts", "products", "appSettings", "companySettings");

export default withRouter(connect(mapStateToProps)(ImportFromTowbookContainer));
