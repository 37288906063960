import { v4 as uuidv4 } from "uuid";
export class AuthPaths {
    static loginUrl = (redirect?: string): string => `/login${redirect ? "?redirect=" + redirect : ""}`;
    static forgotPasswordUrl = (redirect?: string): string => `/forgot${redirect ? "?redirect=" + redirect : ""}`;
    static resetPasswordUrl = (code: string): string => `/reset/${code}`;
    static changePasswordUrl = (): string => `/change`;
    static selectLocationUrl = (redirect: string): string => `/select-location${redirect}`;
    static selectDepartmentShiftUrl = (): string => "/select-department-shift";
    static twoFactorAuth = (): string => "/tfa";
    static enableTwoFactorAuth = (): string => "/tfa/new";
    static disableTwoFactorAuth = (): string => "/tfa/reset";
    static payerSignupUrl = (): string => `/signup/payer`;
    static payerLoginUrl = (): string => `/signin/payer`;
    static verifyUserUrl = (userId: string): string => `/verification/${userId}`;
    static rsEmployeeMobileRedirect = (): string => `/mobile/redirect`;
    static marketingSignupURL = (): string => "signup/marketing";
    static ssoCallbackUrl = (): string => "/sso-callback";
    static ssoLink = (logout?: boolean, state?: string): string => {
        const haul_pass_host = process.env.REACT_APP_HAUL_PASS_HOST || "";
        const client_id = process.env.REACT_APP_HAUL_PASS_CLIENT_ID as string;
        const redirect_uri = `${window.location.origin}${this.ssoCallbackUrl()}`;
        const scope = "openid profile email roadsync checkout";
        const nonce = uuidv4();
        const url = new URL(`${haul_pass_host}/login`);
        url.searchParams.append("response_type", "code");
        url.searchParams.append("client_id", client_id);
        url.searchParams.append("redirect_uri", redirect_uri);
        url.searchParams.append("scope", scope);
        url.searchParams.append("state", state || "");
        url.searchParams.append("nonce", nonce);
        if (logout) {
            url.searchParams.append("prompt", "login");
        }
        return url.toString();
    };
}

