import React from "react";
import ModalContent from "../../components/modals/ModalContent";
import useStyles from "./SSOModals.css";
import { Typography } from "@material-ui/core";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import { LargePrimaryButton } from "../../components/ui/Buttons";
import { getEnvUrlPrefix } from "../../services/api/utils";

const ReportRedirectModal: React.FC = (): JSX.Element => {
    const classes = useStyles();

    const reporterRedirectUrl = (): string => {
        const env = getEnvUrlPrefix();
        return `https://${env}fullbay.fleetpayments.com/reporting/`;
    };

    const doReportingRedirect = (): void => {
        window.location.href = reporterRedirectUrl();
    };

    return (
        <ModalContent className={classes.root}>
            <div className={classes.textContainer}>
                <ReportProblemOutlinedIcon classes={{ root: classes.icon }} />
                <Typography variant="h4" gutterBottom>
                    Reporting Access
                </Typography>
                <Typography className={classes.paragraph}>
                    Your account has access to Reporting features. You will be redirected to the Reporting Service.
                </Typography>
                <LargePrimaryButton className="btn-yellow" onClick={doReportingRedirect}>
                    Go to Reporting
                </LargePrimaryButton>
            </div>
        </ModalContent>
    );
};

export default ReportRedirectModal;

