import React from "react";
import { connect } from "react-redux";
import { getCompany } from "./actions/companies";
import { User } from "./types/User";
import { Company } from "./types/Company";
import { Location } from "./types/Location";
import { isSuperAdmin, isRSEmployee } from "./services/app/auth";
import { AuthState } from "./reducers/auth";
import { CompaniesState } from "./reducers/companies";
import { isEqual } from "lodash";
import { CompanyTypes } from "./constants/company";
import { isPayoutsEnabled, isAchPaymentEnabled } from "./services/app/company";

interface Props {
    dispatch: Function;
    user?: User;
    settingsLoading?: boolean;
    locations?: { data: { [k: string]: Location & { loading: boolean } } };
    locationIds?: string[];
    auth: AuthState;
    companies: CompaniesState;
    impersonation?: User;
}

interface State {
    user?: User;
}

class Pendo extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { user: undefined };
    }

    public render(): React.ReactElement {
        return <></>;
    }

    public async componentDidMount(): Promise<void> {
        const companyId = this.getCompanyId();

        if (companyId && !this.getCompany()) {
            this.props.dispatch(getCompany(companyId));
        } else {
            this.onChange();
        }
    }

    shouldComponentUpdate(nextProps, nextState): boolean {
        return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState);
    }

    private isRoadSyncUser(): boolean {
        const { user } = this.props;
        return isRSEmployee(user) || isSuperAdmin(user);
    }

    public async componentDidUpdate(): Promise<void> {
        await this.onChange();
    }

    async onChange(): Promise<void> {
        const { auth } = this.props;
        const isUserLoggedIn = Boolean(auth?.me?.id);
        const company = this.getCompany();
        const currentUser = auth.me;
        const isPaying =
            company?.type === CompanyTypes.TOWANDREPARE.key ||
            company?.type === CompanyTypes.WAREHOUSE.key ||
            company?.type === CompanyTypes.LUMPER.key;
        const isInstantPayoutEnabled = isPayoutsEnabled(company);
        const isAchPaymentMethodEnabled = isAchPaymentEnabled(company);

        if (!isUserLoggedIn || !company) {
            return;
        }

        if (!window["pendo"]) {
            await _initPendo();
        }

        window["pendo"].initialize({
            visitor: {
                id: this.getUserIdPrefix() + currentUser.id,
                email: currentUser.email,
                full_name: `${currentUser.first ?? ""} ${currentUser.last ?? ""}`.trim(),
                role: currentUser.roles.join(","),
                instant_payout_enabled: isInstantPayoutEnabled,
                ach_payment_method_enabled: isAchPaymentMethodEnabled,
            },
            account: {
                id: company?.publicId,
                hash: company?.id,
                name: company?.name,
                isPaying: isPaying ? "Y" : "N",
                creationDate: company?.createdAt,
                company_type: company?.type,
                instant_payout_enabled: isInstantPayoutEnabled,
                ach_payment_method_enabled: isAchPaymentMethodEnabled,
            },
        });
    }

    getUserIdPrefix(): string {
        const { impersonation } = this.props;
        return impersonation ? "imp_" : "";
    }

    getUser(): User | undefined {
        return this.isRoadSyncUser() ? undefined : this.props.user;
    }

    getCompanyId(): string | undefined {
        return this.getUser()?.companyId;
    }

    getCompany(): Company | undefined {
        const companyId = this.getCompanyId();
        if (!companyId) {
            return undefined;
        }
        if (this.props.companies?.data === undefined) {
            return undefined;
        }

        return this.props.companies.data[companyId];
    }
}

// _initPendo is a snippet directly from Pendo to install using the RoadSync API key.
// We trigger it here instead of adding it to index.html to limit sessions to logged in users only.
function _initPendo(): Promise<void> {
    return new Promise((resolve, reject) => {
        if (!!window["pendo"]) {
            resolve();
        }

        (function (apiKey) {
            (function (p, e, n, d, o) {
                // @ts-ignore
                var v, w, x, y, z;
                o = p[d] = p[d] || {};
                o._q = o._q || [];
                v = ["initialize", "identify", "updateOptions", "pageLoad", "track"];
                for (w = 0, x = v.length; w < x; ++w)
                    (function (m) {
                        // @ts-ignore
                        o[m] =
                            o[m] ||
                            function () {
                                o._q[m === v[0] ? "unshift" : "push"]([m].concat([].slice.call(arguments, 0)));
                            };
                    })(v[w]);
                y = e.createElement(n);
                y.async = !0;
                y.src = "https://cdn.pendo.io/agent/static/" + apiKey + "/pendo.js";
                y.onload = () => resolve();
                y.onerror = (e) => reject(e);
                z = e.getElementsByTagName(n)[0];
                z.parentNode.insertBefore(y, z);
            })(window, document, "script", "pendo");
        })("4202a8ad-1108-4ee6-648a-acbe244ee1ee");
    });
}

const mapStateToProps = ({ appSettings, auth, companies, dispatch, locations }): Props => ({
    impersonation: appSettings?.settings?.impersonation,
    companies,
    dispatch,
    locations,
    user: auth?.me,
    settingsLoading: appSettings?.loading,
    locationIds: appSettings?.settings?.accessibleLocations,
    auth,
});

export default connect(mapStateToProps)(Pendo);

