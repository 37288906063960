import { createSelector } from "reselect";
import { ExternalInvoiceInput, GlobalState, QuickbooksImport } from "../types";
import { getCustomLineItemsProductId } from "./productSelectors";
import { getActiveCompanyLocationsList, getCompanyLocations } from "./locationsSelectors";
import { getCurrentUser } from "./userSelectors";
import { ProductType } from "../constants/product";
import { LineItem } from "../types/LineItems";
import { InvoiceSource } from "../constants/invoice";
export const QB_INVOICE_PAGE_SIZE = 20;

const getQuickBooksState = (state: GlobalState) => state.quickbooks.data;
const getSearchQuery = (state: GlobalState) => state.quickbooks.ui.searchQuery;
const getPageNumber = (state: GlobalState) => state.quickbooks.ui.page;

export const getQuickbooksInvoicesLoading = (state: GlobalState) => state.quickbooks.ui.loading;

export const getQuickbooksInvoices = createSelector([getQuickBooksState, getActiveCompanyLocationsList], (quickbooks, activeLocations) => {
    const defaultLocation = activeLocations[0]?.id || "";
    const invoicesArray = Object.values(quickbooks).map((invoice) => {
        const email = invoice.email || invoice.customer_emails[0];
        return {
            ...invoice,
            email: email,
            location: invoice.location || defaultLocation,
            valid: typeof invoice.valid === "boolean" ? invoice.valid : !!email,
        };
    }) as QuickbooksImport[];
    return invoicesArray;
});

export const getSortedQuickbooksInvoices = createSelector([getQuickbooksInvoices], (invoicesArray) => {
    return invoicesArray.sort((a, b) => b.invoice_number.localeCompare(a.invoice_number));
});

export const getFilteredQuickbooksInvoices = createSelector([getSortedQuickbooksInvoices, getSearchQuery], (invoicesArray, searchQuery) => {
    const filteredInvoices = !searchQuery
        ? invoicesArray
        : invoicesArray.filter((invoice) =>
              Object.values(invoice).some((value) => value.toString().toLowerCase().includes(searchQuery.toLowerCase()))
          );
    return filteredInvoices;
});

export const getCurrentQuickbooksInvoicePage = createSelector([getFilteredQuickbooksInvoices, getPageNumber], (invoices, page) => {
    const currentPage = page - 1; // Adjusting to zero-indexed for slicing
    const startIndex = currentPage * QB_INVOICE_PAGE_SIZE;
    const endIndex = startIndex + QB_INVOICE_PAGE_SIZE;
    return invoices.slice(startIndex, endIndex);
});

export const getIsAllQuickbooksInvoicesPageSelected = createSelector([getCurrentQuickbooksInvoicePage], (invoices) => {
    return invoices.every((invoice) => invoice.selected);
});

export const getQuickBooksFilters = createSelector([getFilteredQuickbooksInvoices, getSearchQuery, getPageNumber], (invoices, query, page) => {
    const startIndex = page * QB_INVOICE_PAGE_SIZE;
    return {
        currentPage: page,
        searchQuery: query,
        totalInvoices: invoices.length,
        itemCount: startIndex,
        pageSize: QB_INVOICE_PAGE_SIZE,
    };
});

export const getAllSelectedQuickBooksInvoices = createSelector([getQuickbooksInvoices], (invoices) => {
    return invoices.filter((invoice) => invoice.selected);
});

export const getAllSelectedQuickBooksInvoicesValid = createSelector([getAllSelectedQuickBooksInvoices], (invoices) => {
    return invoices.length > 0 && invoices.every((invoice) => invoice.valid);
});

export const getAllSelectedQuickbooksInvoicePayload = createSelector(
    [getAllSelectedQuickBooksInvoices, getCustomLineItemsProductId, getCurrentUser, getCompanyLocations],
    (invoices, customProductId, currentUser, locations) => {
        const invoicesPayload: ExternalInvoiceInput[] = invoices.map((invoice) => {
            const base = {
                productId: customProductId as string,
                description: "Quickbooks",
                qty: invoice.total_amount,
                cost: invoice.total_amount,
                type: ProductType.ADD_MULTIPLE_LINE_ITEMS.key,
                isTaxable: true,
            };

            const lineItems: LineItem[] = [
                {
                    ...base,
                    id: "",
                    product: { ...base, id: customProductId as string },
                },
            ];

            const location = (locations || {})[invoice.location as string];

            let shiftId: string | undefined = undefined;
            let departmentId: string | undefined = undefined;
            if (location) {
                if (location.shifts?.length) {
                    shiftId = location.shifts[0];
                }
                if (location.departments?.length) {
                    departmentId = typeof location.departments[0] === "string" ? location.departments[0] : location.departments[0].id;
                }
            }

            return {
                companyId: currentUser?.companyId as string,
                locationId: invoice.location as string,
                payerName: invoice.customer_name,
                payerEmail: invoice.email?.trim() || invoice.customer_emails[0]?.trim(),
                lineItems,
                shiftId,
                departmentId,
                externalInvoiceNumber: invoice.invoice_number,
                source: InvoiceSource.QUICKBOOKS,
                paymentSettings: { externalInvoiceId: invoice.external_invoice_id },
            } as ExternalInvoiceInput;
        });
        return invoicesPayload;
    }
);

