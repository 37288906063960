import { createSelector } from "reselect";
import { GlobalState } from "../types";
import { ProductType } from "../constants/product";

export const getProductsState = (state: GlobalState) => state.products.data;

export const getProductsList = createSelector([getProductsState], (products) => {
    const productsList = Object.values(products || {});
    return productsList;
});

export const getCustomLineItemsProductId = createSelector([getProductsList], (productsList) => {
    const productId = productsList.find((product) => product.type === ProductType.ADD_MULTIPLE_LINE_ITEMS.key)?.id;
    return productId;
});

