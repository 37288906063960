import { CheckTypes } from "./deposit";
import _ from "lodash";

/*
key: the exact error string that will match the invoice paymentError field from checkout-api
display: this will be displayed to the user in various contexts, i.e. on the payment page or on the
  decline reasons modal.
checkProviders: used on the RejectionReasons page to allow CS to choose a reason for manual
  failures, this is only needed for check type payments.
*/
interface RejectionType {
    key: string;
    display: string;
    checkProviders: string[];
    helpText?: string;
}

export class InvoiceDeclineReasons {
    static readonly DefaultCheckProvidersForCard = [
        CheckTypes.COMCHEK.key,
        CheckTypes.EFS.key,
        CheckTypes.FLEET_ONE.key,
        CheckTypes.INSTAMONEY.key,
        CheckTypes.TCHECK.key,
        CheckTypes.USBANK.key,
    ];

    static readonly DefaultCheckProvidersForCardWithoutEFSAndTCheck = [
        CheckTypes.COMCHEK.key,
        CheckTypes.FLEET_ONE.key,
        CheckTypes.INSTAMONEY.key,
        CheckTypes.USBANK.key,
    ];

    static readonly DefaultCheckProvidersForCardWithoutEFSAndTCheckAndComchek = [
        CheckTypes.FLEET_ONE.key,
        CheckTypes.INSTAMONEY.key,
        CheckTypes.USBANK.key,
    ];

    static readonly DefaultCheckProvidersForFuelCard = [CheckTypes.EFS.key, CheckTypes.TCHECK.key];

    static readonly TRY_AGAIN: RejectionType = {
        key: "tryagain",
        display: "Unable to process payment. Please try again.",
        checkProviders: [],
    };
    static readonly FUNDS: RejectionType = {
        key: "funds",
        display: "Insufficient funds. Please contact account holder or use another payment method.",
        checkProviders: [
            CheckTypes.COMCHEK.key,
            CheckTypes.EFS.key,
            CheckTypes.FLEET_ONE.key,
            CheckTypes.INSTAMONEY.key,
            CheckTypes.TCHECK.key,
            CheckTypes.USBANK.key,
            CheckTypes.COMCHEKV2.key,
        ],
    };
    static readonly EXPRESS_CODE: RejectionType = {
        key: "expresscode",
        display: "Invalid, inactive, or cancelled expresscode.",
        checkProviders: [
            CheckTypes.COMCHEK.key,
            CheckTypes.EFS.key,
            CheckTypes.FLEET_ONE.key,
            CheckTypes.INSTAMONEY.key,
            CheckTypes.TCHECK.key,
            CheckTypes.USBANK.key,
            CheckTypes.COMCHEKV2.key,
        ],
    };
    static readonly INVALID_DATA: RejectionType = {
        key: "invaliddata",
        display: "Invalid Data",
        checkProviders: [
            CheckTypes.COMCHEK.key,
            CheckTypes.EFS.key,
            CheckTypes.FLEET_ONE.key,
            CheckTypes.INSTAMONEY.key,
            CheckTypes.TCHECK.key,
            CheckTypes.USBANK.key,
        ],
    };
    static readonly NUMBER_NOT_AUTHED: RejectionType = {
        key: "numbernotauthed",
        display: "Fleet card is not authorized or not linked to the check number",
        checkProviders: [CheckTypes.FLEET_ONE.key],
    };
    static readonly UNABLE_TO_PROCESS: RejectionType = {
        key: "unabletoprocess",
        display: "Unable to process payment at this time. Please use another payment method",
        checkProviders: [
            CheckTypes.COMCHEK.key,
            CheckTypes.COMCHEKV2.key,
            CheckTypes.EFS.key,
            CheckTypes.FLEET_ONE.key,
            CheckTypes.INSTAMONEY.key,
            CheckTypes.TCHECK.key,
            CheckTypes.USBANK.key,
        ],
    };
    static readonly INVALID_TRIP_NUMBER: RejectionType = {
        key: "invalidtripnumber",
        display: "Trip number does not match expected value. Please correct or contact account holder.",
        checkProviders: [
            CheckTypes.COMCHEK.key,
            CheckTypes.COMCHEKV2.key,
            CheckTypes.FLEET_ONE.key,
            CheckTypes.INSTAMONEY.key,
            CheckTypes.USBANK.key,
        ],
    };
    static readonly INVALID_DRIVER_NUMBER: RejectionType = {
        key: "invaliddrivernumber",
        display: "Driver number does not match expected value. Please correct or contact account holder.",
        checkProviders: [
            CheckTypes.COMCHEK.key,
            CheckTypes.COMCHEKV2.key,
            CheckTypes.FLEET_ONE.key,
            CheckTypes.INSTAMONEY.key,
            CheckTypes.USBANK.key,
        ],
    };
    static readonly INVALID_TRIP_UNIT_DRIVER: RejectionType = {
        key: "invalidtripunitdriver",
        display: "Trip, Unit or Driver Numbers Are Missing or Invalid",
        checkProviders: [CheckTypes.COMCHEK.key, CheckTypes.FLEET_ONE.key, CheckTypes.INSTAMONEY.key, CheckTypes.USBANK.key],
    };
    static readonly INCORRECT_AMOUNT: RejectionType = {
        key: "incorrectamount",
        display: "Must be exact amount",
        checkProviders: [
            CheckTypes.COMCHEK.key,
            CheckTypes.EFS.key,
            CheckTypes.FLEET_ONE.key,
            CheckTypes.INSTAMONEY.key,
            CheckTypes.TCHECK.key,
            CheckTypes.USBANK.key,
        ],
    };
    static readonly INVALID_UNIT_NUMBER: RejectionType = {
        key: "invalidunitnumber",
        display: "Unit number does not match expected value. Please correct or contact account holder.",
        checkProviders: [
            CheckTypes.COMCHEK.key,
            CheckTypes.COMCHEKV2.key,
            CheckTypes.FLEET_ONE.key,
            CheckTypes.INSTAMONEY.key,
            CheckTypes.USBANK.key,
        ],
    };
    static readonly INVALID_LOCATION: RejectionType = {
        key: "invalidlocation",
        display: "Invalid Location",
        checkProviders: [CheckTypes.EFS.key, CheckTypes.FLEET_ONE.key, CheckTypes.INSTAMONEY.key, CheckTypes.TCHECK.key, CheckTypes.USBANK.key],
    };
    static readonly INVALID_CHECK_NUMBER: RejectionType = {
        key: "invalidchecknumber",
        display: "Invalid Check Number",
        checkProviders: [
            CheckTypes.COMCHEK.key,
            CheckTypes.EFS.key,
            CheckTypes.FLEET_ONE.key,
            CheckTypes.INSTAMONEY.key,
            CheckTypes.TCHECK.key,
            CheckTypes.USBANK.key,
        ],
    };
    static readonly INVALID_PAYCODE: RejectionType = {
        key: "invalidpaycode",
        display: "Invalid Paycode",
        checkProviders: [CheckTypes.EFS.key, CheckTypes.FLEET_ONE.key, CheckTypes.INSTAMONEY.key, CheckTypes.TCHECK.key, CheckTypes.USBANK.key],
    };
    static readonly SHIPPER_DECLINED: RejectionType = {
        key: "shipperdeclined",
        display: "Shipper Declined",
        checkProviders: [
            CheckTypes.COMCHEK.key,
            CheckTypes.EFS.key,
            CheckTypes.FLEET_ONE.key,
            CheckTypes.INSTAMONEY.key,
            CheckTypes.TCHECK.key,
            CheckTypes.USBANK.key,
        ],
    };
    static readonly CARD_INVALID_NUMBER: RejectionType = {
        key: "invalidcardnumber",
        display: "This is not a valid fuel card number. Please correct or contact account holder.",
        checkProviders: [
            CheckTypes.COMCHEK.key,
            CheckTypes.EFS.key,
            CheckTypes.FLEET_ONE.key,
            CheckTypes.INSTAMONEY.key,
            CheckTypes.TCHECK.key,
            CheckTypes.USBANK.key,
        ],
    };
    static readonly CARD_INVALID_EXPIRY: RejectionType = {
        key: "invalidcardexpiry",
        display: "Invalid Card Expiration Date",
        checkProviders: [CheckTypes.COMCHEK.key, CheckTypes.FLEET_ONE.key, CheckTypes.INSTAMONEY.key, CheckTypes.TCHECK.key, CheckTypes.USBANK.key],
    };
    static readonly CARD_INVALID_CVC: RejectionType = {
        key: "invalidcardcvc",
        display: "Invalid Card CVV/CVC",
        checkProviders: [CheckTypes.FLEET_ONE.key, CheckTypes.INSTAMONEY.key, CheckTypes.TCHECK.key, CheckTypes.USBANK.key],
    };
    static readonly CARD_INVALID_ZIP: RejectionType = {
        key: "invalidcardzip",
        display: "Invalid Card Zip Code",
        checkProviders: [CheckTypes.COMCHEK.key, CheckTypes.FLEET_ONE.key, CheckTypes.INSTAMONEY.key, CheckTypes.TCHECK.key, CheckTypes.USBANK.key],
    };
    static readonly CARD_DECLINED: RejectionType = {
        key: "carddeclined",
        display: "Card Was Declined",
        checkProviders: [CheckTypes.COMCHEK.key, CheckTypes.FLEET_ONE.key, CheckTypes.INSTAMONEY.key, CheckTypes.TCHECK.key, CheckTypes.USBANK.key],
    };
    static readonly REGISTER_CHECK: RejectionType = {
        key: "registercheck",
        display: "Funds not registered to RoadSync check",
        checkProviders: [
            CheckTypes.COMCHEK.key,
            CheckTypes.EFS.key,
            CheckTypes.FLEET_ONE.key,
            CheckTypes.INSTAMONEY.key,
            CheckTypes.TCHECK.key,
            CheckTypes.USBANK.key,
        ],
    };
    static readonly COMDATA_DOWN: RejectionType = {
        key: "comdatadown",
        display: "Unable to process payment. Please try again.",
        checkProviders: [CheckTypes.COMCHEK.key],
    };

    static readonly COMCHEK_CREDIT_DEPARTMENT: RejectionType = {
        key: "comchekcreditcreditdepartment",
        display: "Driver / Carrier needs to contact the comchek credit department at 1-855-737-6849.",
        checkProviders: [CheckTypes.COMCHEK.key],
    };

    static readonly COMCHEK_DOWN: RejectionType = {
        key: "comchekdown",
        display: "Comchek is down.",
        checkProviders: [CheckTypes.COMCHEK.key],
    };

    static readonly EFS_DOWN: RejectionType = {
        key: "efsdown",
        display: "EFS Down",
        checkProviders: [CheckTypes.EFS.key],
    };
    static readonly TCHECK_DOWN: RejectionType = {
        key: "tcheckdown",
        display: "Tcheck Down",
        checkProviders: [CheckTypes.TCHECK.key],
    };
    static readonly FLEETONE_DOWN: RejectionType = {
        key: "fleetonedown",
        display: "Fleet One Down",
        checkProviders: [CheckTypes.FLEET_ONE.key],
    };
    static readonly INSTAMONEY_DOWN: RejectionType = {
        key: "instamoneydown",
        display: "Instamoney Down",
        checkProviders: [CheckTypes.INSTAMONEY.key],
    };
    static readonly USBANK_DOWN: RejectionType = {
        key: "usbankdown",
        display: "US Bank Down",
        checkProviders: [CheckTypes.USBANK.key],
    };
    static readonly SYSTEMS_DOWN: RejectionType = {
        key: "systemsdown",
        display: "Systems are down at this time, please try to call in check to authorize",
        checkProviders: [
            CheckTypes.COMCHEK.key,
            CheckTypes.EFS.key,
            CheckTypes.FLEET_ONE.key,
            CheckTypes.INSTAMONEY.key,
            CheckTypes.TCHECK.key,
            CheckTypes.USBANK.key,
        ],
    };
    static readonly TCHECK_NEEDS_EXPERATION: RejectionType = {
        key: "tcheckneedsexpiration",
        display: "Tchek needs the expiration for the Tchek card",
        checkProviders: [CheckTypes.TCHECK.key],
    };
    static readonly FLEET_ONE_REGISTER_FUNDS: RejectionType = {
        key: "fleetoneregisterfunds",
        display: "Fleetone: register funds to check number",
        checkProviders: [CheckTypes.FLEET_ONE.key],
    };
    static readonly ACH_TRANSFER_FAILURE: RejectionType = {
        key: "achfailed",
        display: "Bank Account Transfer Failure",
        checkProviders: [CheckTypes.COMCHEK.key, CheckTypes.FLEET_ONE.key, CheckTypes.INSTAMONEY.key, CheckTypes.TCHECK.key, CheckTypes.USBANK.key],
    };

    static readonly ACH_INSUFFICIENT_FUNDS: RejectionType = {
        key: "AchInsufficientFunds",
        display: "The available balance is insufficient to complete this transaction.",
        checkProviders: [CheckTypes.FLEET_ONE.key, CheckTypes.INSTAMONEY.key, CheckTypes.USBANK.key],
    };

    static readonly ACH_BANK_ACCOUNT_CLOSED: RejectionType = {
        key: "AchBankAccountClosed",
        display: "The account selected for this transaction has been closed.",
        checkProviders: [CheckTypes.FLEET_ONE.key, CheckTypes.INSTAMONEY.key, CheckTypes.USBANK.key],
    };

    static readonly ACH_BANK_ACCOUNT_NOT_FOUND: RejectionType = {
        key: "AchBankAccountNotFound",
        display: "The account selected for this transaction could not be located.",
        checkProviders: [CheckTypes.FLEET_ONE.key, CheckTypes.INSTAMONEY.key, CheckTypes.USBANK.key],
    };

    static readonly ACH_BANK_ACCOUNT_INVALID: RejectionType = {
        key: "AchBankAccountInvalid",
        display: "The number structure of this account is not valid.",
        checkProviders: [CheckTypes.FLEET_ONE.key, CheckTypes.INSTAMONEY.key, CheckTypes.USBANK.key],
    };

    static readonly ACH_DEBIT_UNAUTHORIZED: RejectionType = {
        key: "AchDebitUnauthorized",
        display: "The receiver has not authorized this transaction. Please contact them.",
        checkProviders: [CheckTypes.FLEET_ONE.key, CheckTypes.INSTAMONEY.key, CheckTypes.USBANK.key],
    };

    static readonly ACH_AUTHORIZATION_REVOKED: RejectionType = {
        key: "AchAuthorizationRevoked",
        display: "The receiver revoked this transaction’s authorization. Please contact them.",
        checkProviders: [CheckTypes.FLEET_ONE.key, CheckTypes.INSTAMONEY.key, CheckTypes.USBANK.key],
    };

    static readonly ACH_PAYMENT_STOPPED: RejectionType = {
        key: "AchPaymentStopped",
        display: "The receiver has requested to stop this transaction. Please contact them.",
        checkProviders: [CheckTypes.FLEET_ONE.key, CheckTypes.INSTAMONEY.key, CheckTypes.USBANK.key],
    };

    static readonly ACH_UNCOLLECTED_FUNDS: RejectionType = {
        key: "AchUncollectedFunds",
        display: "The balance is insufficient to complete the value of uncollected funds.",
        checkProviders: [CheckTypes.FLEET_ONE.key, CheckTypes.INSTAMONEY.key, CheckTypes.USBANK.key],
    };

    static readonly ACH_CUSTOMER_ADVICE_UNKNOWN_ORIGINATOR: RejectionType = {
        key: "AchCustomerAdviceUnknwonOriginator",
        display: "The receiver has not authorized this transaction. Please contact them.",
        checkProviders: [
            CheckTypes.COMCHEK.key,
            CheckTypes.EFS.key,
            CheckTypes.FLEET_ONE.key,
            CheckTypes.INSTAMONEY.key,
            CheckTypes.TCHECK.key,
            CheckTypes.USBANK.key,
        ],
    };

    static readonly ACH_CUSTOMER_ADVICE_ENTRY_NOT_ACCORDANCE: RejectionType = {
        key: "AchCustomerAdviceEntryNotAccordance",
        display: "This transaction was not properly initiated. Please contact your Bank.",
        checkProviders: [CheckTypes.FLEET_ONE.key, CheckTypes.INSTAMONEY.key, CheckTypes.USBANK.key],
    };

    static readonly ACH_BANK_ACCOUNT_FROZEN: RejectionType = {
        key: "AchBankAccountFrozen",
        display: "The balance in this account is unavailable.",
        checkProviders: [CheckTypes.FLEET_ONE.key, CheckTypes.INSTAMONEY.key, CheckTypes.USBANK.key],
    };

    static readonly ACH_NON_TRANSACTION_ACCOUNT: RejectionType = {
        key: "AchNonTransactionAccount",
        display: "This account is restricted from transaction activity.",
        checkProviders: [CheckTypes.COMCHEK.key, CheckTypes.EFS.key, CheckTypes.FLEET_ONE.key, CheckTypes.INSTAMONEY.key, CheckTypes.USBANK.key],
    };

    static readonly CALL_PROCESSOR: RejectionType = {
        key: "callcheckprocessor",
        display: "Payer must call check processor to complete the processing of this check",
        checkProviders: [
            CheckTypes.COMCHEK.key,
            CheckTypes.EFS.key,
            CheckTypes.FLEET_ONE.key,
            CheckTypes.INSTAMONEY.key,
            CheckTypes.TCHECK.key,
            CheckTypes.USBANK.key,
        ],
    };

    static readonly WEX_REQUEST_FAILED: RejectionType = {
        key: "WEXRequestFailed",
        display: "There was a payment error for this transaction. Please contact support.",
        checkProviders: [CheckTypes.TCHECK.key],
    };
    static readonly WEX_API_OFFLINE: RejectionType = {
        key: "WEXAPIisOffline",
        display: "There was a payment error for this transaction. Please contact support.",
        checkProviders: [],
    };
    static readonly WEX_ERROR: RejectionType = {
        key: "WEXEncounteredAnError",
        display: "There was a payment error for this transaction. Please contact support.",
        checkProviders: [],
    };
    static readonly WEX_INVALID_SITE_NUMBER: RejectionType = {
        key: "InvalidSiteNumber",
        display: "The site number for this transaction is invalid. Please contact support.",
        checkProviders: [CheckTypes.TCHECK.key],
    };
    static readonly WEX_INVALID_MESSAGE_TYPE: RejectionType = {
        key: "InvalidMessageType",
        display: "There was a problem processing your request. Please contact support.",
        checkProviders: [CheckTypes.TCHECK.key],
    };
    static readonly WEX_INVALID_MONEY_CODE: RejectionType = {
        key: "InvalidMoneyCode",
        display: "The money code for this transaction is invalid.",
        checkProviders: [CheckTypes.EFS.key, CheckTypes.TCHECK.key],
    };
    static readonly WEX_CHECK_PREVIOUSLY_USED: RejectionType = {
        key: "CheckWasPreviouslyUsed",
        display: "The check number was already used.",
        checkProviders: [CheckTypes.EFS.key, CheckTypes.TCHECK.key],
    };
    static readonly WEX_MAX_AMOUNT_EXCEEDED: RejectionType = {
        key: "MaxAmountExceeded",
        display: "Max amount of codes/daily limit exceeded, please contact EFS to raise limit.",
        checkProviders: [CheckTypes.EFS.key, CheckTypes.TCHECK.key],
    };
    static readonly WEX_MUST_BE_EXACT_AMOUNT: RejectionType = {
        key: "MustBeExactAmount",
        display: "Please enter the exact authorized amount.",
        checkProviders: [CheckTypes.EFS.key, CheckTypes.TCHECK.key],
    };
    static readonly WEX_UNKNOWN_ERROR_CODE: RejectionType = {
        key: "UnknownErrorCode",
        display: "There was a payment error for this transaction. Please contact support.",
        checkProviders: [],
    };
    static readonly WEX_CHECK_NUMBER_INVALID: RejectionType = {
        key: "CheckNumberInvalid",
        display: "The check number is invalid.",
        checkProviders: [CheckTypes.EFS.key, CheckTypes.TCHECK.key],
    };
    static readonly WEX_INVALID_TERMINAL_ID: RejectionType = {
        key: "InvalidTerminalId",
        display: "Invalid Terminal ID. Please contact support about setting up your company.",
        checkProviders: [CheckTypes.EFS.key, CheckTypes.TCHECK.key],
    };
    static readonly WEX_EXPRESS_CODE_NOT_ON_FILE: RejectionType = {
        key: "ExpressCodeNotOnFile",
        display: "The express code was not found. Please check your express code and try again, or contact support.",
        checkProviders: [CheckTypes.EFS.key, CheckTypes.TCHECK.key],
    };
    static readonly WEX_AMOUNT_CODE_INVALID: RejectionType = {
        key: "AmountCodeInvalid",
        display: "The amount entered was invalid. Please check that the amount is the authorized amount.",
        checkProviders: [CheckTypes.EFS.key, CheckTypes.TCHECK.key],
    };
    static readonly WEX_INVALID_FUEL_CARD_NUMBER: RejectionType = {
        key: "WexInvalidFuelCardNumber",
        display: "Invalid fuel card number. Please try your card number again.",
        checkProviders: InvoiceDeclineReasons.DefaultCheckProvidersForFuelCard,
    };
    static readonly WEX_INACTIVE_FUEL_CARD: RejectionType = {
        key: "WexInactiveFuelCard",
        display: "This fuel card is inactive. Please try a different payment method.",
        checkProviders: InvoiceDeclineReasons.DefaultCheckProvidersForFuelCard,
    };
    static readonly WEX_INVALID_UNIT_NUMBER: RejectionType = {
        key: "WexInvalidUnitNumber",
        display: "Invalid Unit Number. Please check (or input) the Unit Number and try again.",
        checkProviders: [],
    };
    static readonly WEX_INVALID_TRIP_NUMBER: RejectionType = {
        key: "WexInvalidTripNumber",
        display: "Invalid Trip Number. Please check (or input) the Trip Number and try again.",
        checkProviders: [],
    };
    static readonly WEX_INVALID_TRUCK_STOP: RejectionType = {
        key: "WexInvalidTruckStop",
        display: "Invalid Truck Stop. Please try a different payment method or contact support.",
        checkProviders: [],
    };
    static readonly WEX_INVALID_DRIVER_NUMBER: RejectionType = {
        key: "WexInvalidDriverNumber",
        display: "Invalid Driver Number. Please check (or input) the Driver Number and try again.",
        checkProviders: [],
    };
    static readonly WEX_INVALID_PRODUCT_CODE: RejectionType = {
        key: "InvalidProductCode",
        display: "Your card is not registered to be used at this location. Please contact your broker or carrier",
        checkProviders: InvoiceDeclineReasons.DefaultCheckProvidersForFuelCard,
    };
    static readonly WEX_INVALID_ODOMETER: RejectionType = {
        key: "WexInvalidOdometer",
        display: "Invalid Odometer. Please correct or contact account holder.",
        checkProviders: [],
    };
    static readonly WEX_INVALID_PO_NUMBER: RejectionType = {
        key: "WexInvalidPoNumber",
        display: "Invalid PO Number. Please correct or contact account holder.",
        checkProviders: [],
    };
    static readonly WEX_INVALID_TRAILER_NUMBER: RejectionType = {
        key: "WexInvalidTrailerNumber",
        display: "Invalid Trailer Number. Please correct or contact account holder.",
        checkProviders: [],
    };
    static readonly INVALID_REQUEST_ERROR: RejectionType = {
        key: "InvalidRequestError",
        display: "Invalid request. Please correct or contact account holder.",
        checkProviders: [],
    };
    static readonly MISSING_FIELDS_ERROR: RejectionType = {
        key: "MissingFieldsError",
        display: "Missing fields on request. Please correct or contact account holder.",
        checkProviders: [],
    };
    static readonly UNKNOWN_ERROR_CODE_43: RejectionType = {
        key: "UnknownErrorCode43",
        display: "Unknown Error: UnknownErrorCode43",
        checkProviders: [],
    };
    static readonly UNKNOWN_ERROR_CODE_46: RejectionType = {
        key: "UnknownErrorCode46",
        display: "Unknown Error: UnknownErrorCode46",
        checkProviders: [],
    };
    static readonly UNEXPECTED_ERROR: RejectionType = {
        key: "UnexpectedError",
        display: "Unknown Error: UnexpectedError",
        checkProviders: [],
    };
    static readonly NMI_REQUEST_DECLINED: RejectionType = {
        key: "nmirequestdeclined",
        display: "Transaction was declined by the payment processor.",
        checkProviders: InvoiceDeclineReasons.DefaultCheckProvidersForFuelCard,
    };
    static readonly NON_US_ISSUER_DOES_NOT_PARTICIPATE: RejectionType = {
        key: "nonusissuerdoesnotparticipate",
        display: "Transaction was declined by the payment processor.",
        checkProviders: InvoiceDeclineReasons.DefaultCheckProvidersForCardWithoutEFSAndTCheckAndComchek,
    };
    static readonly ISSUER_SYSTEM_UNAVAILABLE: RejectionType = {
        key: "issuersystemunavailable",
        display: "Transaction was declined by the payment processor.",
        checkProviders: [],
    };
    static readonly NOT_A_MAIL_PHONE_ORDER: RejectionType = {
        key: "notamail/phoneorder",
        display: "Transaction was declined by the payment processor.",
        checkProviders: [],
    };
    static readonly SERVICE_NOT_SUPPORTED: RejectionType = {
        key: "servicenotsupported",
        display: "Transaction was declined by the payment processor.",
        checkProviders: [],
    };
    static readonly AVS_NOT_AVAILABLE: RejectionType = {
        key: "avsnotavailable",
        display: "Transaction was declined by the payment processor.",
        checkProviders: [],
    };
    static readonly CVV2_CVC2_NO_MATCH: RejectionType = {
        key: "cvv2/cvc2nomatch",
        display: "The CVV number is invalid.",
        checkProviders: InvoiceDeclineReasons.DefaultCheckProvidersForCardWithoutEFSAndTCheckAndComchek,
    };
    static readonly NOT_PROCESSED: RejectionType = {
        key: "notprocessed",
        display: "The CVV number is invalid.",
        checkProviders: InvoiceDeclineReasons.DefaultCheckProvidersForCardWithoutEFSAndTCheckAndComchek,
    };
    static readonly MERCHANT_HAS_INDICATED_THAT_CVV2_IS_NOT_PRESENT: RejectionType = {
        key: "merchanthasindicatedthatcvv2/cvc2isnotpresentoncard",
        display: "The CVV number is invalid.",
        checkProviders: InvoiceDeclineReasons.DefaultCheckProvidersForCardWithoutEFSAndTCheckAndComchek,
    };
    static readonly ISSUER_IS_NOT_CERTIFIED_AND_OR_HAS_NOT_PROVIDED_VISA_ENCRYPTION_KEYS: RejectionType = {
        key: "issuerisnotcertifiedandorhasnotprovidedvisaencryptionkeys",
        display: "The CVV number is invalid.",
        checkProviders: InvoiceDeclineReasons.DefaultCheckProvidersForCardWithoutEFSAndTCheckAndComchek,
    };
    static readonly TRANSACTION_WAS_DECLINED_BY_PROCESSOR: RejectionType = {
        key: "TransactionWasDeclinedByProcessor",
        display: "Transaction was declined by the credit card processor.",
        checkProviders: InvoiceDeclineReasons.DefaultCheckProvidersForCardWithoutEFSAndTCheckAndComchek,
    };
    static readonly DO_NOT_HONOR: RejectionType = {
        key: "DoNotHonor",
        display: "Transaction was declined by the credit card processor.",
        checkProviders: [],
    };
    static readonly INSUFFICIENT_FUNDS: RejectionType = {
        key: "InsufficientFunds",
        display: "Transaction was declined by the credit card processor.",
        checkProviders: [],
    };
    static readonly OVER_LIMIT: RejectionType = {
        key: "OverLimit",
        display: "Transaction was declined by the credit card processor.",
        checkProviders: [],
    };
    static readonly TRANSACTION_NOT_ALLOWED: RejectionType = {
        key: "TransactionNotAllowed",
        display: "Transaction was declined by the credit card processor.",
        checkProviders: [],
    };
    static readonly INCORRECT_PAYMENT_INFORMATION: RejectionType = {
        key: "IncorrectPaymentInformation",
        display: "Transaction was declined by the credit card processor.",
        checkProviders: [],
    };
    static readonly NO_SUCH_CARD_ISSUER: RejectionType = {
        key: "NoSuchCardIssuer",
        display: "Transaction was declined by the credit card processor.",
        checkProviders: [],
    };
    static readonly NO_CARD_NUMBER_ON_FILE_WITH_ISSUER: RejectionType = {
        key: "NoCardNumberOnFileWithIssuer",
        display: "Transaction was declined by the credit card processor.",
        checkProviders: [],
    };
    static readonly EXPIRED_CARD: RejectionType = {
        key: "ExpiredCard",
        display: "This credit card is expired. Please check the expiration date or try a different card.",
        checkProviders: InvoiceDeclineReasons.DefaultCheckProvidersForCardWithoutEFSAndTCheckAndComchek,
    };
    static readonly INVALID_EXPIRATION_DATE: RejectionType = {
        key: "InvalidExpirationDate",
        display: "The expiration date is invalid. Please check the expiration date or try a different card.",
        checkProviders: InvoiceDeclineReasons.DefaultCheckProvidersForCardWithoutEFSAndTCheckAndComchek,
    };
    static readonly INVALID_CARD_SECURITY_CODE: RejectionType = {
        key: "InvalidCardSecurityCode",
        display: "The security code is invalid. Please check the security code or try a different card.",
        checkProviders: InvoiceDeclineReasons.DefaultCheckProvidersForCardWithoutEFSAndTCheckAndComchek,
    };
    static readonly INVALID_PIN: RejectionType = {
        key: "InvalidPin",
        display: "The pin is invalid. Please check the pin or try a different card.",
        checkProviders: InvoiceDeclineReasons.DefaultCheckProvidersForCardWithoutEFSAndTCheckAndComchek,
    };
    static readonly CALL_ISSUER_FOR_FURTHER_INFORMATION: RejectionType = {
        key: "CallIssuerForFurtherInformation",
        display: "Transaction was declined by the credit card processor.",
        checkProviders: [],
    };
    static readonly PICK_UP_CARD: RejectionType = {
        key: "PickUpCard",
        display: "Transaction was declined by the credit card processor.",
        checkProviders: [],
    };
    static readonly LOST_CARD: RejectionType = {
        key: "LostCard",
        display: "Transaction was declined by the credit card processor.",
        checkProviders: [],
    };
    static readonly STOLEN_CARD: RejectionType = {
        key: "StolenCard",
        display: "Transaction was declined by the credit card processor.",
        checkProviders: [],
    };
    static readonly FRAUDULENT_CARD: RejectionType = {
        key: "FraudulentCard",
        display: "Transaction was declined by the credit card processor.",
        checkProviders: [],
    };
    static readonly DECLINED_WITH_FURTHER_INSTRUCTIONS_AVAILABLE: RejectionType = {
        key: "DeclinedWithFurtherInstructionsAvailable",
        display: "Transaction was declined by the credit card processor.",
        checkProviders: [],
    };
    static readonly DECLINED_STOP_ALL_RECURRING_PAYMENTS: RejectionType = {
        key: "DeclinedStopAllRecurringPayments",
        display: "Transaction was declined by the credit card processor.",
        checkProviders: [],
    };
    static readonly DECLINED_STOP_THIS_RECURRING_PROGRAM: RejectionType = {
        key: "DeclinedStopThisRecurringProgram",
        display: "Transaction was declined by the credit card processor.",
        checkProviders: [],
    };
    static readonly DECLINED_UPDATE_CARDHOLDER_DATA_AVAILABLE: RejectionType = {
        key: "DeclinedUpdateCardholderDataAvailable",
        display: "Transaction was declined by the credit card processor.",
        checkProviders: [],
    };
    static readonly DECLINED_RETRY_IN_A_FEW_DAYS: RejectionType = {
        key: "DeclinedRetryInAFewDays",
        display: "Transaction was declined by the credit card processor.",
        checkProviders: [],
    };
    static readonly TRANSACTION_WAS_REJECTED_BY_GATEWAY: RejectionType = {
        key: "TransactionWasRejectedByGateway",
        display: "Transaction was declined by the credit card processor.",
        checkProviders: [],
    };
    static readonly TRANSACTION_ERROR_RETURNED_BY_PROCESSOR: RejectionType = {
        key: "TransactionErrorReturnedByProcessor",
        display: "Transaction was declined by the credit card processor.",
        checkProviders: [],
    };
    static readonly INVALID_MERCHANT_CONFIGURATION: RejectionType = {
        key: "InvalidMerchantConfiguration",
        display: "Transaction was declined by the credit card processor.",
        checkProviders: [],
    };
    static readonly MERCHANT_ACCOUNT_IS_INACTIVE: RejectionType = {
        key: "MerchantAccountIsInactive",
        display: "Transaction was declined by the credit card processor.",
        checkProviders: [],
    };
    static readonly COMMUNICATION_ERROR: RejectionType = {
        key: "CommunicationError",
        display: "Transaction was declined by the credit card processor.",
        checkProviders: [],
    };
    static readonly COMMUNICATION_ERROR_WITH_ISSUER: RejectionType = {
        key: "CommunicationErrorWithIssuer",
        display: "Transaction was declined by the credit card processor.",
        checkProviders: [],
    };
    static readonly DUPLICATE_TRANSACTION_AT_PROCESSOR: RejectionType = {
        key: "DuplicateTransactionAtProcessor",
        display: "Transaction was declined by the credit card processor.",
        checkProviders: [],
    };
    static readonly PROCESSOR_FORMAT_ERROR: RejectionType = {
        key: "ProcessorFormatError",
        display: "Transaction was declined by the credit card processor.",
        checkProviders: [],
    };
    static readonly INVALID_TRANSACTION_INFORMATION: RejectionType = {
        key: "InvalidTransactionInformation",
        display: "Transaction was declined by the credit card processor.",
        checkProviders: [],
    };
    static readonly PROCESSOR_FEATURE_NOT_AVAILABLE: RejectionType = {
        key: "ProcessorFeatureNotAvailable",
        display: "Transaction was declined by the credit card processor.",
        checkProviders: [],
    };
    static readonly UNSUPPORTED_CARD_TYPE: RejectionType = {
        key: "UnsupportedCardType",
        display: "Transaction was declined by the credit card processor.",
        checkProviders: [],
    };
    static readonly AMOUNT_EXCEED_MAXIMUM: RejectionType = {
        key: "amountexceedsmaximum",
        display: "Not enough funds on the Comdata fuel card",
        checkProviders: [],
    };
    static readonly COMDATA_CARD_INVALID_LOCATION: RejectionType = {
        key: "ComdataLocationInvalid",
        display: "Card not approved for this location. Please contact account holder or use another payment method.",
        checkProviders: [],
    };
    static readonly COMDATA_MAXIMUM_DRAW_LIMIT: RejectionType = {
        key: "ComdataMaximumDrawLimit",
        display: "This card cannot be used again today. Please contact account holder or use another payment method.",
        checkProviders: [CheckTypes.COMCHEKV2.key],
    };
    static readonly COMDATA_OVER_PO_LIMIT: RejectionType = {
        key: "ComdataOverPOLimit",
        display: "PO limit exceeded. Please contact account holder or use another payment method.",
        checkProviders: [],
    };
    static readonly COMDATA_PO_INVALID_THIS_CARD: RejectionType = {
        key: "ComdataPOInvalidThisCard",
        display: "PO cannot be paid with this card. Please contact account holder or use another payment method.",
        checkProviders: [],
    };
    static readonly COMDATA_PO_INVALID: RejectionType = {
        key: "ComdataPOInvalid",
        display: "Invalid PO. Please correct or contact account holder.",
        checkProviders: [],
    };
    static readonly COMDATA_DUPLICATE_PO: RejectionType = {
        key: "ComdataDuplicatePO",
        display: "Duplicate PO. Please correct or contact account holder.",
        checkProviders: [],
    };
    static readonly COMDATA_INVALID_NAME: RejectionType = {
        key: "ComdataInvalidName",
        display: "Payer name does not match expected value. Please correct or contact account holder.",
        checkProviders: [CheckTypes.COMCHEKV2.key],
    };
    static readonly COMDATA_TRAILER_NUMBER_INVALID: RejectionType = {
        key: "ComdataTrailerNumberInvalid",
        display: "Trailer number does not match expected value. Please correct or contact account holder.",
        checkProviders: [],
    };
    static readonly COMDATA_TRAILER_HUB_INVALID: RejectionType = {
        key: "ComdataTrailerHubInvalid",
        display: "Trailer hub mileage does not match expected value. Please correct or contact account holder.",
        checkProviders: [],
    };
    static readonly COMDATA_TRAILER_HOURS_INVALID: RejectionType = {
        key: "ComdataTrailerHoursInvalid",
        display: "Trailer hours do not match expected value. Please correct or contact account holder.",
        checkProviders: [],
    };
    static readonly COMDATA_DL_STATE_INVALID: RejectionType = {
        key: "ComdataDLStateInvalid",
        display: "License state does not match expected value. Please correct or contact account holder.",
        checkProviders: [],
    };
    static readonly COMDATA_DL_NUMBER_INVALID: RejectionType = {
        key: "ComdataDLNumberInvalid",
        display: "License number does not match expected value. Please correct or contact account holder.",
        checkProviders: [],
    };
    static readonly COMDATA_NAME_REQUIRED: RejectionType = {
        key: "ComdataNameRequired",
        display: "First Name or Last Name is missing",
        checkProviders: [CheckTypes.COMCHEKV2.key],
    };
    static readonly COMDATA_TRY_CHECK_AUTH: RejectionType = {
        key: "ComdataTryCheckAuth",
        display: "Express Code is already registered to a check. Please contact company.",
        checkProviders: [CheckTypes.COMCHEKV2.key],
    };
    static readonly COMDATA_EXPRESS_INACTIVE: RejectionType = {
        key: "ComdataExpressInactive",
        display: "Express Code is no longer active. Please contact company.",
        checkProviders: [CheckTypes.COMCHEKV2.key],
    };
    static readonly COMDATA_TRAILER_HOURS_REQUIRED: RejectionType = {
        key: "ComdataTrailerHoursRequired",
        display: "Trailer Hours is required.",
        checkProviders: [CheckTypes.COMCHEKV2.key],
    };
    static readonly COMDATA_TRACTOR_HUB_REQUIRED: RejectionType = {
        key: "ComdataTractorHubRequired",
        display: "Hub Reading is required.",
        checkProviders: [CheckTypes.COMCHEKV2.key],
    };
    static readonly COMDATA_TRAILER_HUB_REQUIRED: RejectionType = {
        key: "ComdataTrailerHubRequired",
        display: "Trailer Hub is required.",
        checkProviders: [CheckTypes.COMCHEKV2.key],
    };
    static readonly COMDATA_DL_STATE_REQUIRED: RejectionType = {
        key: "ComdataDLStateRequired",
        display: "Driver License State is required.",
        checkProviders: [CheckTypes.COMCHEKV2.key],
    };
    static readonly COMDATA_DL_NUMBER_REQUIRED: RejectionType = {
        key: "ComdataDLNumberRequired",
        display: "Driver License Number is required.",
        checkProviders: [CheckTypes.COMCHEKV2.key],
    };
    static readonly COMDATA_TRAILER_NUMBER_REQUIRED: RejectionType = {
        key: "ComdataTrailerNumberRequired",
        display: "Trailer Number is required.",
        checkProviders: [CheckTypes.COMCHEKV2.key],
    };
    static readonly COMDATA_PO_REQUIRED: RejectionType = {
        key: "ComdataPORequired",
        display: "PO is required.",
        checkProviders: [CheckTypes.COMCHEKV2.key],
    };
    static readonly COMDATA_TRIP_NUMBER_REQUIRED: RejectionType = {
        key: "ComdataTripNumberRequired",
        display: "Trip Number is required.",
        helpText: "Trip Number should be up to 10 characters long.",
        checkProviders: [CheckTypes.COMCHEKV2.key],
    };
    static readonly COMDATA_UNIT_NUMBER_REQUIRED: RejectionType = {
        key: "ComdataUnitNumberRequired",
        display: "Unit Number is required.",
        helpText: "Unit Number should be up to 6 characters long.",
        checkProviders: [CheckTypes.COMCHEKV2.key],
    };
    static readonly COMDATA_CARD_NUMBER_REQUIRED: RejectionType = {
        key: "ComdataCardNumberRequired",
        display: "Card Number is required.",
        checkProviders: [CheckTypes.COMCHEKV2.key],
    };
    static readonly COMDATA_DRIVER_NUMBER_REQUIRED: RejectionType = {
        key: "ComdataDriverNumberRequired",
        display: "Driver Number is required.",
        helpText: "Driver Number should be up to 16 characters long.",
        checkProviders: [CheckTypes.COMCHEKV2.key],
    };
    static readonly COMDATA_CARD_NOT_ENABLED: RejectionType = {
        key: "comdatacardnotenabled",
        display: "Card not enabled for this type of purchase.",
        checkProviders: [],
    };
    static readonly ADDRESS_MATCH_ONLY: RejectionType = {
        key: "addressmatchonly",
        display: "Transaction declined by the payment processor: addressmatchonly",
        checkProviders: [],
    };
    static readonly ADDRESS_CUSTOMER_NAME_MATCH_ONLY: RejectionType = {
        key: "addresscustomernamematchonly",
        display: "Transaction declined by the payment processor: addresscustomernamematchonly",
        checkProviders: [],
    };
    static readonly NINE_CHARACTER_NUMERIC_ZIP_MATCH_ONLY: RejectionType = {
        key: "9characternumericzipmatchonly",
        display: "Transaction declined by the payment processor: 9characternumericzipmatchonly",
        checkProviders: [],
    };
    static readonly FIVE_CHARACTER_ZIP_MATCH_ONLY: RejectionType = {
        key: "5characterzipmatchonly",
        display: "Transaction declined by the payment processor: 5characterzipmatchonly",
        checkProviders: [],
    };
    static readonly FIVE_CHARACTER_ZIP_CUSTOMER_NAME_MATCH_ONLY: RejectionType = {
        key: "5characterzipcustomernamematchonly",
        display: "Transaction declined by the payment processor: 5characterzipcustomernamematchonly",
        checkProviders: [],
    };
    static readonly NO_ADDRESS_OR_ZIP_MATCH_ONLY: RejectionType = {
        key: "noaddressorzipmatchonly",
        display: "Transaction declined by the payment processor: noaddressorzipmatchonly",
        checkProviders: [],
    };
    static readonly NO_ADDRESS_OR_ZIP_OR_CUSTOMER_NAME_MATCH_ONLY: RejectionType = {
        key: "noaddressorziporcustomernamematchonly",
        display: "Transaction declined by the payment processor: noaddressorziporcustomernamematchonly",
        checkProviders: [],
    };
    static readonly ADDRESS_UNAVAILABLE: RejectionType = {
        key: "addressunavailable",
        display: "Transaction declined by the payment processor: addressunavailable",
        checkProviders: [],
    };
    static readonly UNKNOWN_ERROR: RejectionType = {
        key: "UnknownError",
        display: "Card Declined",
        checkProviders: [],
    };
    static readonly GENERIC_DECLINE: RejectionType = {
        key: "GenericDecline",
        display: "Card Declined",
        checkProviders: [],
    };
    static readonly FRAUDULENT_CARD_DECLINE: RejectionType = {
        key: "FraudulentCardDecline",
        display: "Card Declined",
        checkProviders: [],
    };
    static readonly LOST_CARD_DECLINE: RejectionType = {
        key: "LostCardDecline",
        display: "Card Declined",
        checkProviders: [],
    };
    static readonly MERCHANT_BLACKLIST: RejectionType = {
        key: "MerchantBlacklist",
        display: "Card Declined",
        checkProviders: [],
    };
    static readonly STOLEN_CARD_DECLINE: RejectionType = {
        key: "StolenCardDecline",
        display: "Card Declined",
        checkProviders: [],
    };
    static readonly CALL_ISSUER_DECLINE: RejectionType = {
        key: "CallIssuerDecline",
        display: "Card Declined. Please contact your issuing bank",
        checkProviders: [],
    };
    static readonly CARD_NOT_SUPPORTED: RejectionType = {
        key: "CardNotSupported",
        display: "Card Declined. Please contact your issuing bank",
        checkProviders: [],
    };
    static readonly CARD_VELOCITY_EXCEEDED: RejectionType = {
        key: "CardVelocityExceeded",
        display: "Card Declined. Please contact your issuing bank",
        checkProviders: [],
    };
    static readonly CURRENCY_NOT_SUPPORTED: RejectionType = {
        key: "CurrencyNotSupported",
        display: "Card Declined. Please contact your issuing bank",
        checkProviders: [],
    };
    static readonly DO_NOT_HONOR_DECLINE: RejectionType = {
        key: "DoNotHonorDecline",
        display: "Card Declined. Please contact your issuing bank",
        checkProviders: [],
    };
    static readonly DO_NOT_TRY_AGAIN: RejectionType = {
        key: "DoNotTryAgain",
        display: "Card Declined. Please contact your issuing bank",
        checkProviders: [],
    };
    static readonly INVALID_ACCOUNT: RejectionType = {
        key: "InvalidAccount",
        display: "Card Declined. Please contact your issuing bank",
        checkProviders: [],
    };
    static readonly INVALID_AMOUNT: RejectionType = {
        key: "InvalidAmount",
        display: "Card Declined. Please contact your issuing bank",
        checkProviders: [],
    };
    static readonly ISSUER_NOT_AVAILABLE: RejectionType = {
        key: "IssuerNotAvailable",
        display: "Card Declined. Please contact your issuing bank",
        checkProviders: [],
    };
    static readonly NEW_ACCOUNT_INFORMATION_AVAILABLE: RejectionType = {
        key: "NewAccountInformationAvailable",
        display: "Card Declined. Please contact your issuing bank",
        checkProviders: [],
    };
    static readonly NO_ACTION_TAKEN: RejectionType = {
        key: "NoActionTaken",
        display: "Card Declined. Please contact your issuing bank",
        checkProviders: [],
    };
    static readonly NOT_PERMITTED: RejectionType = {
        key: "NotPermitted",
        display: "Card Declined. Please contact your issuing bank",
        checkProviders: [],
    };
    static readonly OFFLINE_PIN_REQUIRED: RejectionType = {
        key: "OfflinePinRequired",
        display: "Card Declined. Please contact your issuing bank",
        checkProviders: [],
    };
    static readonly PICKUP_CARD_DECLINE: RejectionType = {
        key: "PickupCardDecline",
        display: "Card Declined. Please contact your issuing bank",
        checkProviders: [],
    };
    static readonly REENTER_TRANSACTION: RejectionType = {
        key: "ReenterTransaction",
        display: "Card Declined. Please contact your issuing bank",
        checkProviders: [],
    };
    static readonly RESTRICTED_CARD: RejectionType = {
        key: "RestrictedCard",
        display: "Card Declined. Please contact your issuing bank",
        checkProviders: [],
    };
    static readonly REVOCATION_OF_ALL_AUTHORIZATIONS: RejectionType = {
        key: "RevocationOfAllAuthorizations",
        display: "Card Declined. Please contact your issuing bank",
        checkProviders: [],
    };
    static readonly REVOCATION_OF_AUTHORIZATION: RejectionType = {
        key: "RevocationOfAuthorization",
        display: "Card Declined. Please contact your issuing bank",
        checkProviders: [],
    };
    static readonly SECURITY_VIOLATION: RejectionType = {
        key: "SecurityViolation",
        display: "Card Declined. Please contact your issuing bank",
        checkProviders: [],
    };
    static readonly SERVICE_NOT_ALLOWED: RejectionType = {
        key: "ServiceNotAllowed",
        display: "Card Declined. Please contact your issuing bank",
        checkProviders: [],
    };
    static readonly STOP_PAYMENT_ORDER: RejectionType = {
        key: "StopPaymentOrder",
        display: "Card Declined. Please contact your issuing bank",
        checkProviders: [],
    };
    static readonly TRANSACTION_NOT_ALLOWED_DECLINE: RejectionType = {
        key: "TransactionNotAllowedDecline",
        display: "Card Declined. Please contact your issuing bank",
        checkProviders: [],
    };
    static readonly TRY_AGAIN_LATER: RejectionType = {
        key: "TryAgainLater",
        display: "Card Declined. Please contact your issuing bank",
        checkProviders: [],
    };
    static readonly TESTMODE_DECLINE: RejectionType = {
        key: "TestmodeDecline",
        display: "Card is not valid",
        checkProviders: [],
    };
    static readonly INCORRECT_NUMBER: RejectionType = {
        key: "IncorrectNumber",
        display: "Card Number is not valid. Please update and try again",
        checkProviders: [],
    };
    static readonly INVALID_NUMBER: RejectionType = {
        key: "InvalidNumber",
        display: "Card Number is not valid. Please update and try again",
        checkProviders: [],
    };
    static readonly INCORRECT_CVC: RejectionType = {
        key: "IncorrectCvc",
        display: "CVC is not valid. Please update and try again",
        checkProviders: [],
    };
    static readonly INVALID_CVC: RejectionType = {
        key: "InvalidCvc",
        display: "CVC is not valid. Please update and try again",
        checkProviders: [],
    };
    static readonly INSUFFICIENT_FUNDS_DECLINE: RejectionType = {
        key: "InsufficientFundsDecline",
        display: "Card does not have enough funds to complete transaction. Please try a different payment method.",
        checkProviders: [],
    };
    static readonly INVALID_EXPIRY_MONTH: RejectionType = {
        key: "InvalidExpiryMonth",
        display: "Expiration date is not valid. Please update and try again",
        checkProviders: [],
    };
    static readonly INVALID_EXPIRY_YEAR: RejectionType = {
        key: "InvalidExpiryYear",
        display: "Expiration date is not valid. Please update and try again",
        checkProviders: [],
    };
    static readonly INCORRECT_PIN: RejectionType = {
        key: "IncorrectPin",
        display: "PIN is not valid. Please update and try again",
        checkProviders: [],
    };
    static readonly INVALID_PIN_DECLINE: RejectionType = {
        key: "InvalidPinDecline",
        display: "PIN is not valid. Please update and try again",
        checkProviders: [],
    };
    static readonly DUPLICATE_TRANSACTION: RejectionType = {
        key: "DuplicateTransaction",
        display: "Please make sure this charge wasn't already made and try again",
        checkProviders: [],
    };
    static readonly AUTHENTICATION_REQUIRED: RejectionType = {
        key: "AuthenticationRequired",
        display: "Please try a different payment type",
        checkProviders: [],
    };
    static readonly APPROVE_WITH_ID: RejectionType = {
        key: "ApproveWithId",
        display: "Please try a different payment type",
        checkProviders: [],
    };
    static readonly EXPIRED_CARD_DECLINE: RejectionType = {
        key: "ExpiredCardDecline",
        display: "Please try a different payment type",
        checkProviders: [],
    };
    static readonly PIN_TRY_EXCEEDED: RejectionType = {
        key: "PinTryExceeded",
        display: "Please try a different payment type",
        checkProviders: [],
    };
    static readonly WITHDRAWAL_COUNT_LIMIT_EXCEEDED: RejectionType = {
        key: "WithdrawalCountLimitExceeded",
        display: "Please try a different payment type",
        checkProviders: [],
    };
    static readonly PROCESSING_ERROR: RejectionType = {
        key: "ProcessingError",
        display: "Please try again",
        checkProviders: [],
    };
    static readonly INCORRECT_ZIP: RejectionType = {
        key: "IncorrectZip",
        display: "Zip code is not valid. Please update and try again",
        checkProviders: [],
    };
    static readonly CARD_DECLINED_RATE_LIMIT_EXCEEDED: RejectionType = {
        key: "CardDeclinedRateLimitExceeded",
        display: "Please try a different payment type",
        checkProviders: [],
    };
    static readonly COMDATA_INVALID_EXPIRY_DATE: RejectionType = {
        key: "ComdataInvalidExpirationDate",
        display: "Expiration date is not valid. Please update and try again",
        checkProviders: [],
    };
    static readonly RAINFOREST_GENERIC_DECLINE: RejectionType = {
        key: "RainforestGenericErrorResponse",
        display: "Card Declined. Please contact your issuing bank",
        checkProviders: [],
    };
    static readonly RAINFOREST_DECLINED: RejectionType = {
        key: "RainforestDeclinedErrorResponse",
        display: "Card Declined. Please contact your issuing bank",
        checkProviders: [],
    };
    static readonly RAINFOREST_DO_NOT_HONOR: RejectionType = {
        key: "RainforestDoNotHonorErrorResponse",
        display: "Card Declined. Please contact your issuing bank",
        checkProviders: [],
    };
    static readonly RAINFOREST_EXPIRED_CARD: RejectionType = {
        key: "RainforestExpiredCardErrorResponse",
        display: "Card Declined. Please contact your issuing bank",
        checkProviders: [],
    };
    static readonly RAINFOREST_FRAUDULENT_CARD: RejectionType = {
        key: "RainforestFraudulentCardErrorResponse",
        display: "Card Declined. Please contact your issuing bank",
        checkProviders: [],
    };
    static readonly RAINFOREST_INCORRECT_PAYMENT_INFORMATION: RejectionType = {
        key: "RainforestIncorrectPaymentInformationErrorResponse",
        display: "Card Information is not valid. Please update and try again",
        checkProviders: [],
    };
    static readonly RAINFOREST_INSUFFICIENT_FUNDS: RejectionType = {
        key: "RainforestInsufficientFundsErrorResponse",
        display: "Card does not have enough funds to complete transaction. Please try a different payment method",
        checkProviders: [],
    };
    static readonly RAINFOREST_INVALID_CARD_SECURITY_CODE: RejectionType = {
        key: "RainforestInvalidCardSecurityCodeErrorResponse",
        display: "CVC is not valid. Please update and try again",
        checkProviders: [],
    };
    static readonly RAINFOREST_INVALID_EXPIRATION_DATE: RejectionType = {
        key: "RainforestInvalidExpirationDateErrorResponse",
        display: "Expiration date is not valid. Please update and try again",
        checkProviders: [],
    };
    static readonly RAINFOREST_INVALID_PIN: RejectionType = {
        key: "RainforestInvalidPinErrorResponse",
        display: "PIN is not valid. Please update and try again",
        checkProviders: [],
    };
    static readonly RAINFOREST_LOST_CARD: RejectionType = {
        key: "RainforestLostCardErrorResponse",
        display: "Card Declined. Please contact your issuing bank",
        checkProviders: [],
    };
    static readonly RAINFOREST_NO_CARD_NUMBER_ON_FILE: RejectionType = {
        key: "RainforestNoCardNumberOnFileWithIssuerErrorResponse",
        display: "Card Information is not valid. Please update and try again",
        checkProviders: [],
    };
    static readonly RAINFOREST_NO_SUCH_CARD_ISSUER: RejectionType = {
        key: "RainforestNoSuchCardIssuerErrorResponse",
        display: "Card Information is not valid. Please update and try again",
        checkProviders: [],
    };
    static readonly RAINFOREST_OVER_LIMIT: RejectionType = {
        key: "RainforestOverLimitErrorResponse",
        display: "Card does not have enough funds to complete transaction. Please try a different payment method",
        checkProviders: [],
    };
    static readonly RAINFOREST_PROCESSING_ERROR: RejectionType = {
        key: "RainforestProcessingErrorResponse",
        display: "Please try again",
        checkProviders: [],
    };
    static readonly RAINFOREST_RISK_DECLINE: RejectionType = {
        key: "RainforestRiskDeclineErrorResponse",
        display: "Please try a different payment method",
        checkProviders: [],
    };
    static readonly RAINFOREST_STOLEN_CARD: RejectionType = {
        key: "RainforestStolenCardErrorResponse",
        display: "Card Declined. Please contact your issuing bank",
        checkProviders: [],
    };
    static readonly RAINFOREST_UNEXPECTED_PAYIN_TOKEN: RejectionType = {
        key: "RainforestUnexpectedPayinTokenErrorResponse",
        display: "Please try again",
        checkProviders: [],
    };
    static readonly RAINFOREST_UNSUPPORTED_CARD_TYPE: RejectionType = {
        key: "RainforestUnsupportedCardTypeErrorResponse",
        display: "Please try a different payment method",
        checkProviders: [],
    };
    static readonly RAINFOREST_PAYMENT_STILL_PROCESSING: RejectionType = {
        key: "RainforestCardPaymentStillProcessing",
        display: "A payment for this invoice was already submitted, please ask the merchant to contact Support.",
        checkProviders: [],
    };
    static readonly ACH_CUSTOMER_ADVICE_NOT_AUTHORIZED: RejectionType = {
        key: "AchCustomerAdviceNotAuthorized",
        display: "The receiver has not authorized this transaction. Please contact them.",
        checkProviders: [CheckTypes.FLEET_ONE.key, CheckTypes.INSTAMONEY.key, CheckTypes.USBANK.key],
    };

    private static readonly LOOKUP = {
        [InvoiceDeclineReasons.FUNDS.key]: InvoiceDeclineReasons.FUNDS,
        [InvoiceDeclineReasons.EXPRESS_CODE.key]: InvoiceDeclineReasons.EXPRESS_CODE,
        [InvoiceDeclineReasons.INVALID_DATA.key]: InvoiceDeclineReasons.INVALID_DATA,
        [InvoiceDeclineReasons.NUMBER_NOT_AUTHED.key]: InvoiceDeclineReasons.NUMBER_NOT_AUTHED,
        [InvoiceDeclineReasons.UNABLE_TO_PROCESS.key]: InvoiceDeclineReasons.UNABLE_TO_PROCESS,
        [InvoiceDeclineReasons.INVALID_TRIP_NUMBER.key]: InvoiceDeclineReasons.INVALID_TRIP_NUMBER,
        [InvoiceDeclineReasons.INVALID_DRIVER_NUMBER.key]: InvoiceDeclineReasons.INVALID_DRIVER_NUMBER,
        [InvoiceDeclineReasons.INVALID_TRIP_UNIT_DRIVER.key]: InvoiceDeclineReasons.INVALID_TRIP_UNIT_DRIVER,
        [InvoiceDeclineReasons.INCORRECT_AMOUNT.key]: InvoiceDeclineReasons.INCORRECT_AMOUNT,
        [InvoiceDeclineReasons.INVALID_UNIT_NUMBER.key]: InvoiceDeclineReasons.INVALID_UNIT_NUMBER,
        [InvoiceDeclineReasons.INVALID_LOCATION.key]: InvoiceDeclineReasons.INVALID_LOCATION,
        [InvoiceDeclineReasons.INVALID_CHECK_NUMBER.key]: InvoiceDeclineReasons.INVALID_CHECK_NUMBER,
        [InvoiceDeclineReasons.INVALID_PAYCODE.key]: InvoiceDeclineReasons.INVALID_PAYCODE,
        [InvoiceDeclineReasons.SHIPPER_DECLINED.key]: InvoiceDeclineReasons.SHIPPER_DECLINED,
        [InvoiceDeclineReasons.CARD_INVALID_NUMBER.key]: InvoiceDeclineReasons.CARD_INVALID_NUMBER,
        [InvoiceDeclineReasons.CARD_INVALID_EXPIRY.key]: InvoiceDeclineReasons.CARD_INVALID_EXPIRY,
        [InvoiceDeclineReasons.CARD_INVALID_CVC.key]: InvoiceDeclineReasons.CARD_INVALID_CVC,
        [InvoiceDeclineReasons.CARD_INVALID_ZIP.key]: InvoiceDeclineReasons.CARD_INVALID_ZIP,
        [InvoiceDeclineReasons.CARD_DECLINED.key]: InvoiceDeclineReasons.CARD_DECLINED,
        [InvoiceDeclineReasons.REGISTER_CHECK.key]: InvoiceDeclineReasons.REGISTER_CHECK,
        [InvoiceDeclineReasons.COMDATA_DOWN.key]: InvoiceDeclineReasons.COMDATA_DOWN,
        [InvoiceDeclineReasons.COMCHEK_DOWN.key]: InvoiceDeclineReasons.COMCHEK_DOWN,
        [InvoiceDeclineReasons.COMCHEK_CREDIT_DEPARTMENT.key]: InvoiceDeclineReasons.COMCHEK_CREDIT_DEPARTMENT,
        [InvoiceDeclineReasons.EFS_DOWN.key]: InvoiceDeclineReasons.EFS_DOWN,
        [InvoiceDeclineReasons.TCHECK_DOWN.key]: InvoiceDeclineReasons.TCHECK_DOWN,
        [InvoiceDeclineReasons.FLEETONE_DOWN.key]: InvoiceDeclineReasons.FLEETONE_DOWN,
        [InvoiceDeclineReasons.INSTAMONEY_DOWN.key]: InvoiceDeclineReasons.INSTAMONEY_DOWN,
        [InvoiceDeclineReasons.USBANK_DOWN.key]: InvoiceDeclineReasons.USBANK_DOWN,
        [InvoiceDeclineReasons.SYSTEMS_DOWN.key]: InvoiceDeclineReasons.SYSTEMS_DOWN,
        [InvoiceDeclineReasons.TCHECK_NEEDS_EXPERATION.key]: InvoiceDeclineReasons.TCHECK_NEEDS_EXPERATION,
        [InvoiceDeclineReasons.FLEET_ONE_REGISTER_FUNDS.key]: InvoiceDeclineReasons.FLEET_ONE_REGISTER_FUNDS,
        [InvoiceDeclineReasons.ACH_TRANSFER_FAILURE.key]: InvoiceDeclineReasons.ACH_TRANSFER_FAILURE,
        [InvoiceDeclineReasons.CALL_PROCESSOR.key]: InvoiceDeclineReasons.CALL_PROCESSOR,
        [InvoiceDeclineReasons.WEX_REQUEST_FAILED.key]: InvoiceDeclineReasons.WEX_REQUEST_FAILED,
        [InvoiceDeclineReasons.WEX_API_OFFLINE.key]: InvoiceDeclineReasons.WEX_API_OFFLINE,
        [InvoiceDeclineReasons.WEX_ERROR.key]: InvoiceDeclineReasons.WEX_ERROR,
        [InvoiceDeclineReasons.WEX_INVALID_SITE_NUMBER.key]: InvoiceDeclineReasons.WEX_INVALID_SITE_NUMBER,
        [InvoiceDeclineReasons.WEX_INVALID_MESSAGE_TYPE.key]: InvoiceDeclineReasons.WEX_INVALID_MESSAGE_TYPE,
        [InvoiceDeclineReasons.WEX_INVALID_MONEY_CODE.key]: InvoiceDeclineReasons.WEX_INVALID_MONEY_CODE,
        [InvoiceDeclineReasons.WEX_CHECK_PREVIOUSLY_USED.key]: InvoiceDeclineReasons.WEX_CHECK_PREVIOUSLY_USED,
        [InvoiceDeclineReasons.WEX_MAX_AMOUNT_EXCEEDED.key]: InvoiceDeclineReasons.WEX_MAX_AMOUNT_EXCEEDED,
        [InvoiceDeclineReasons.WEX_MUST_BE_EXACT_AMOUNT.key]: InvoiceDeclineReasons.WEX_MUST_BE_EXACT_AMOUNT,
        [InvoiceDeclineReasons.WEX_UNKNOWN_ERROR_CODE.key]: InvoiceDeclineReasons.WEX_UNKNOWN_ERROR_CODE,
        [InvoiceDeclineReasons.WEX_CHECK_NUMBER_INVALID.key]: InvoiceDeclineReasons.WEX_CHECK_NUMBER_INVALID,
        [InvoiceDeclineReasons.WEX_INVALID_TERMINAL_ID.key]: InvoiceDeclineReasons.WEX_INVALID_TERMINAL_ID,
        [InvoiceDeclineReasons.WEX_EXPRESS_CODE_NOT_ON_FILE.key]: InvoiceDeclineReasons.WEX_EXPRESS_CODE_NOT_ON_FILE,
        [InvoiceDeclineReasons.WEX_AMOUNT_CODE_INVALID.key]: InvoiceDeclineReasons.WEX_AMOUNT_CODE_INVALID,
        [InvoiceDeclineReasons.WEX_INVALID_FUEL_CARD_NUMBER.key]: InvoiceDeclineReasons.WEX_INVALID_FUEL_CARD_NUMBER,
        [InvoiceDeclineReasons.WEX_INACTIVE_FUEL_CARD.key]: InvoiceDeclineReasons.WEX_INACTIVE_FUEL_CARD,
        [InvoiceDeclineReasons.WEX_INVALID_UNIT_NUMBER.key]: InvoiceDeclineReasons.WEX_INVALID_UNIT_NUMBER,
        [InvoiceDeclineReasons.WEX_INVALID_TRIP_NUMBER.key]: InvoiceDeclineReasons.WEX_INVALID_TRIP_NUMBER,
        [InvoiceDeclineReasons.WEX_INVALID_TRUCK_STOP.key]: InvoiceDeclineReasons.WEX_INVALID_TRUCK_STOP,
        [InvoiceDeclineReasons.WEX_INVALID_DRIVER_NUMBER.key]: InvoiceDeclineReasons.WEX_INVALID_DRIVER_NUMBER,
        [InvoiceDeclineReasons.WEX_INVALID_PRODUCT_CODE.key]: InvoiceDeclineReasons.WEX_INVALID_PRODUCT_CODE,
        [InvoiceDeclineReasons.WEX_INVALID_ODOMETER.key]: InvoiceDeclineReasons.WEX_INVALID_ODOMETER,
        [InvoiceDeclineReasons.WEX_INVALID_PO_NUMBER.key]: InvoiceDeclineReasons.WEX_INVALID_PO_NUMBER,
        [InvoiceDeclineReasons.WEX_INVALID_TRAILER_NUMBER.key]: InvoiceDeclineReasons.WEX_INVALID_TRAILER_NUMBER,
        [InvoiceDeclineReasons.INVALID_REQUEST_ERROR.key]: InvoiceDeclineReasons.INVALID_REQUEST_ERROR,
        [InvoiceDeclineReasons.MISSING_FIELDS_ERROR.key]: InvoiceDeclineReasons.MISSING_FIELDS_ERROR,
        [InvoiceDeclineReasons.UNKNOWN_ERROR_CODE_43.key]: InvoiceDeclineReasons.UNKNOWN_ERROR_CODE_43,
        [InvoiceDeclineReasons.UNKNOWN_ERROR_CODE_46.key]: InvoiceDeclineReasons.UNKNOWN_ERROR_CODE_46,
        [InvoiceDeclineReasons.UNEXPECTED_ERROR.key]: InvoiceDeclineReasons.UNEXPECTED_ERROR,
        [InvoiceDeclineReasons.CARD_DECLINED.key]: InvoiceDeclineReasons.CARD_DECLINED,
        [InvoiceDeclineReasons.NMI_REQUEST_DECLINED.key]: InvoiceDeclineReasons.NMI_REQUEST_DECLINED,
        [InvoiceDeclineReasons.NON_US_ISSUER_DOES_NOT_PARTICIPATE.key]: InvoiceDeclineReasons.NON_US_ISSUER_DOES_NOT_PARTICIPATE,
        [InvoiceDeclineReasons.ISSUER_SYSTEM_UNAVAILABLE.key]: InvoiceDeclineReasons.ISSUER_SYSTEM_UNAVAILABLE,
        [InvoiceDeclineReasons.NOT_A_MAIL_PHONE_ORDER.key]: InvoiceDeclineReasons.NOT_A_MAIL_PHONE_ORDER,
        [InvoiceDeclineReasons.SERVICE_NOT_SUPPORTED.key]: InvoiceDeclineReasons.SERVICE_NOT_SUPPORTED,
        [InvoiceDeclineReasons.AVS_NOT_AVAILABLE.key]: InvoiceDeclineReasons.AVS_NOT_AVAILABLE,
        [InvoiceDeclineReasons.CVV2_CVC2_NO_MATCH.key]: InvoiceDeclineReasons.CVV2_CVC2_NO_MATCH,
        [InvoiceDeclineReasons.NOT_PROCESSED.key]: InvoiceDeclineReasons.NOT_PROCESSED,
        [InvoiceDeclineReasons.MERCHANT_HAS_INDICATED_THAT_CVV2_IS_NOT_PRESENT.key]:
            InvoiceDeclineReasons.MERCHANT_HAS_INDICATED_THAT_CVV2_IS_NOT_PRESENT,
        [InvoiceDeclineReasons.ISSUER_IS_NOT_CERTIFIED_AND_OR_HAS_NOT_PROVIDED_VISA_ENCRYPTION_KEYS.key]:
            InvoiceDeclineReasons.ISSUER_IS_NOT_CERTIFIED_AND_OR_HAS_NOT_PROVIDED_VISA_ENCRYPTION_KEYS,
        [InvoiceDeclineReasons.TRANSACTION_WAS_DECLINED_BY_PROCESSOR.key]: InvoiceDeclineReasons.TRANSACTION_WAS_DECLINED_BY_PROCESSOR,
        [InvoiceDeclineReasons.DO_NOT_HONOR.key]: InvoiceDeclineReasons.DO_NOT_HONOR,
        [InvoiceDeclineReasons.INSUFFICIENT_FUNDS.key]: InvoiceDeclineReasons.INSUFFICIENT_FUNDS,
        [InvoiceDeclineReasons.OVER_LIMIT.key]: InvoiceDeclineReasons.OVER_LIMIT,
        [InvoiceDeclineReasons.TRANSACTION_NOT_ALLOWED.key]: InvoiceDeclineReasons.TRANSACTION_NOT_ALLOWED,
        [InvoiceDeclineReasons.INCORRECT_PAYMENT_INFORMATION.key]: InvoiceDeclineReasons.INCORRECT_PAYMENT_INFORMATION,
        [InvoiceDeclineReasons.NO_SUCH_CARD_ISSUER.key]: InvoiceDeclineReasons.NO_SUCH_CARD_ISSUER,
        [InvoiceDeclineReasons.NO_CARD_NUMBER_ON_FILE_WITH_ISSUER.key]: InvoiceDeclineReasons.NO_CARD_NUMBER_ON_FILE_WITH_ISSUER,
        [InvoiceDeclineReasons.EXPIRED_CARD.key]: InvoiceDeclineReasons.EXPIRED_CARD,
        [InvoiceDeclineReasons.INVALID_EXPIRATION_DATE.key]: InvoiceDeclineReasons.INVALID_EXPIRATION_DATE,
        [InvoiceDeclineReasons.INVALID_CARD_SECURITY_CODE.key]: InvoiceDeclineReasons.INVALID_CARD_SECURITY_CODE,
        [InvoiceDeclineReasons.INVALID_PIN.key]: InvoiceDeclineReasons.INVALID_PIN,
        [InvoiceDeclineReasons.CALL_ISSUER_FOR_FURTHER_INFORMATION.key]: InvoiceDeclineReasons.CALL_ISSUER_FOR_FURTHER_INFORMATION,
        [InvoiceDeclineReasons.PICK_UP_CARD.key]: InvoiceDeclineReasons.PICK_UP_CARD,
        [InvoiceDeclineReasons.LOST_CARD.key]: InvoiceDeclineReasons.LOST_CARD,
        [InvoiceDeclineReasons.STOLEN_CARD.key]: InvoiceDeclineReasons.STOLEN_CARD,
        [InvoiceDeclineReasons.FRAUDULENT_CARD.key]: InvoiceDeclineReasons.FRAUDULENT_CARD,
        [InvoiceDeclineReasons.DECLINED_WITH_FURTHER_INSTRUCTIONS_AVAILABLE.key]: InvoiceDeclineReasons.DECLINED_WITH_FURTHER_INSTRUCTIONS_AVAILABLE,
        [InvoiceDeclineReasons.DECLINED_STOP_ALL_RECURRING_PAYMENTS.key]: InvoiceDeclineReasons.DECLINED_STOP_ALL_RECURRING_PAYMENTS,
        [InvoiceDeclineReasons.DECLINED_STOP_THIS_RECURRING_PROGRAM.key]: InvoiceDeclineReasons.DECLINED_STOP_THIS_RECURRING_PROGRAM,
        [InvoiceDeclineReasons.DECLINED_UPDATE_CARDHOLDER_DATA_AVAILABLE.key]: InvoiceDeclineReasons.DECLINED_UPDATE_CARDHOLDER_DATA_AVAILABLE,
        [InvoiceDeclineReasons.DECLINED_RETRY_IN_A_FEW_DAYS.key]: InvoiceDeclineReasons.DECLINED_RETRY_IN_A_FEW_DAYS,
        [InvoiceDeclineReasons.TRANSACTION_WAS_REJECTED_BY_GATEWAY.key]: InvoiceDeclineReasons.TRANSACTION_WAS_REJECTED_BY_GATEWAY,
        [InvoiceDeclineReasons.TRANSACTION_ERROR_RETURNED_BY_PROCESSOR.key]: InvoiceDeclineReasons.TRANSACTION_ERROR_RETURNED_BY_PROCESSOR,
        [InvoiceDeclineReasons.INVALID_MERCHANT_CONFIGURATION.key]: InvoiceDeclineReasons.INVALID_MERCHANT_CONFIGURATION,
        [InvoiceDeclineReasons.MERCHANT_ACCOUNT_IS_INACTIVE.key]: InvoiceDeclineReasons.MERCHANT_ACCOUNT_IS_INACTIVE,
        [InvoiceDeclineReasons.COMMUNICATION_ERROR.key]: InvoiceDeclineReasons.COMMUNICATION_ERROR,
        [InvoiceDeclineReasons.COMMUNICATION_ERROR_WITH_ISSUER.key]: InvoiceDeclineReasons.COMMUNICATION_ERROR_WITH_ISSUER,
        [InvoiceDeclineReasons.DUPLICATE_TRANSACTION_AT_PROCESSOR.key]: InvoiceDeclineReasons.DUPLICATE_TRANSACTION_AT_PROCESSOR,
        [InvoiceDeclineReasons.PROCESSOR_FORMAT_ERROR.key]: InvoiceDeclineReasons.PROCESSOR_FORMAT_ERROR,
        [InvoiceDeclineReasons.INVALID_TRANSACTION_INFORMATION.key]: InvoiceDeclineReasons.INVALID_TRANSACTION_INFORMATION,
        [InvoiceDeclineReasons.PROCESSOR_FEATURE_NOT_AVAILABLE.key]: InvoiceDeclineReasons.PROCESSOR_FEATURE_NOT_AVAILABLE,
        [InvoiceDeclineReasons.UNSUPPORTED_CARD_TYPE.key]: InvoiceDeclineReasons.UNSUPPORTED_CARD_TYPE,
        [InvoiceDeclineReasons.AMOUNT_EXCEED_MAXIMUM.key]: InvoiceDeclineReasons.AMOUNT_EXCEED_MAXIMUM,
        [InvoiceDeclineReasons.COMDATA_CARD_INVALID_LOCATION.key]: InvoiceDeclineReasons.COMDATA_CARD_INVALID_LOCATION,
        [InvoiceDeclineReasons.COMDATA_MAXIMUM_DRAW_LIMIT.key]: InvoiceDeclineReasons.COMDATA_MAXIMUM_DRAW_LIMIT,
        [InvoiceDeclineReasons.COMDATA_OVER_PO_LIMIT.key]: InvoiceDeclineReasons.COMDATA_OVER_PO_LIMIT,
        [InvoiceDeclineReasons.COMDATA_PO_INVALID_THIS_CARD.key]: InvoiceDeclineReasons.COMDATA_PO_INVALID_THIS_CARD,
        [InvoiceDeclineReasons.COMDATA_PO_INVALID.key]: InvoiceDeclineReasons.COMDATA_PO_INVALID,
        [InvoiceDeclineReasons.COMDATA_DUPLICATE_PO.key]: InvoiceDeclineReasons.COMDATA_DUPLICATE_PO,
        [InvoiceDeclineReasons.COMDATA_INVALID_NAME.key]: InvoiceDeclineReasons.COMDATA_INVALID_NAME,
        [InvoiceDeclineReasons.COMDATA_TRAILER_NUMBER_INVALID.key]: InvoiceDeclineReasons.COMDATA_TRAILER_NUMBER_INVALID,
        [InvoiceDeclineReasons.COMDATA_TRAILER_HUB_INVALID.key]: InvoiceDeclineReasons.COMDATA_TRAILER_HUB_INVALID,
        [InvoiceDeclineReasons.COMDATA_TRAILER_HOURS_INVALID.key]: InvoiceDeclineReasons.COMDATA_TRAILER_HOURS_INVALID,
        [InvoiceDeclineReasons.COMDATA_DL_STATE_INVALID.key]: InvoiceDeclineReasons.COMDATA_DL_STATE_INVALID,
        [InvoiceDeclineReasons.COMDATA_DL_NUMBER_INVALID.key]: InvoiceDeclineReasons.COMDATA_DL_NUMBER_INVALID,
        [InvoiceDeclineReasons.COMDATA_NAME_REQUIRED.key]: InvoiceDeclineReasons.COMDATA_NAME_REQUIRED,
        [InvoiceDeclineReasons.COMDATA_TRY_CHECK_AUTH.key]: InvoiceDeclineReasons.COMDATA_TRY_CHECK_AUTH,
        [InvoiceDeclineReasons.COMDATA_EXPRESS_INACTIVE.key]: InvoiceDeclineReasons.COMDATA_EXPRESS_INACTIVE,
        [InvoiceDeclineReasons.COMDATA_TRAILER_HOURS_REQUIRED.key]: InvoiceDeclineReasons.COMDATA_TRAILER_HOURS_REQUIRED,
        [InvoiceDeclineReasons.COMDATA_TRACTOR_HUB_REQUIRED.key]: InvoiceDeclineReasons.COMDATA_TRACTOR_HUB_REQUIRED,
        [InvoiceDeclineReasons.COMDATA_TRAILER_HUB_REQUIRED.key]: InvoiceDeclineReasons.COMDATA_TRAILER_HUB_REQUIRED,
        [InvoiceDeclineReasons.COMDATA_DL_STATE_REQUIRED.key]: InvoiceDeclineReasons.COMDATA_DL_STATE_REQUIRED,
        [InvoiceDeclineReasons.COMDATA_DL_NUMBER_REQUIRED.key]: InvoiceDeclineReasons.COMDATA_DL_NUMBER_REQUIRED,
        [InvoiceDeclineReasons.COMDATA_TRAILER_NUMBER_REQUIRED.key]: InvoiceDeclineReasons.COMDATA_TRAILER_NUMBER_REQUIRED,
        [InvoiceDeclineReasons.COMDATA_PO_REQUIRED.key]: InvoiceDeclineReasons.COMDATA_PO_REQUIRED,
        [InvoiceDeclineReasons.COMDATA_TRIP_NUMBER_REQUIRED.key]: InvoiceDeclineReasons.COMDATA_TRIP_NUMBER_REQUIRED,
        [InvoiceDeclineReasons.COMDATA_UNIT_NUMBER_REQUIRED.key]: InvoiceDeclineReasons.COMDATA_UNIT_NUMBER_REQUIRED,
        [InvoiceDeclineReasons.COMDATA_CARD_NUMBER_REQUIRED.key]: InvoiceDeclineReasons.COMDATA_CARD_NUMBER_REQUIRED,
        [InvoiceDeclineReasons.COMDATA_DRIVER_NUMBER_REQUIRED.key]: InvoiceDeclineReasons.COMDATA_DRIVER_NUMBER_REQUIRED,
        [InvoiceDeclineReasons.COMDATA_CARD_NOT_ENABLED.key]: InvoiceDeclineReasons.COMDATA_CARD_NOT_ENABLED,
        [InvoiceDeclineReasons.COMDATA_INVALID_EXPIRY_DATE.key]: InvoiceDeclineReasons.COMDATA_INVALID_EXPIRY_DATE,
        [InvoiceDeclineReasons.ADDRESS_MATCH_ONLY.key]: InvoiceDeclineReasons.ADDRESS_MATCH_ONLY,
        [InvoiceDeclineReasons.ADDRESS_CUSTOMER_NAME_MATCH_ONLY.key]: InvoiceDeclineReasons.ADDRESS_CUSTOMER_NAME_MATCH_ONLY,
        [InvoiceDeclineReasons.NINE_CHARACTER_NUMERIC_ZIP_MATCH_ONLY.key]: InvoiceDeclineReasons.NINE_CHARACTER_NUMERIC_ZIP_MATCH_ONLY,
        [InvoiceDeclineReasons.FIVE_CHARACTER_ZIP_MATCH_ONLY.key]: InvoiceDeclineReasons.FIVE_CHARACTER_ZIP_MATCH_ONLY,
        [InvoiceDeclineReasons.FIVE_CHARACTER_ZIP_CUSTOMER_NAME_MATCH_ONLY.key]: InvoiceDeclineReasons.FIVE_CHARACTER_ZIP_CUSTOMER_NAME_MATCH_ONLY,
        [InvoiceDeclineReasons.NO_ADDRESS_OR_ZIP_MATCH_ONLY.key]: InvoiceDeclineReasons.NO_ADDRESS_OR_ZIP_MATCH_ONLY,
        [InvoiceDeclineReasons.NO_ADDRESS_OR_ZIP_OR_CUSTOMER_NAME_MATCH_ONLY.key]:
            InvoiceDeclineReasons.NO_ADDRESS_OR_ZIP_OR_CUSTOMER_NAME_MATCH_ONLY,
        [InvoiceDeclineReasons.ADDRESS_UNAVAILABLE.key]: InvoiceDeclineReasons.ADDRESS_UNAVAILABLE,
        [InvoiceDeclineReasons.TRY_AGAIN.key]: InvoiceDeclineReasons.TRY_AGAIN,
        [InvoiceDeclineReasons.UNKNOWN_ERROR.key]: InvoiceDeclineReasons.UNKNOWN_ERROR,
        [InvoiceDeclineReasons.GENERIC_DECLINE.key]: InvoiceDeclineReasons.GENERIC_DECLINE,
        [InvoiceDeclineReasons.FRAUDULENT_CARD_DECLINE.key]: InvoiceDeclineReasons.FRAUDULENT_CARD_DECLINE,
        [InvoiceDeclineReasons.LOST_CARD_DECLINE.key]: InvoiceDeclineReasons.LOST_CARD_DECLINE,
        [InvoiceDeclineReasons.MERCHANT_BLACKLIST.key]: InvoiceDeclineReasons.MERCHANT_BLACKLIST,
        [InvoiceDeclineReasons.STOLEN_CARD_DECLINE.key]: InvoiceDeclineReasons.STOLEN_CARD_DECLINE,
        [InvoiceDeclineReasons.CALL_ISSUER_DECLINE.key]: InvoiceDeclineReasons.CALL_ISSUER_DECLINE,
        [InvoiceDeclineReasons.CARD_NOT_SUPPORTED.key]: InvoiceDeclineReasons.CARD_NOT_SUPPORTED,
        [InvoiceDeclineReasons.CARD_VELOCITY_EXCEEDED.key]: InvoiceDeclineReasons.CARD_VELOCITY_EXCEEDED,
        [InvoiceDeclineReasons.CURRENCY_NOT_SUPPORTED.key]: InvoiceDeclineReasons.CURRENCY_NOT_SUPPORTED,
        [InvoiceDeclineReasons.DO_NOT_HONOR_DECLINE.key]: InvoiceDeclineReasons.DO_NOT_HONOR_DECLINE,
        [InvoiceDeclineReasons.DO_NOT_TRY_AGAIN.key]: InvoiceDeclineReasons.DO_NOT_TRY_AGAIN,
        [InvoiceDeclineReasons.INVALID_ACCOUNT.key]: InvoiceDeclineReasons.INVALID_ACCOUNT,
        [InvoiceDeclineReasons.INVALID_AMOUNT.key]: InvoiceDeclineReasons.INVALID_AMOUNT,
        [InvoiceDeclineReasons.ISSUER_NOT_AVAILABLE.key]: InvoiceDeclineReasons.ISSUER_NOT_AVAILABLE,
        [InvoiceDeclineReasons.NEW_ACCOUNT_INFORMATION_AVAILABLE.key]: InvoiceDeclineReasons.NEW_ACCOUNT_INFORMATION_AVAILABLE,
        [InvoiceDeclineReasons.NO_ACTION_TAKEN.key]: InvoiceDeclineReasons.NO_ACTION_TAKEN,
        [InvoiceDeclineReasons.NOT_PERMITTED.key]: InvoiceDeclineReasons.NOT_PERMITTED,
        [InvoiceDeclineReasons.OFFLINE_PIN_REQUIRED.key]: InvoiceDeclineReasons.OFFLINE_PIN_REQUIRED,
        [InvoiceDeclineReasons.PICKUP_CARD_DECLINE.key]: InvoiceDeclineReasons.PICKUP_CARD_DECLINE,
        [InvoiceDeclineReasons.REENTER_TRANSACTION.key]: InvoiceDeclineReasons.REENTER_TRANSACTION,
        [InvoiceDeclineReasons.RESTRICTED_CARD.key]: InvoiceDeclineReasons.RESTRICTED_CARD,
        [InvoiceDeclineReasons.REVOCATION_OF_ALL_AUTHORIZATIONS.key]: InvoiceDeclineReasons.REVOCATION_OF_ALL_AUTHORIZATIONS,
        [InvoiceDeclineReasons.REVOCATION_OF_AUTHORIZATION.key]: InvoiceDeclineReasons.REVOCATION_OF_AUTHORIZATION,
        [InvoiceDeclineReasons.SECURITY_VIOLATION.key]: InvoiceDeclineReasons.SECURITY_VIOLATION,
        [InvoiceDeclineReasons.SERVICE_NOT_ALLOWED.key]: InvoiceDeclineReasons.SERVICE_NOT_ALLOWED,
        [InvoiceDeclineReasons.STOP_PAYMENT_ORDER.key]: InvoiceDeclineReasons.STOP_PAYMENT_ORDER,
        [InvoiceDeclineReasons.TRANSACTION_NOT_ALLOWED_DECLINE.key]: InvoiceDeclineReasons.TRANSACTION_NOT_ALLOWED_DECLINE,
        [InvoiceDeclineReasons.TRY_AGAIN_LATER.key]: InvoiceDeclineReasons.TRY_AGAIN_LATER,
        [InvoiceDeclineReasons.TESTMODE_DECLINE.key]: InvoiceDeclineReasons.TESTMODE_DECLINE,
        [InvoiceDeclineReasons.INCORRECT_NUMBER.key]: InvoiceDeclineReasons.INCORRECT_NUMBER,
        [InvoiceDeclineReasons.INVALID_NUMBER.key]: InvoiceDeclineReasons.INVALID_NUMBER,
        [InvoiceDeclineReasons.INCORRECT_CVC.key]: InvoiceDeclineReasons.INCORRECT_CVC,
        [InvoiceDeclineReasons.INVALID_CVC.key]: InvoiceDeclineReasons.INVALID_CVC,
        [InvoiceDeclineReasons.INSUFFICIENT_FUNDS_DECLINE.key]: InvoiceDeclineReasons.INSUFFICIENT_FUNDS_DECLINE,
        [InvoiceDeclineReasons.INVALID_EXPIRY_MONTH.key]: InvoiceDeclineReasons.INVALID_EXPIRY_MONTH,
        [InvoiceDeclineReasons.INVALID_EXPIRY_YEAR.key]: InvoiceDeclineReasons.INVALID_EXPIRY_YEAR,
        [InvoiceDeclineReasons.INCORRECT_PIN.key]: InvoiceDeclineReasons.INCORRECT_PIN,
        [InvoiceDeclineReasons.INVALID_PIN_DECLINE.key]: InvoiceDeclineReasons.INVALID_PIN_DECLINE,
        [InvoiceDeclineReasons.DUPLICATE_TRANSACTION.key]: InvoiceDeclineReasons.DUPLICATE_TRANSACTION,
        [InvoiceDeclineReasons.AUTHENTICATION_REQUIRED.key]: InvoiceDeclineReasons.AUTHENTICATION_REQUIRED,
        [InvoiceDeclineReasons.APPROVE_WITH_ID.key]: InvoiceDeclineReasons.APPROVE_WITH_ID,
        [InvoiceDeclineReasons.EXPIRED_CARD_DECLINE.key]: InvoiceDeclineReasons.EXPIRED_CARD_DECLINE,
        [InvoiceDeclineReasons.PIN_TRY_EXCEEDED.key]: InvoiceDeclineReasons.PIN_TRY_EXCEEDED,
        [InvoiceDeclineReasons.WITHDRAWAL_COUNT_LIMIT_EXCEEDED.key]: InvoiceDeclineReasons.WITHDRAWAL_COUNT_LIMIT_EXCEEDED,
        [InvoiceDeclineReasons.PROCESSING_ERROR.key]: InvoiceDeclineReasons.PROCESSING_ERROR,
        [InvoiceDeclineReasons.INCORRECT_ZIP.key]: InvoiceDeclineReasons.INCORRECT_ZIP,
        [InvoiceDeclineReasons.CARD_DECLINED_RATE_LIMIT_EXCEEDED.key]: InvoiceDeclineReasons.CARD_DECLINED_RATE_LIMIT_EXCEEDED,
        [InvoiceDeclineReasons.ACH_INSUFFICIENT_FUNDS.key]: InvoiceDeclineReasons.ACH_INSUFFICIENT_FUNDS,
        [InvoiceDeclineReasons.ACH_BANK_ACCOUNT_CLOSED.key]: InvoiceDeclineReasons.ACH_BANK_ACCOUNT_CLOSED,
        [InvoiceDeclineReasons.ACH_BANK_ACCOUNT_NOT_FOUND.key]: InvoiceDeclineReasons.ACH_BANK_ACCOUNT_NOT_FOUND,
        [InvoiceDeclineReasons.ACH_BANK_ACCOUNT_INVALID.key]: InvoiceDeclineReasons.ACH_BANK_ACCOUNT_INVALID,
        [InvoiceDeclineReasons.ACH_DEBIT_UNAUTHORIZED.key]: InvoiceDeclineReasons.ACH_DEBIT_UNAUTHORIZED,
        [InvoiceDeclineReasons.ACH_AUTHORIZATION_REVOKED.key]: InvoiceDeclineReasons.ACH_AUTHORIZATION_REVOKED,
        [InvoiceDeclineReasons.ACH_PAYMENT_STOPPED.key]: InvoiceDeclineReasons.ACH_PAYMENT_STOPPED,
        [InvoiceDeclineReasons.ACH_UNCOLLECTED_FUNDS.key]: InvoiceDeclineReasons.ACH_UNCOLLECTED_FUNDS,
        [InvoiceDeclineReasons.ACH_CUSTOMER_ADVICE_ENTRY_NOT_ACCORDANCE.key]: InvoiceDeclineReasons.ACH_CUSTOMER_ADVICE_ENTRY_NOT_ACCORDANCE,
        [InvoiceDeclineReasons.ACH_BANK_ACCOUNT_FROZEN.key]: InvoiceDeclineReasons.ACH_BANK_ACCOUNT_FROZEN,
        [InvoiceDeclineReasons.ACH_NON_TRANSACTION_ACCOUNT.key]: InvoiceDeclineReasons.ACH_NON_TRANSACTION_ACCOUNT,
        [InvoiceDeclineReasons.ACH_CUSTOMER_ADVICE_NOT_AUTHORIZED.key]: InvoiceDeclineReasons.ACH_CUSTOMER_ADVICE_NOT_AUTHORIZED,
        [InvoiceDeclineReasons.RAINFOREST_GENERIC_DECLINE.key]: InvoiceDeclineReasons.RAINFOREST_GENERIC_DECLINE,
        [InvoiceDeclineReasons.RAINFOREST_DECLINED.key]: InvoiceDeclineReasons.RAINFOREST_DECLINED,
        [InvoiceDeclineReasons.RAINFOREST_DO_NOT_HONOR.key]: InvoiceDeclineReasons.RAINFOREST_DO_NOT_HONOR,
        [InvoiceDeclineReasons.RAINFOREST_EXPIRED_CARD.key]: InvoiceDeclineReasons.RAINFOREST_EXPIRED_CARD,
        [InvoiceDeclineReasons.RAINFOREST_FRAUDULENT_CARD.key]: InvoiceDeclineReasons.RAINFOREST_FRAUDULENT_CARD,
        [InvoiceDeclineReasons.RAINFOREST_INCORRECT_PAYMENT_INFORMATION.key]: InvoiceDeclineReasons.RAINFOREST_INCORRECT_PAYMENT_INFORMATION,
        [InvoiceDeclineReasons.RAINFOREST_INSUFFICIENT_FUNDS.key]: InvoiceDeclineReasons.RAINFOREST_INSUFFICIENT_FUNDS,
        [InvoiceDeclineReasons.RAINFOREST_INVALID_CARD_SECURITY_CODE.key]: InvoiceDeclineReasons.RAINFOREST_INVALID_CARD_SECURITY_CODE,
        [InvoiceDeclineReasons.RAINFOREST_INVALID_EXPIRATION_DATE.key]: InvoiceDeclineReasons.RAINFOREST_INVALID_EXPIRATION_DATE,
        [InvoiceDeclineReasons.RAINFOREST_INVALID_PIN.key]: InvoiceDeclineReasons.RAINFOREST_INVALID_PIN,
        [InvoiceDeclineReasons.RAINFOREST_LOST_CARD.key]: InvoiceDeclineReasons.RAINFOREST_LOST_CARD,
        [InvoiceDeclineReasons.RAINFOREST_NO_CARD_NUMBER_ON_FILE.key]: InvoiceDeclineReasons.RAINFOREST_NO_CARD_NUMBER_ON_FILE,
        [InvoiceDeclineReasons.RAINFOREST_NO_SUCH_CARD_ISSUER.key]: InvoiceDeclineReasons.RAINFOREST_NO_SUCH_CARD_ISSUER,
        [InvoiceDeclineReasons.RAINFOREST_OVER_LIMIT.key]: InvoiceDeclineReasons.RAINFOREST_OVER_LIMIT,
        [InvoiceDeclineReasons.RAINFOREST_PROCESSING_ERROR.key]: InvoiceDeclineReasons.RAINFOREST_PROCESSING_ERROR,
        [InvoiceDeclineReasons.RAINFOREST_RISK_DECLINE.key]: InvoiceDeclineReasons.RAINFOREST_RISK_DECLINE,
        [InvoiceDeclineReasons.RAINFOREST_STOLEN_CARD.key]: InvoiceDeclineReasons.RAINFOREST_STOLEN_CARD,
        [InvoiceDeclineReasons.RAINFOREST_UNEXPECTED_PAYIN_TOKEN.key]: InvoiceDeclineReasons.RAINFOREST_UNEXPECTED_PAYIN_TOKEN,
        [InvoiceDeclineReasons.RAINFOREST_UNSUPPORTED_CARD_TYPE.key]: InvoiceDeclineReasons.RAINFOREST_UNSUPPORTED_CARD_TYPE,
        [InvoiceDeclineReasons.RAINFOREST_PAYMENT_STILL_PROCESSING.key]: InvoiceDeclineReasons.RAINFOREST_PAYMENT_STILL_PROCESSING,
    };
    static getTypes(): RejectionType[] {
        return _.values(InvoiceDeclineReasons.LOOKUP);
    }
    static getByKey(key: string): RejectionType {
        return InvoiceDeclineReasons.LOOKUP[key];
    }
    static getReasonsByCheckType(checkType): RejectionType[] {
        const allReasons = InvoiceDeclineReasons.getTypes();
        return allReasons.filter((reason) => reason.checkProviders.indexOf(checkType) !== -1);
    }

    static getRainforestRefusalReason(refusalCode: string): RejectionType {
        switch (refusalCode) {
            case "DECLINED":
                return InvoiceDeclineReasons.RAINFOREST_DECLINED;
            case "DO_NOT_HONOR":
                return InvoiceDeclineReasons.RAINFOREST_DO_NOT_HONOR;
            case "EXPIRED_CARD":
                return InvoiceDeclineReasons.RAINFOREST_EXPIRED_CARD;
            case "FRAUDULENT_CARD":
                return InvoiceDeclineReasons.RAINFOREST_FRAUDULENT_CARD;
            case "INCORRECT_PAYMENT_INFORMATION":
                return InvoiceDeclineReasons.RAINFOREST_INCORRECT_PAYMENT_INFORMATION;
            case "INSUFFICIENT_FUNDS":
                return InvoiceDeclineReasons.RAINFOREST_INSUFFICIENT_FUNDS;
            case "INVALID_CARD_SECURITY_CODE":
                return InvoiceDeclineReasons.RAINFOREST_INVALID_CARD_SECURITY_CODE;
            case "INVALID_EXPIRATION_DATE":
                return InvoiceDeclineReasons.RAINFOREST_INVALID_EXPIRATION_DATE;
            case "INVALID_PIN":
                return InvoiceDeclineReasons.RAINFOREST_INVALID_PIN;
            case "LOST_CARD":
                return InvoiceDeclineReasons.RAINFOREST_LOST_CARD;
            case "NO_CARD_NUMBER_ON_FILE_WITH_ISSUER":
                return InvoiceDeclineReasons.RAINFOREST_NO_CARD_NUMBER_ON_FILE;
            case "NO_SUCH_CARD_ISSUER":
                return InvoiceDeclineReasons.RAINFOREST_NO_SUCH_CARD_ISSUER;
            case "OVER_LIMIT":
                return InvoiceDeclineReasons.RAINFOREST_OVER_LIMIT;
            case "PROCESSING_ERROR":
                return InvoiceDeclineReasons.RAINFOREST_PROCESSING_ERROR;
            case "RAINFOREST_RISK_DECLINE":
                return InvoiceDeclineReasons.RAINFOREST_RISK_DECLINE;
            case "STOLEN_CARD":
                return InvoiceDeclineReasons.RAINFOREST_STOLEN_CARD;
            case "UNEXPECTED_PAYIN_TOKEN":
                return InvoiceDeclineReasons.RAINFOREST_UNEXPECTED_PAYIN_TOKEN;
            case "UNSUPPORTED_CARD_TYPE":
                return InvoiceDeclineReasons.RAINFOREST_UNSUPPORTED_CARD_TYPE;
            default:
                return InvoiceDeclineReasons.RAINFOREST_GENERIC_DECLINE;
        }
    }

    static getComdataExpressCodeErrorMessage(comdataErrorCode?: string): string | undefined {
        switch (comdataErrorCode) {
            case "00400":
                return "Amount exceeds the code's balance, Comdata could not process the payment.";
            case "00369":
            case "00050":
            case "00395":
            case "00367":
                return "Express code is incorrect, Comdata could not process the payment.";
            case "00031":
            case "00404":
            case "00446":
                return "Payment failed because Trip Number is invalid. Please ask the Payer to double check this information and try again.";
            case "00051":
            case "00223":
            case "00282":
            case "00293":
            case "00378":
            case "00642":
                return "Payment failed because Unit Number is invalid. Please ask the Payer to double check this information and try again.";
            case "00402":
            case "01034":
            case "01033":
            case "01021":
            case "00996":
                return "Payment failed because Driver Number is invalid. Please ask the Payer to double check this information and try again.";
            case "00640":
            case "00815":
            case "00816":
            case "00834":
            case "00945":
            case "00865":
            case "00866":
            case "00867":
            case "00868":
            case "00869":
            case "999963":
            case "01047":
            case "01106":
            case "00987":
            case "00847":
            case "00848":
            case "00849":
            case "00664":
                return "Payment failed because Card Number is invalid. Please ask the Payer to double check this information and try again.";
            case "00749":
            case "00633":
            case "00732":
                return "Payment failed because Card Number has expired, please ask the Payer to provide a valid one.";
            case "00015":
                return "Please try again later, Comdata could not process the payment.";
            case "00050 ":
                return "Please ask the Payer to contact their company, Comdata could not process the payment.";
            case "00075":
                return "Please ask the Payer to contact their company, Comdata could not process the payment.";
            case "00373":
                return "Amount exceeds the maximum allowed, Comdata could not process the payment.";
            case "00380":
                return "Payer Name is required, please provide it and try again.";
            case "00391":
                return "Express code is incorrect, Comdata could not process the payment.";
            case "00394":
                return "Charges exceed the available amount, Comdata could not process the payment.";
            case "00396":
                return "The express code has already been issued, Comdata could not process the payment.";
            case "00397":
                return "Express code is incorrect, Comdata could not process the payment.";
            case "00401":
                return "Express Code is required, please provide it and try again.";
            case "00405":
            case "00406":
                return "Name is invalid, Comdata could not process the payment.";
            case "00409":
                return "Please use the full amount of the Express code, Comdata could not process the payment.";
            case "00464":
                return "The location is not active, please contact RoadsSync's support.";
            case "01295":
                return "Amount exceeds the maximum allowed, Comdata could not process the payment.";
            case "99999":
                return "Please contact Comdata, the payment could not be processed.";
            default:
                return undefined;
        }
    }
}
