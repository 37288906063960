import _ from "lodash";

export interface Role {
    key: string;
    display: string;
    disabled?: boolean;
}

export class Roles {
    static readonly SUPER_ADMIN: Role = {
        key: "superadmin",
        display: "Super Admin",
    };

    static readonly COMPANY_ADMIN: Role = {
        key: "companyadmin",
        display: "Company Admin",
    };

    static readonly ACCOUNTANT: Role = {
        key: "accountant",
        display: "Accountant",
    };

    static readonly CLIENT_SUPPORT: Role = {
        key: "clientsupport",
        display: "Client Support",
    };

    static readonly EMPLOYEE: Role = {
        key: "employee",
        display: "Employee",
    };

    static readonly WAREHOUSE_WORKER: Role = {
        key: "warehouseworker",
        display: "Location Worker",
        disabled: true,
    };

    // Should Change to CSR
    static readonly RSEMPLOYEE: Role = {
        key: "rsemployee",
        display: "RS Employee",
    };

    static readonly RS_IMPLEMENTATION: Role = {
        key: "implementation",
        display: "RS Implementation",
    };

    static readonly RS_CHECK_OPS: Role = {
        key: "rscheckops",
        display: "RS Check Ops",
        disabled: true,
    };

    static readonly REPORTING: Role = {
        key: "reporting",
        display: "Reporting",
    };

    static readonly FINANCIAL_ADMIN: Role = {
        key: "financialadmin",
        display: "Financial Admin",
    };

    static readonly API_USER: Role = {
        key: "api",
        display: "API User",
    };

    private static readonly LOOKUP = {
        superadmin: Roles.SUPER_ADMIN,
        companyadmin: Roles.COMPANY_ADMIN,
        accountant: Roles.ACCOUNTANT,
        clientsupport: Roles.CLIENT_SUPPORT,
        employee: Roles.EMPLOYEE,
        warehouseworker: Roles.WAREHOUSE_WORKER,
        rsemployee: Roles.RSEMPLOYEE,
        implementation: Roles.RS_IMPLEMENTATION,
        rscheckops: Roles.RS_CHECK_OPS,
        reporting: Roles.REPORTING,
        financialadmin: Roles.FINANCIAL_ADMIN,
        api: Roles.API_USER,
    };

    static getRoles(): Role[] {
        return _.values(Roles.LOOKUP).filter((r) => !r.disabled);
    }

    static getByKey(key: string): Role {
        return Roles.LOOKUP[key];
    }
}

export const getRemainingRoles = (roles: Role[]): Role[] => Roles.getRoles().filter((r) => !roles.includes(r));

export const allowedCombosForValidation = {
    [Roles.COMPANY_ADMIN.key]: [Roles.COMPANY_ADMIN.key, Roles.ACCOUNTANT.key, Roles.FINANCIAL_ADMIN.key, Roles.API_USER.key],
    [Roles.ACCOUNTANT.key]: [Roles.COMPANY_ADMIN.key, Roles.ACCOUNTANT.key, Roles.FINANCIAL_ADMIN.key, Roles.API_USER.key],
    [Roles.FINANCIAL_ADMIN.key]: [Roles.COMPANY_ADMIN.key, Roles.ACCOUNTANT.key, Roles.FINANCIAL_ADMIN.key, Roles.API_USER.key],
    [Roles.EMPLOYEE.key]: [Roles.EMPLOYEE.key, Roles.CLIENT_SUPPORT.key, Roles.API_USER.key],
    [Roles.CLIENT_SUPPORT.key]: [Roles.EMPLOYEE.key, Roles.CLIENT_SUPPORT.key, Roles.API_USER.key],
    [Roles.RSEMPLOYEE.key]: [Roles.RSEMPLOYEE.key, Roles.RS_IMPLEMENTATION.key, Roles.API_USER.key],
    [Roles.RS_IMPLEMENTATION.key]: [Roles.RSEMPLOYEE.key, Roles.RS_IMPLEMENTATION.key, Roles.API_USER.key],
    [Roles.REPORTING.key]: [Roles.REPORTING.key, Roles.API_USER.key],
    [Roles.WAREHOUSE_WORKER.key]: [Roles.WAREHOUSE_WORKER.key, Roles.API_USER.key],
    [Roles.API_USER.key]: [
        Roles.API_USER.key,
        Roles.COMPANY_ADMIN.key,
        Roles.ACCOUNTANT.key,
        Roles.FINANCIAL_ADMIN.key,
        Roles.EMPLOYEE.key,
        Roles.CLIENT_SUPPORT.key,
        Roles.RSEMPLOYEE.key,
        Roles.RS_IMPLEMENTATION.key,
        Roles.REPORTING.key,
        Roles.WAREHOUSE_WORKER.key,
        Roles.RS_CHECK_OPS.key,
    ],
};

