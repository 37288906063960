import { makeURLSearchParams, URLSearchMap } from "../endpoints";

export class InvoiceEndpoints {
    static getAllInvoices = (params: URLSearchMap): string => `/api/v1/invoices?${makeURLSearchParams(params).toString()}`;

    static getInvoicesByLocation = (locationId: string, params: URLSearchMap): string =>
        `/api/v1/locations/${locationId}/invoices?${makeURLSearchParams(params).toString()}`;

    /**
     * @deprecated
     * limitted for certain roles. filter companyId won't work
     * supports isCompanyAdmin(me) || isClientSupport(me) || isAccountant(me)
     */
    static getInvoicesByCompany = (params: URLSearchMap): string => `/api/v1/invoices?${makeURLSearchParams(params).toString()}`;

    static getInvoice = (invoiceId: string): string => `/api/v1/invoices/${invoiceId}`;
    static getVoidedInvoice = (invoiceId: string): string => {
        return `/api/v1/invoices/${invoiceId}?includeregular=false&includevoided=true`;
    };

    //grab validated credit card info from invoice
    static getValidatedPaymentInfo = (invoiceId: string): string => `/api/v1/invoices/${invoiceId}/active-charge`;

    static getInvoicesByCompanyId = (companyId: string, params: URLSearchMap): string =>
        `/api/v1/companies/${companyId}/invoices?${makeURLSearchParams(params).toString()}`;
    static getInvoiceFromTowbook = (companyId: string, towbookCallNumber: string, towbookAccountId: string, towbookCompanyId: string): string =>
        `/api/v1/companies/${companyId}/towbook/accounts/${towbookAccountId}/call-number/${towbookCallNumber}?towbookCompanyId=${towbookCompanyId}`;

    static getTowbookCompanies = (companyId: string): string => `/api/v1/companies/${companyId}/towbook/accounts`;
    static bulkImportInvoices = (companyId: string): string => `/api/v1/companies/${companyId}/invoices/bulk`;
    static updateTowbookCompaniesStatus = (companyId: string, accountId: string, towbookCompanyId: string): string =>
        `/api/v1/companies/${companyId}/towbook/accounts/${accountId}/company/${towbookCompanyId}`;
    static getPdfUrlFromTowbook = (companyId: string, towbookCallNumber: string, towbookAccountId: string, towbookCompanyId: string): string =>
        `/api/v1/companies/${companyId}/towbook/accounts/${towbookAccountId}/call-number/${towbookCallNumber}/pdf?towbookCompanyId=${towbookCompanyId}`;

    static createInvoice = (companyId: string): string => `/api/v1/companies/${companyId}/invoices`;
    static updateInvoice = (invoiceId: string): string => `/api/v1/invoices/${invoiceId}`;
    static duplicateInvoice = (invoiceId: string, useStoredPaymentDetails: boolean): string =>
        `/api/v1/invoices/${invoiceId}/clone?usestoredpaymentdetails=${useStoredPaymentDetails}`;
    static updateInvoicePaidBy = (invoiceId: string): string => `/api/v1/invoices/${invoiceId}/paid_by`;
    static printInvoiceReceipt = (invoiceId: string): string => `/api/v1/invoices/${invoiceId}/receipt/generate`;
    static printInvoiceReceiptPublic = (invoiceToken: string): string => `/api/v1/public/invoices/${invoiceToken}/receipt/generate`;

    static deleteInvoice = (invoiceId: string): string => `/api/v1/invoices/${invoiceId}`;
    static refundInvoice = (): string => `/api/v1/deposits/card/refund/partial`;
    static generateInvoiceReceipt = (invoiceId: string): string => `/api/v1/invoices/${invoiceId}/receipt`;
    static sendInvoiceReceipt = (invoiceId: string): string => `/api/v1/invoices/${invoiceId}/send`;
    static getInvoiceReceipt = (invoiceId: string, isVoided = false): string => {
        return `/api/v1/invoices/${invoiceId}/receipt/url${isVoided ? "?includevoided=true" : ""}`;
    };
    static sendNotificationToPayer = (invoiceId: string): string => `/api/v1/invoices/${invoiceId}/notify/payer`;
    static addSignature = (invoiceId: string): string => `/api/v1/invoices/${invoiceId}/signature`;
    static addEmailReminder = (invoiceId: string): string => `/api/v1/invoices/${invoiceId}/reminder`;
    static sendInvoiceForCompletion = (invoiceId: string): string => `/api/v1/invoices/${invoiceId}/send`;
    static uploadInvoicePdfFile = (invoiceId: string, fileName: string): string => {
        const params = fileName ? `?attachmentname=${fileName}` : "";
        return `/api/v1/invoices/${invoiceId}/attach${params}`;
    };
    static removeInvoicePdfFile = (invoiceId: string): string => `/api/v1/invoices/${invoiceId}/attach`;
    static getFilesForInvoice = (invoiceId: string): string => `/api/v1/invoices/${invoiceId}/attachments`;
    static editApprovedInvoice = (invoiceId: string): string => `/api/v1/invoices/${invoiceId}/modify`;
    static getV1InvoiceById = (invoiceId: string): string => `/api/v1/v1/check-auth/${invoiceId}`;
    static deleteV1InvoiceById = (invoiceId: string): string => `/api/v1/v1/check-auth/${invoiceId}`;
    // eslint-disable-next-line max-params
    static getPaidInvoicesByCompany = (companyId: string, offset: number, limit: number, quickfilter: string): string => {
        const route = `/api/v1/companies/${companyId}/invoices/paid?offset=${offset}&limit=${limit}`;
        return quickfilter ? `${route}&search=${quickfilter}` : route;
    };
    // eslint-disable-next-line max-params
    static getOpenInvoicesByCompany = (companyId: string, offset: number, limit: number, quickfilter: string): string => {
        const route = `/api/v1/companies/${companyId}/invoices/unpaid?offset=${offset}&limit=${limit}`;
        return quickfilter ? `${route}&search=${quickfilter}` : route;
    };
    // eslint-disable-next-line max-params
    static getRefundedInvoicesByCompany = (companyId: string, offset: number, limit: number, quickfilter: string): string =>
        `${InvoiceEndpoints.getPaidInvoicesByCompany(companyId, offset, limit, quickfilter)}${InvoiceEndpoints.getRouteParamsRefundedByCompany(
            quickfilter
        )}`;

    private static getRouteParamsRefundedByCompany(invoiceListFilter?): string {
        const params = [""];

        if (invoiceListFilter) {
            params.push(`search=${invoiceListFilter}`);
        }

        params.push(`includevoided=true`);
        params.push(`includeregular=false`);

        return params.join("&");
    }
}
