import React, { useState, useEffect } from "react";
import { reduxForm, Field, InjectedFormProps, formValueSelector } from "redux-form";
import { connect, DispatchProp } from "react-redux";
import { required, workOrderText, emailOrPhoneNumber, floatBiggerThanZero } from "../../services/app/validators";
import { AuthorizationType } from "../../containers/workOrder/management/CreateWorkOrder";
import { Error } from "../ui/Error";
import { WorkOrderTemplate } from "../../types/WorkOrderTemplate";
import { getWorkOrderTemplate } from "../../actions/workOrderTemplates";
import { CustomFieldRenderHelper } from "../location/customFields/CustomFieldRenderHelper";
import { Shift } from "../../types/Shift";
import { Department } from "../../types/Department";
import { Location } from "../../types/Location";
import { Carrier } from "../../types/carrier";
import { GlobalState } from "../../types/GlobalState";
import { FormControlLabel, Radio } from "@material-ui/core";
import { P, Grid } from "@roadsync/roadsync-ui";
import { RenderTextField } from "../ui/form/RenderTextField";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import { RenderRadioGroup } from "../ui/form/RenderRadioGroup";
import WorkOrderTemplateSelectFieldMUI from "../location/WorkOrderTemplateSelectFieldMUI";
import { LargePrimaryButton } from "../ui/buttons/LargePrimaryButton";
import useStyles from "./WorkOrderEditForm.css";

export interface WorkOrderEditFormData {
    reference: string;
    signedCarrierName?: string;
    signedDriverName?: string;
    phoneOrEmail?: string;
    locationId?: string;
    workOrderSettingsId?: string;
    departmentId?: string;
    shiftId?: string;
    depositAmount?: string;
    [customFieldName: string]: string | undefined;
}

interface Props extends DispatchProp, OwnProps, PropsFromState { }

type PropsFromState = Pick<GlobalState, "workOrderTemplates" | "auth" | "companies" | "publicData"> &
    Pick<WorkOrderEditFormData, "locationId" | "workOrderSettingsId">;

interface OwnProps {
    carriersList: Carrier[];
    departments: Department[];
    loadCarriers: Function;
    locations: Location[];
    onSubmit: (values: WorkOrderEditFormData) => void;
    onLocationChange?: (e: React.FormEvent, newValue?: string, oldValue?: string) => void;
    onWorkOrderTemplateChange?: (t: WorkOrderTemplate) => void;
    shifts: Shift[];
    showLocationPicker?: boolean;
    showDepartmentPicker?: boolean;
    showShiftPicker?: boolean;
    vehicleReferenceLabel?: string;
    workOrderTemplateId?: string;
    setDefaultWorkOrderTemplate?: Function;
    title: string;
}

// eslint-disable-next-line max-lines-per-function,max-len
const WorkOrderEditForm: React.FC<Props & InjectedFormProps<WorkOrderEditFormData, Props>> = (
    props: Props & InjectedFormProps<WorkOrderEditFormData, Props>
) => {
    const {
        error,
        handleSubmit,
        onSubmit,
        submitting,
        valid,
        showLocationPicker,
        showDepartmentPicker,
        locations,
        carriersList,
        loadCarriers,
        departments,
        shifts,
        onLocationChange,
        showShiftPicker,
        locationId,
        workOrderSettingsId,
        workOrderTemplates,
        onWorkOrderTemplateChange,
        dispatch,
        setDefaultWorkOrderTemplate,
        title,
        initialValues,
    } = props;

    const classes = useStyles();

    const [workOrderTemplate, setWorkOrderTemplate] = useState<WorkOrderTemplate | undefined>(undefined);

    const loadWorkOrderTemplate = (id?: string): void => {
        if (!id) {
            setWorkOrderTemplate(undefined);
            return;
        }
        const template =
            workOrderTemplates?.data &&
            Object.keys(workOrderTemplates?.data)
                .map((l) => workOrderTemplates.data[l])
                .filter((templates) => Array.isArray(templates))
                .map((templates) => (templates as WorkOrderTemplate[]).filter((t) => t.id === id).shift()) //.map going to return [undefined,{}] (for example)
                .filter((t) => t !== undefined)[0]; //which we gonna filter here (without shift()) and pick first one

        if (onWorkOrderTemplateChange && template) {
            onWorkOrderTemplateChange(template);
        }
        setWorkOrderTemplate(template);

        if (!template && id) {
            dispatch<any>(getWorkOrderTemplate(id)).then(() => loadWorkOrderTemplate(id));
        }
    };

    if (loadCarriers && !carriersList?.length) {
        loadCarriers();
    }

    useEffect(() => {
        loadWorkOrderTemplate(workOrderSettingsId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workOrderSettingsId]);

    return (
        <form onSubmit={handleSubmit} data-testid="work-order-form">
            <Grid container direction="column" wrap="nowrap" spacing={2}>
                {error && <Error error={error} />}
                <Grid item>
                    <P variant="h4" gutterBottom>
                        {title}
                    </P>
                </Grid>
                <Grid item>
                    <Field
                        name="reference"
                        label="Work Order Reference"
                        placeholder="Enter a work order reference for record keeping purposes"
                        type="text"
                        component={RenderTextField}
                        validate={[required, workOrderText]}
                        required={true}
                    />
                </Grid>
                <Grid item>
                    <Autocomplete
                        id="carrier-list"
                        freeSolo
                        options={carriersList}
                        defaultValue={initialValues?.signedCarrierName || ""}
                        renderInput={(params): JSX.Element => (
                            <Field
                                name="signedCarrierName"
                                label={"Payer Name"}
                                placeholder="For internal identifying purposes"
                                component={RenderTextField}
                                validate={required}
                                required={true}
                                {...params}
                            />
                        )}
                    />
                </Grid>
                <Grid item>
                    <Field
                        name="signedDriverName"
                        label={"Driver Name (if different from payer)"}
                        placeholder="Enter name here"
                        type="text"
                        component={RenderTextField}
                        validate={workOrderText}
                    />
                </Grid>
                <Grid item>
                    <Field
                        name="phoneOrEmail"
                        label={"Payer Email or Phone Number"}
                        placeholder="Enter driver email or phone number"
                        type="text"
                        component={RenderTextField}
                        validate={emailOrPhoneNumber}
                    />
                </Grid>
                {showLocationPicker && (
                    <Grid item>
                        <Field
                            name="locationId"
                            label="Select a Location"
                            validate={required}
                            component={RenderRadioGroup}
                            onChange={onLocationChange}
                        >
                            {locations.map((location) => (
                                <FormControlLabel
                                    key={location.id}
                                    value={location.id}
                                    control={<Radio color="primary" data-public />}
                                    label={location.name}
                                />
                            ))}
                        </Field>
                    </Grid>
                )}
                <Grid item>
                    <P variant="body2" className={classes.depositAmount}>
                        Collect optional deposit before the service is started
                    </P>
                </Grid>
                <Grid item>
                    <Field
                        name="depositAmount"
                        label="Deposit Amount (optional)"
                        type="number"
                        validate={floatBiggerThanZero}
                        component={RenderTextField}
                    ></Field>
                </Grid>
                {locationId && workOrderTemplates?.data && (
                    <Grid item>
                        <WorkOrderTemplateSelectFieldMUI
                            locationId={locationId}
                            name="workOrderSettingsId"
                            label="Work Order Template"
                            onChange={(e): void => loadWorkOrderTemplate(e?.target?.value as any)}
                            setDefaultWorkOrderTemplate={setDefaultWorkOrderTemplate}
                        />
                    </Grid>
                )}
                {workOrderTemplate && (
                    <Grid item>
                        <CustomFieldRenderHelper template={workOrderTemplate} />
                    </Grid>
                )}
                {showDepartmentPicker && !!departments?.length && (
                    <Grid item>
                        <Field name="departmentId" label="Department" validate={required} required={true} component={RenderRadioGroup}>
                            {departments.map((d) => (
                                <FormControlLabel key={d.id} value={d.id} control={<Radio color="primary" data-public />} label={d.name} />
                            ))}
                        </Field>
                    </Grid>
                )}
                {showShiftPicker && !!shifts?.length && (
                    <Grid item>
                        <Field name="shiftId" label="Shift" type="text" validate={required} required={true} component={RenderRadioGroup}>
                            {shifts.map((s) => (
                                <FormControlLabel key={s.id} value={s.id} control={<Radio color="primary" data-public />} label={s.name} />
                            ))}
                        </Field>
                    </Grid>
                )}
                <Grid item>
                    <Grid container justifyContent="space-between" spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <LargePrimaryButton
                                fullWidth
                                disabled={submitting || !valid}
                                id="send-to-recipient"
                                onClick={handleSubmit((values) => onSubmit({ ...values, authorization: AuthorizationType.Recipient }))}
                            >
                                Send Form To Recipient
                            </LargePrimaryButton>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <LargePrimaryButton
                                fullWidth
                                disabled={submitting || !valid}
                                id="let-driver-authorize"
                                onClick={handleSubmit((values) => onSubmit({ ...values, authorization: AuthorizationType.Driver }))}
                            >
                                Let Driver Authorize Here
                            </LargePrimaryButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

const form = "editWorkOrder";
const selector = formValueSelector(form);
const mapStateToProps = (state: GlobalState): PropsFromState => {
    return {
        locationId: state.appSettings.defaults?.locationId ?? selector(state, "locationId"),
        workOrderTemplates: state.workOrderTemplates,
        workOrderSettingsId: selector(state, "workOrderSettingsId"),
        auth: state.auth,
        companies: state.companies,
        publicData: state.publicData,
    };
}
export default connect(mapStateToProps)(reduxForm<WorkOrderEditFormData, Props>({ form })(WorkOrderEditForm));
