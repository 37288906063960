import React from "react";
import { Box, Grid, IconButton, InputAdornment, StyledComponentProps } from "@material-ui/core";
import { connect } from "react-redux";
import { Field, FieldArray, formValueSelector, InjectedFormProps, reduxForm } from "redux-form";
import { formatMoneyOnly } from "../../../../services/app/formats";
import { required } from "../../../../services/app/validators";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import { RenderSwitch } from "../../../../components/ui/form/RenderSwitch";
import { Button, LargePrimaryButton } from "../../../../components/ui/Buttons";
import { RenderTextField } from "../../../../components/ui/Form";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterNoneRoundedIcon from "@material-ui/icons/FilterNoneRounded";
import { GlobalState } from "../../../../types/GlobalState";
import styles from "./InvoiceAddMultipleLineItemsForm.css";
import { LineItem } from "../../../../types/LineItems";

export interface CustomLineItemsFormData {
    lineItems: CustomLineItem[];
}

interface PropsFromState {
    lineItems: CustomLineItem[];
}

interface OwnProps {
    onSave: (lineItems: CustomLineItem[]) => void;
}

interface CompiledProps extends PropsFromState, OwnProps, StyledComponentProps { }

export interface CustomLineItem {
    cost: number;
    isTaxable?: boolean;
    description: string;
}

type Props = InjectedFormProps<CustomLineItemsFormData, CompiledProps> & CompiledProps;

class InvoiceAddMultipleLineItems extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
        this.saveChanges = this.saveChanges.bind(this);
        this.renderFields = this.renderFields.bind(this);
    }

    saveChanges(): void {
        setTimeout(() => {
            const { onSave, lineItems } = this.props;
            onSave(lineItems.filter((i) => i.description && (i.cost || i.cost === 0)));
        });
    }

    // eslint-disable-next-line
    renderFields({ fields, saveChanges }): any {
        const classes = styles();
        const addNewLineItem = (): void => fields.push({ description: "", cost: 0, isTaxable: false });
        const removeItem = (i: number): void => {
            fields.remove(i);
            setTimeout(saveChanges);
        };
        const duplicateItem = (i: number): void => {
            fields.push(fields.get(i));
            setTimeout(saveChanges);
        };

        const handleFirstFocus = (event: React.ChangeEvent<HTMLInputElement>): void => {
            event.target.select();
            event.target.onfocus = null;
        };

        return (
            <Grid container direction="column" wrap="nowrap" spacing={2} data-testid="line-items-form">
                <Grid item>
                    {
                        // eslint-disable-next-line
                        fields.map(
                            (field: string, i: number): React.ReactNode => (
                                <Box key={i} className={classes?.row} p={1} borderBottom="none" mb={2}>
                                    <Grid
                                        key={i}
                                        container
                                        className={classes?.container}
                                        spacing={2}
                                        wrap="wrap"
                                        direction="row"
                                        data-testid={`custom-line-item-${field}`}
                                    >
                                        <Grid item xs={12} sm={5}>
                                            <Field
                                                name={`${field}.description`}
                                                type="text"
                                                component={RenderTextField}
                                                onBlur={saveChanges}
                                                validate={required}
                                                label="Name of Line Item"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <Field
                                                name={`${field}.cost`}
                                                type="number"
                                                component={RenderTextField}
                                                validate={required}
                                                onBlur={saveChanges}
                                                label="Amount"
                                                onFocus={handleFirstFocus}
                                                startAdornment={
                                                    <InputAdornment position="start">
                                                        <AttachMoneyIcon />
                                                    </InputAdornment>
                                                }
                                                parse={formatMoneyOnly}
                                            />
                                        </Grid>
                                        <Grid item xs="auto" sm="auto">
                                            <Field
                                                name={`${field}.isTaxable`}
                                                type="checkbox"
                                                component={RenderSwitch}
                                                onChange={saveChanges}
                                                labelPlacement="bottom"
                                                label="Taxable"
                                            />
                                        </Grid>
                                        <Grid item xs="auto" sm="auto">
                                            <IconButton className={classes?.actionBtn} onClick={(): void => removeItem(i)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs="auto" sm="auto">
                                            <IconButton className={classes?.actionBtn} onClick={(): void => duplicateItem(i)}>
                                                <FilterNoneRoundedIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Box>
                            )
                        )
                    }
                </Grid>
                <Box pt={2}>
                    <Grid container spacing={2} direction="row">
                        <Grid item xs={12} className={fields.length > 0 ? classes?.saveGrid : classes?.hidden}>
                            <LargePrimaryButton className={classes?.saveBtn}>Save</LargePrimaryButton>
                        </Grid>
                        <Grid item xs={12} className={classes?.addGrid}>
                            <Button variant="outlined" className={classes?.addBtn} onClick={addNewLineItem} data-testid="add-line-item-btn">
                                Add New Line Item
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        );
    }

    render(): React.ReactElement {
        const { handleSubmit } = this.props;
        return (
            <form onSubmit={handleSubmit}>
                <FieldArray name="lineItems" component={this.renderFields} saveChanges={this.saveChanges} />
            </form>
        );
    }
}

export interface InvoiceAddMultipleLineItemsFormData {
    multipleLineItems: LineItem[];
}

const form = "invoiceLineItemsForm";
const selector = formValueSelector(form);
const mapStateToProps = (state: GlobalState): PropsFromState => ({ lineItems: selector(state, "lineItems") });
export default connect(mapStateToProps)(reduxForm<CustomLineItemsFormData, CompiledProps>({ form })(InvoiceAddMultipleLineItems));

