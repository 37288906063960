export function insertFileDownloadLink(blob: Blob, fileName: string): void {
    const a = window.document.createElement("a");
    a.href = URL.createObjectURL(blob);
    a.setAttribute("download", fileName);
    a.setAttribute("style", "visibility:hidden");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

export function downloadFileFromBlob(data: string, fileName: string, type: string): void {
    const blob = new Blob([data], { type: type });
    insertFileDownloadLink(blob, fileName);
}

export function openFileFromBlob(data: Blob): void {
    const blobUrl = URL.createObjectURL(data);
    window.open(blobUrl, "_blank");
}

