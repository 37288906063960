import React from "react";
import ModalContent from "../../components/modals/ModalContent";
import useStyles from "./SSOModals.css";
import { Typography } from "@material-ui/core";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import { LargePrimaryButton } from "../../components/ui/Buttons";
import { getEnvUrlPrefix } from "../../services/api/utils";

const PayerLoginRedirectModal: React.FC = (): JSX.Element => {
    const classes = useStyles();
    const getDriverRedirectUrl = (): string => {
        const env = getEnvUrlPrefix();
        return `https://${env}driver.roadsync.app/login`;
    };

    const doDriverRedirect = (): void => {
        window.location.href = getDriverRedirectUrl();
    };

    return (
        <ModalContent className={classes.root}>
            <div className={classes.textContainer}>
                <ReportProblemOutlinedIcon classes={{ root: classes.icon }} />
                <Typography variant="h4" gutterBottom>
                    Payers App Access
                </Typography>
                <Typography className={classes.paragraph}>
                    This account is set up for the Payers App. You will be redirected there shortly.
                </Typography>
                <LargePrimaryButton className="btn-yellow" onClick={doDriverRedirect}>
                    Go to Payers App
                </LargePrimaryButton>
            </div>
        </ModalContent>
    );
};

export default PayerLoginRedirectModal;

