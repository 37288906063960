import { GlobalState } from "../types";
import { createSelector } from "reselect";
import { getCurrentUser } from "./userSelectors";

export const getCompanies = (state: GlobalState) => state.companies.data;

export const getCurrentUserCompany = createSelector([getCompanies, getCurrentUser], (companies, currentUser) => {
    if (!companies || !currentUser) {
        return undefined;
    }
    const company = companies[currentUser?.companyId];
    return company;
});

