import { getInvoice } from "../actions/invoices";
import { getDepositForInvoice } from "../actions/deposits";
import { getCompanySettings } from "../actions/companySettings";
import { PreparationSteps } from "../constants/invoice";
import { getStepForInvoice } from "../services/app/invoice";
import { InvoicePreparationState } from "../types/Invoice";
import { invoiceSetStepAction, invoiceInitializeAction } from "../actions/invoice.preparation.actions";
import { isAnyPaymentMethodExceptCheckEnabled } from "../services/app/company";
import { Product } from "../types/Product";
import { ProductsState } from "../reducers/products";
import { AppSettingsState } from "../reducers/appSettings";
import { ShiftsState } from "../reducers/shifts";
import { DepartmentsState } from "../reducers/departments";
import { InvoiceState } from "../reducers/invoices";
import { DepositsState } from "../reducers/deposits";
import { CompanySettingsState } from "../reducers/companySettings";
import { LocationsState } from "../reducers/locations";
import { Dispatch } from "react";
import { CompaniesState } from "../reducers/companies";
import { getCompany } from "../actions/companies";
import { InvoicePreparationSteps } from "./InvoicePreparationSteps";
import { getProducts } from "../actions/products";
import { App } from "../constants/app";

interface ExpectedState {
    appSettings: AppSettingsState;
    companySettings: CompanySettingsState;
    shifts: ShiftsState;
    departments: DepartmentsState;
    invoices: InvoiceState;
    deposits: DepositsState;
    products: ProductsState;
    locations: LocationsState;
    companies: CompaniesState;
    invoicePreparation: InvoicePreparationState;
}

function invoiceInitialize(invoiceId?: string, isTowbookInvoice?: boolean): (dispatch: Dispatch<any>, getState: () => ExpectedState) => void {
    return function (dispatch: Dispatch<any>, getState: () => ExpectedState): void {
        const { appSettings, companySettings, shifts, departments, invoices, deposits } = getState();

        const me = appSettings?.settings?.user;
        const companyId = me?.companyId;
        const waitFor: Promise<unknown>[] = [];
        if (invoiceId && !invoices.data?.[invoiceId]) {
            waitFor.push(dispatch(getInvoice(invoiceId)) as any);
        }
        if (invoiceId && !deposits.data?.[invoiceId]) {
            waitFor.push(dispatch(getDepositForInvoice(invoiceId)) as any);
        }
        if (companyId) {
            //re-fetch company information for updated company features
            waitFor.push(dispatch(getCompany(companyId)) as any);
        }
        if (companyId && !companySettings.data?.[companyId]) {
            waitFor.push(dispatch(getCompanySettings(companyId)) as any);
        }

        Promise.all(waitFor).then(() => {
            const {
                invoices: { data, ui },
                locations,
                appSettings: { settings },
            } = getState();
            const invoice = (invoiceId && data?.[invoiceId]) || ui?.progress;
            const location = "string" === typeof invoice?.location ? locations.data?.[invoice.location] : invoice?.location;
            if (location) {
                waitFor.push(dispatch(getProducts(location.id, 0, App.GET_ALL)) as any);
            }

            Promise.all(waitFor).then(() => {
                const { products } = getState();
                const availableProducts: Product[] | undefined = location ? getUpToDateProductsByLocation(products, location.id) : undefined;
                dispatch(
                    invoiceSetStep(
                        getStepForInvoice(
                            invoice,
                            location,
                            locations,
                            settings,
                            me,
                            shifts,
                            departments,
                            appSettings?.settings?.accessibleLocations,
                            availableProducts,
                            isTowbookInvoice,
                        )
                    )
                );
            });
        });
    };
}

function invoiceInitializeSteps(): (dispatch: Dispatch<any>, getState: () => ExpectedState) => void {
    return function (dispatch: Dispatch<any>) {
        dispatch(invoiceInitializeAction(InvoicePreparationSteps));
    };
}

function getUpToDateProductsByLocation(products: ProductsState, locationId: string): Product[] {
    return Object.keys(products.data)
        .map((id) => products.data[id])
        .filter((value) => value.locationId === locationId);
}

function invoiceStepBack(invoiceId?: string) {
    // eslint-disable-next-line max-statements
    return function (dispatch: Dispatch<any>, getState: () => ExpectedState) {
        const {
            appSettings,
            invoices,
            companies,
            invoicePreparation: { activeStep },
        } = getState();
        const me = appSettings && appSettings.settings && appSettings.settings.user;
        const invoice = invoiceId && invoices.data?.[invoiceId];
        const company = me?.companyId ? companies.data?.[me.companyId] : undefined;

        if (invoice && !invoice.type) {
            if (activeStep.stepName === PreparationSteps.LINE_ITEMS) {
                if (isAnyPaymentMethodExceptCheckEnabled(company)) {
                    dispatch(invoiceSetStep(PreparationSteps.PAYMENT_METHOD));
                } else {
                    dispatch(invoiceSetStep(PreparationSteps.SELECT_FLEET_CARD_TYPE));
                }
                return;
            } else if (activeStep.stepName === PreparationSteps.SELECT_FLEET_CARD_TYPE || activeStep.stepName === PreparationSteps.PAYMENT_METHOD) {
                dispatch(invoiceSetStep(PreparationSteps.LOCATION_CUSTOM_FIELDS));
                return;
            }
        }

        if (invoice && invoice.type) {
            if (activeStep.stepName === PreparationSteps.LINE_ITEMS) {
                dispatch(invoiceSetStep(PreparationSteps.PAYMENT_METHOD_EXISTS));
                return;
            }
        }

        dispatch(invoiceSetStep(activeStep.previousStep || PreparationSteps.PAYER_INFO));
    };
}

function invoiceStepForward(nextStepName: string) {
    return function (dispatch: Dispatch<any>): void {
        dispatch(invoiceSetStep(nextStepName));
    };
}

function invoiceSetStep(stepName: string) {
    return function (dispatch: Dispatch<any>, getState: () => ExpectedState): void {
        const {
            invoicePreparation: { preparationSteps },
        } = getState();
        const step = preparationSteps.find((value) => {
            return value.stepName === stepName;
        });
        if (step) {
            dispatch(invoiceSetStepAction(step));
        }
    };
}

export { invoiceInitialize, invoiceStepBack, invoiceStepForward, invoiceSetStep, invoiceInitializeSteps };
