import React from "react";
import ModalContent from "../../components/modals/ModalContent";
import useStyles from "./SSOModals.css";
import { Typography } from "@material-ui/core";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import { LargePrimaryButton } from "../../components/ui/Buttons";
import { AuthPaths } from "../../services/app/paths";

const SSOErrorModal: React.FC = (): JSX.Element => {
    const classes = useStyles();

    const ssoRedirect = (): void => {
        window.location.href = AuthPaths.ssoLink(true);
    };

    return (
        <ModalContent className={classes.root}>
            <div className={classes.textContainer}>
                <ReportProblemOutlinedIcon classes={{ root: classes.icon }} />
                <Typography variant="h4" gutterBottom>
                    Login Error
                </Typography>
                <Typography className={classes.paragraph}>Something went wrong during login. Please try again.</Typography>
                <LargePrimaryButton className="btn-yellow" onClick={ssoRedirect}>
                    Try Again
                </LargePrimaryButton>
            </div>
        </ModalContent>
    );
};

export default SSOErrorModal;

