import { Company } from "../../types/Company";
import { FeatureType, FeatureTypes } from "../../constants/feature";
import { Type as CompanyType, CompanyTypes } from "../../constants/company";
import { Feature } from "../../types/Feature";
import { isAccountant, isCompanyAdmin, isFinancialAdmin, isRSEmployee, isRSImplementation, isSuperAdmin } from "./auth";
import { User } from "../../types/User";
import { CompaniesState } from "../../reducers/companies";
import { PublicDataState } from "../../reducers/public";

export function isFeatureEnabled(featureType: FeatureType, company?: Partial<Company>): boolean {
    return !!company?.features?.some((feature) => feature.featureType === featureType.key && feature.isEnabled);
}

export function isFeatureExists(company: Company, featureType: string): Feature | undefined {
    return company?.features?.find((feature) => feature.featureType === featureType);
}

export function isFeatureEnabledInList(featureType: FeatureType, featureList: Feature[] = []): boolean {
    return featureList?.some((feature) => feature.featureType === featureType.key && feature.isEnabled) === true;
}

export function isCashEnabled(company?: Partial<Company>): boolean {
    return isFeatureEnabled(FeatureTypes.CASH, company);
}

export function isCardEnabled(company?: Partial<Company>): boolean {
    return isFeatureEnabled(FeatureTypes.CARDS, company);
}

export function isCreditCardAtPublicCheckoutOnlyEnabled(company?: Partial<Company>): boolean {
    return isFeatureEnabled(FeatureTypes.CREDIT_CARD_AT_PUBLIC_CHECKOUT_ONLY, company);
}

export function isRemoteCheckoutEnabled(company?: Partial<Company>): boolean {
    return isFeatureEnabled(FeatureTypes.PUBLIC_CHECKOUT, company);
}

export function isCompanyTypeLumper(companyType?: string): boolean {
    return companyType === CompanyTypes.LUMPER.key;
}

export function isCompanyTypeOwnerOperator(companyType?: string): boolean {
    return companyType === CompanyTypes.OWNEROPERATOR.key;
}

export function isCompanyTypeWarehouse(companyType?: string): boolean {
    return companyType === CompanyTypes.WAREHOUSE.key;
}

export function isCompanyTypeTowAndRepare(companyType?: string): boolean {
    return companyType === CompanyTypes.TOWANDREPARE.key;
}

export function isCompanyTypePayer(companyType?: string): boolean {
    return companyType === CompanyTypes.PAYER.key;
}

export function isCompanyTypeDistributor(companyType?: string): boolean {
    return companyType === CompanyTypes.DISTRIBUTOR.key;
}

export function isSendReceiptEnabled(company?: Partial<Company>): boolean {
    return isFeatureEnabled(FeatureTypes.RECEIPT_NOTIFICATIONS, company);
}

export function isAnyPaymentMethodExceptCheckEnabled(company?: Partial<Company>): boolean {
    return (
        isCashEnabled(company) ||
        isCardEnabled(company) ||
        isDirectBillEnabled(company) ||
        isHostBillEnabled(company) ||
        isRemoteCheckoutEnabled(company)
    );
}

export function isSignatureEnabled(company?: Partial<Company>): boolean {
    return isFeatureEnabled(FeatureTypes.SIGNATURE_CAPTURE, company);
}

export function isInvoiceDisabled(company?: Partial<Company>): boolean {
    return isFeatureEnabled(FeatureTypes.DISABLE_INVOICES, company);
}

export function isWorkOrderEnabled(company?: Partial<Company>): boolean {
    return isFeatureEnabled(FeatureTypes.ENABLE_WORK_ORDERS, company);
}

export function isPublicCheckoutEnabled(company?: Partial<Company>): boolean {
    return isFeatureEnabled(FeatureTypes.PUBLIC_CHECKOUT, company);
}

export function isProductsCommentEnabled(company?: Partial<Company>): boolean {
    return isFeatureEnabled(FeatureTypes.ENABLE_PRODUCTS_COMMENT, company);
}

export function isAttachInvoicePdfEnabled(company?: Partial<Company>): boolean {
    return isFeatureEnabled(FeatureTypes.INVOICE_ATTACHMENT, company);
}

export function isAllowDisableConvFee(company?: Partial<Company>): boolean {
    return isFeatureEnabled(FeatureTypes.ALLOW_DISABLE_CONV_FEE, company);
}

export function isDirectBillEnabled(company?: Partial<Company>): boolean {
    return isFeatureEnabled(FeatureTypes.DIRECT_BILL, company);
}

export function isHostBillEnabled(company?: Partial<Company>): boolean {
    return isFeatureEnabled(FeatureTypes.HOST_BILL, company);
}

export function isDashboardEnabled(company?: Partial<Company>): boolean {
    return isFeatureEnabled(FeatureTypes.COMPANY_DASHBOARD, company);
}

export function isMultipleLineItemsEnabled(company?: Partial<Company>): boolean {
    return isFeatureEnabled(FeatureTypes.ADD_MULTIPLE_LINE_ITEMS, company);
}

export function isAccountingQuickbooksEnabled(company?: Partial<Company>): boolean {
    return isFeatureEnabled(FeatureTypes.ACCOUNTING_QUICKBOOKS, company);
}

export function isQuickbooksEnabled(company?: Partial<Company>): boolean {
    return isFeatureEnabled(FeatureTypes.RUTTER_QUICKBOOKS, company);
}

export function isCardNameRequiredEnabled(company?: Partial<Company>): boolean {
    return isFeatureEnabled(FeatureTypes.NAME_REQUIRED_WITH_CARD, company);
}

export function isLicenseRequiredEnabled(company?: Partial<Company>): boolean {
    return isFeatureEnabled(FeatureTypes.LICENSE_REQUIRED_WITH_CARD, company);
}

export function isConfirmationBeforeSendingEnabled(company?: Partial<Company>): boolean {
    return isFeatureEnabled(FeatureTypes.INVOICE_SUBMISSION_CONFIRMATION, company);
}

export function isAllowZeroDollarInvoiceEnabled(company?: Partial<Company>): boolean {
    return isFeatureEnabled(FeatureTypes.ALLOW_ZERO_DOLLAR_INVOICE, company);
}

export function isNotifyInvoiceStatusChangeEnabled(company?: Partial<Company>): boolean {
    return isFeatureEnabled(FeatureTypes.NOTIFY_INVOICE_STATUS_CHANGE, company);
}

export function isPayInvoicesEnabled(company?: Partial<Company>): boolean {
    return isFeatureEnabled(FeatureTypes.PAY_INVOICES, company);
}

export function isSkipCheckDetailsScreenEnabled(company?: Partial<Company>): boolean {
    return isFeatureEnabled(FeatureTypes.SKIP_CHECK_DETAILS_SCREEN, company);
}

export function isSendTotalToPayerButtonEnabled(company?: Partial<Company>): boolean {
    return isFeatureEnabled(FeatureTypes.SEND_TOTAL_TO_PAYER_BUTTON, company);
}

export function isGeneralLedgerEnabled(company?: Partial<Company>): boolean {
    return isFeatureEnabled(FeatureTypes.GENERAL_LEDGER, company);
}

export function isWexEnabled(company?: Partial<Company>): boolean {
    return isFeatureEnabled(FeatureTypes.WEX, company);
}

export function isWexCardKillSwitchEnabled(company?: Partial<Company>): boolean {
    return isFeatureEnabled(FeatureTypes.WEX_CARD_KILL_SWITCH, company);
}

export function isFleetCheckKillSwitchEnabled(company?: Partial<Company>): boolean {
    return isFeatureEnabled(FeatureTypes.FLEET_CHECK_KILL_SWITCH, company);
}

export function isWexCardEnabled(company?: Partial<Company>): boolean {
    return isWexEnabled(company) && !isWexCardKillSwitchEnabled(company);
}

export function isAchPaymentEnabled(company?: Partial<Company>): boolean {
    return isFeatureEnabled(FeatureTypes.ACH_PAYMENT, company);
}

export function isSurveyEnabled(company?: Partial<Company>): boolean {
    return isFeatureEnabled(FeatureTypes.SURVEY, company);
}

export function isCardOnFileEnabled(company?: Partial<Company>): boolean {
    return isFeatureEnabled(FeatureTypes.CARD_ON_FILE, company);
}

export function isPayoutsEnabled(company?: Partial<Company>): boolean {
    return isFeatureEnabled(FeatureTypes.INSTANT_PAYOUT, company);
}

export function isSecuredBankingEnabled(company?: Partial<Company>): boolean {
    return isFeatureEnabled(FeatureTypes.SECURED_BANKING, company);
}

export function isDisableEmailsEnabled(company?: Partial<Company>): boolean {
    return isFeatureEnabled(FeatureTypes.EMAIL_DISABLED, company);
}

export function isRainforestPayEnabled(company?: Partial<Company>): boolean {
    return isFeatureEnabled(FeatureTypes.RAINFOREST_PAY, company);
}

export function isPartialRefundEnabled(company?: Partial<Company>): boolean {
    return isFeatureEnabled(FeatureTypes.PARTIAL_REFUND, company);
}

export function isZipBillingAddressEnabled(company?: Partial<Company>): boolean {
    return isFeatureEnabled(FeatureTypes.ZIP_BILLING_ADDRESS, company);
}

export function isExpressPaymentEnabled(company?: Partial<Company>): boolean {
    return isFeatureEnabled(FeatureTypes.EXPRESS_PAYMENT, company);
}

export function isHostedPayPageEnabled(company?: Partial<Company>): boolean {
    return isFeatureEnabled(FeatureTypes.HOSTED_PAY_PAGE, company);
}

export function isOnesourceEnabled(company?: Partial<Company>): boolean {
    return isFeatureEnabled(FeatureTypes.ONESOURCE, company);
}

export function isTowbookEnabled(company?: Partial<Company>): boolean {
    return isFeatureEnabled(FeatureTypes.TOWBOOK, company);
}

export function isAddressBookEnabled(company?: Partial<Company>): boolean {
    return isFeatureEnabled(FeatureTypes.ADDRESS_BOOK, company);
}

export function isOwnerDashboardEnabled(company?: Partial<Company>): boolean {
    return !isFeatureEnabled(FeatureTypes.OWNER_DASHBOARD_KILL_SWITCH, company);
}

export function isAdvanceToCheckoutDirectPaymentsEnabled(company?: Partial<Company>): boolean {
    return isFeatureEnabled(FeatureTypes.ADVANCE_TO_CHECKOUT_DIRECT_PAYMENTS, company);
}

export function isPreValidatePaymentEnabled(company?: Partial<Company>): boolean {
    return isFeatureEnabled(FeatureTypes.PRE_VALIDATE_PAYMENT, company);
}

export function isWebhooksEnabled(company?: Partial<Company>): boolean {
    return isFeatureEnabled(FeatureTypes.WEBHOOKS, company);
}

export function showIntegrations(me?: User): boolean {
    const CompanyAdminFinancialAdminCombo = isCompanyAdmin(me) && isFinancialAdmin(me);
    const CompanyAdminAccountantCombo = isCompanyAdmin(me) && isAccountant(me);
    const isValidRole =
        isCompanyAdmin(me) ||
        CompanyAdminFinancialAdminCombo ||
        CompanyAdminAccountantCombo ||
        isSuperAdmin(me) ||
        isRSEmployee(me) ||
        isRSImplementation(me);
    return isValidRole;
}

export const getRemainingCompanyTypes = (types: CompanyType[]): CompanyType[] => CompanyTypes.getTypes().filter((t) => !types.includes(t));

export const getCompanyFromState = (companies: CompaniesState, publicData: PublicDataState, user?: User): Company | undefined => {
    return user ? companies?.data?.[user!.companyId] : undefined;
};
