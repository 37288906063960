import { CompanyEndpoints } from "./endpoints";
import { Company, CompanyInput, CompanyPublic } from "../../types/Company";
import * as http from "./http";

export function getCompany(companyId: string): Promise<Company> {
    return http.get(CompanyEndpoints.getCompany(companyId));
}

export function getCompanyPublic(companyId: string): Promise<CompanyPublic> {
    return http.get(CompanyEndpoints.getCompanyPublic(companyId));
}

export function createCompany(company: CompanyInput): Promise<Company> {
    return http.postJSON(CompanyEndpoints.CREATE, prepareCompanyInput(company));
}

export function updateCompany(company: CompanyInput): Promise<Company> {
    return http.putJSON(CompanyEndpoints.updateCompany(company.id), prepareCompanyInput(company));
}

// eslint-disable-next-line max-params
export function getCompanies(offset: number, limit: number, filter?: string, companyType?: string): Promise<any> {
    return http.get(CompanyEndpoints.getCompanies(offset, limit, filter, companyType));
}

function prepareCompanyInput(company: CompanyInput) {
    return {
        id: company.id,
        name: company.name,
        street: company.street,
        street2: company.street2,
        city: company.city,
        state: company.state,
        zip: company.zip,
        taxId: company.taxId,
        phone: company.phone,
        timezone: company.timezone,
        type: company.type,
        tier: company.tier,
        fileId: company.fileId,
        parentCompanyId: company.parentCompanyId,
        cashEnabled: company.cashEnabled,
        wexApiTerminalId: company.wexApiEfsTerminalId,
        parentCompanyPublicId: company.parentCompanyPublicId,
        settings: {
            instantPayoutLimit: company.settings?.InstantPayoutLimit,
            cardOnFileTAndCURL: company.settings?.CardOnFileTAndCURL,
            payLinkTAndCURL: company.settings?.PayLinkTAndCURL,
            invoiceTaxIdDisabled: company.settings?.InvoiceTaxIdDisabled,
        },
        crmId: company.crmId,
        crmType: company.crmType,
        slug: company.slug,
        towbookConvenienceFeeEnabled: company?.towbookConvenienceFeeEnabled,
    };
}

export interface CompanyIntegrationsConfig {
    companyId?: string;
    towbook?: {
        acceptedTermsAndConditions?: boolean;
        acceptedTermsAndConditionsBy?: string;
        acceptedTermsAndConditionsAt?: string;
        convenienceFeeEnabled?: boolean;
        postCashPaymentsEnabled?: boolean;
    };
    rutter?: {
        authorized?: boolean;
        authorizedAt?: string;
    };
}

export interface SvixData {
    url: string;
    token: string;
}
export function getCompanyIntegrationConfig(companyId: string): Promise<CompanyIntegrationsConfig> {
    return http.get(`/api/v1/company/${companyId}/integrations`);
}

export function getCompanySvixIframe(companyId: string): Promise<SvixData> {
    return http.get(`/api/v1/company/${companyId}/webhooks/iframe`);
}

export function updateCompanyIntegrationsConfig(companyId: string, config: Partial<CompanyIntegrationsConfig>): Promise<CompanyIntegrationsConfig> {
    return http.postJSON(`/api/v1/company/${companyId}/integrations`, {
        companyId,
        ...config,
    });
}

export function updateCompanyRutterToken(companyId: string, public_token: string): Promise<void> {
    return http.postJSON(`/api/v1/companies/${companyId}/rutter/exchange-public-token`, {
        public_token,
    });
}
