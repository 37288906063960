import { Divider, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from "@material-ui/core";
import React from "react";
import { getLineItemCostAndTitle } from "../../services/app/invoice";
import { LineItem } from "../../types/LineItems";
import { Invoice } from "../../types";
import { InvoiceSource } from "../../constants/invoice";

interface Props {
    item: LineItem;
    invoice?: Invoice;
}

const ProductListLineItem: React.FC<Props> = (props: Props) => {
    const { item, invoice } = props;
    const { description, showQty, cost } = getLineItemCostAndTitle(item);
    const isTowbookInvoice = invoice?.source === InvoiceSource.TOWBOOK;
    const isWorkOrderDeposit = invoice?.source === InvoiceSource.WORK_ORDER_DEPOSIT;
    const towbookDescription = `Towbook Call #${invoice?.externalInvoiceNumber}`;
    const workOrderDepositDesc = "Work Order Deposit";
    const title = isTowbookInvoice ? towbookDescription : isWorkOrderDeposit ? workOrderDepositDesc : description;

    return <>
        <ListItem>
            <ListItemIcon>{showQty || 1}x</ListItemIcon>
            <ListItemText>{title}</ListItemText>
            <ListItemSecondaryAction>{cost}</ListItemSecondaryAction>
        </ListItem>
        <Divider />
    </>
}

export default ProductListLineItem;
