import { createSelector } from "reselect";
import { GlobalState } from "../types";
import { isEmployee, isCompanyAdmin, isFinancialAdmin, isSuperAdmin, isRSEmployee, isAccountant, isClientSupport } from "../services/app/auth";

export const getCurrentUser = (state: GlobalState) => state.auth?.me;

export const getCurrentUserRoles = createSelector([getCurrentUser], (currentUser) => {
    return currentUser.roles;
});

export const getCurrentUserCompanyId = createSelector([getCurrentUser], (currentUser) => {
    return currentUser.companyId;
});

export const getCurrentIsEmployee = createSelector([getCurrentUser], (currentUser) => {
    return isEmployee(currentUser);
});

export const getCombinedUserRoleBooleans = createSelector([getCurrentUser], (currentUser) => {
    return {
        isEmployee: isEmployee(currentUser),
        isCompanyAdmin: isCompanyAdmin(currentUser),
        isFinancialAdmin: isFinancialAdmin(currentUser),
        isSuperAdmin: isSuperAdmin(currentUser),
        isRSEmployee: isRSEmployee(currentUser),
        isAccountant: isAccountant(currentUser),
        isClientSupport: isClientSupport(currentUser),
    };
});

